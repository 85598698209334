import React from 'react';
import { Formik } from 'formik';
import { default as CreateProjectModal } from './create-modal';
import { globalModels, globalQueries } from '@shared/duck';
import { enqueueSnackbar } from 'notistack';
import { useGlobalModalContext } from '@shared/duck/contexts';
import { Project } from '@api-client';
import { CreateProjectFormValues, CreateProjectValidationSchema } from '@shared/duck/utils';

export interface CreateProjectModalProps {
  project?: globalModels.ProjectViewModel
  initialName?: string
  onResult?: (result: Project | undefined) => void;
}

const CreateProjectModalWrapper: React.FC<CreateProjectModalProps> = ({ project, initialName, onResult }: CreateProjectModalProps) => {
  const { createProject } = globalQueries.useCreateProject();
  const { updateProject } = globalQueries.useUpdateProject();

  const createProjectSubmit = async ({ name }: CreateProjectFormValues) => {
    try {
      const result = await createProject({ projectRequest: { name } });
      return result.data;
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to create a new Project', { variant: 'error' });
    }
  };

  const updateProjectSubmit = async (id: string, name: string) => {
    try {
      const result = await updateProject({ id: id, projectRequest: { name } });
      return result.data;
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to update Project', { variant: 'error' });
    }
  };

  const { hideModal } = useGlobalModalContext();
  const [isModalShown, setIsModalShown] = React.useState(true);
  const onClose = () => setIsModalShown(false);

  return (
    <Formik<CreateProjectFormValues>
      initialValues={{ name: project ? project.name : initialName || '', exists: false, isNameTested: false }}
      onSubmit={async values => {
        const result = await (
          project ? updateProjectSubmit(project?.id, values.name) : createProjectSubmit(values)
        );
        onClose();
        onResult?.(result);
      }}
      validateOnChange
      validationSchema={CreateProjectValidationSchema}
    >
      {({ isSubmitting, isValid, values }) => {
        return <CreateProjectModal
          isShown={isModalShown}
          onClose={onClose}
          onExited={() => hideModal()}
          isSubmitting={isSubmitting}
          isValid={isValid}
          isNameTested={values.isNameTested}
          isInEdit={!!project}
        />;
      }}
    </Formik>
  );
};

export default CreateProjectModalWrapper;