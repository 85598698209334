import { Table, TableRowProps } from '@contentful/f36-components';
import { styled, TableRow, TableRowOwnProps } from '@mui/material';
import { tableTypes } from '@shared/components';
import baseTokens from '@contentful/f36-tokens';
import { muiPalette } from '@shared/general-mui-theme';
import { withSafeProps } from 'views/utils/common';

interface StyledHeaderCellProps extends tableTypes.UITableCellProps {
  verticalAlign?: string;
}

export const StyledHeaderCell = styled(withSafeProps<StyledHeaderCellProps>(Table.Cell, ['width', 'verticalAlign']))`
  color: ${baseTokens.gray800};
  width: ${({ width }) => width};
  vertical-align:  ${({ verticalAlign }) => verticalAlign ? `${verticalAlign}` : 'top'};
`;

interface StyledHeaderRowProps extends TableRowProps {
  bordered: boolean;
  withExternalBorders: boolean;
}

interface StyledMuiHeaderRowProps extends TableRowOwnProps {
  bordered: boolean;
}

export const StyledHeaderRow = styled(withSafeProps<StyledHeaderRowProps>(Table.Row, ['bordered', 'withExternalBorders']))`
  box-sizing: border-box;

  & th {
    border-top: ${({ withExternalBorders }) => withExternalBorders ? '1px solid #E7EBEE' : ''};
    border-right: ${({ bordered }) => bordered && `1px solid ${baseTokens.gray200}`}
  }

  & th:nth-last-of-type(-n + 1) {
    border-right: ${({ bordered }) => bordered && 'none'}
  }
`;

export const StyledMuiHeaderRow = styled(withSafeProps<StyledMuiHeaderRowProps>(TableRow, ['bordered']))`
    box-shadow: none;

    & th {
        border-right: ${({ bordered }) => bordered && `1px solid ${muiPalette.grey?.[200]}`};
        border-bottom: 1px solid ${muiPalette.grey?.[200]};
    }

    & th:nth-last-of-type(-n + 1) {
        border-right: ${({ bordered }) => bordered && 'none'}
    }
`;