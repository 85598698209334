import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { FileInputContainer, FileInputText } from './file-input.styled';
import { UploadCloud } from '@assets';
import { globalEnums } from '@shared/duck';
import { UIAsset, MuiTextInput } from '@shared/components';
import { Stack, TextFieldProps } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

type UIFileMultipleInputProps = {
  onUploadFiles: (file: File[]) => void;
  accept: globalEnums.EFileExtension[];
  helpText?: string;
} & Omit<TextFieldProps, 'accept'>;

export const UIFileMultipleInput: React.FC<UIFileMultipleInputProps> = ({
  onUploadFiles,
  accept,
  helpText,
  ...props
}) => {
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [drag, setDrag] = React.useState(false);

  const onFilesChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const attachedFiles: (File | null)[] = Array.from((event.currentTarget as any)?.files);
    let unsupportedTypeError = false;
    const uploadedFiles: File[] = [];

    attachedFiles.forEach(async file => {
      if (!file) {
        return;
      }
      const mime = file.type;
      const ext = file.name.split('.').pop() || '';

      if (accept.filter(a => a.mime.includes(mime) || a.exts?.includes(ext)).length > 0) {
        uploadedFiles.push(file);
      } else {
        unsupportedTypeError = true;
      }
    });
    if (uploadedFiles.length) {
      onUploadFiles(uploadedFiles);
    }
    if (unsupportedTypeError) {
      enqueueSnackbar('Unsupported file type', { variant: 'error' });
    }
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  const acceptProp = React.useMemo(() => accept.map(a => [
    ...a.mime,
    (
      a.exts?.map(x => '.' + x) || []
    ).join(','),
  ].join(',')).join(','), [accept]);

  return (
    <FileInputContainer
      isDraggable={drag}
      isInvalid={props.error}
      onDragEnter={() => setDrag(true)}
      onDragStart={() => setDrag(true)}
      onDragOver={() => setDrag(true)}
      onDragEnd={() => setDrag(false)}
      onDrop={() => setDrag(false)}
      onDragLeave={() => setDrag(false)}
    >
      <Stack alignItems='center' gap={0.25} textAlign='center'>
        <UIAsset src={UploadCloud} />
        <FileInputText color={muiPalette.grey?.['500']}>
          Click or drag and drop to add
        </FileInputText>
        {helpText && (
          <FileInputText
            color={muiPalette.grey?.['500']}
          >
            {helpText}
          </FileInputText>
        )}
      </Stack>
      <MuiTextInput
        type='file'
        inputRef={fileRef}
        onChange={(e) => {
          onFilesChange(e);
        }}
        inputProps={{ accept: acceptProp, multiple: true }}
      />
    </FileInputContainer>
  );
};