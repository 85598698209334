import { Box, Stack } from '@mui/material';
import React from 'react';
import { SideBar, Header, BillingNotification } from './components';
import { LayoutContainer } from './layout.styled';
import { useMedia } from '@shared/hooks';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
  const { isSmallScreen } = useMedia();

  const contentPadding = isSmallScreen ? '1rem' : '2rem';

  const [open, setOpen] = React.useState(!isSmallScreen);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <LayoutContainer>
      <SideBar className='sidebar' toggleDrawer={toggleDrawer} open={open} />
      <Header className='navbar' toggleDrawer={toggleDrawer} />
      <BillingNotification className='notification' />

      <Box
        className='content'
        padding={contentPadding}
        sx={{
          alignSelf: 'stretch',
        }}
      >
        {/** Note:
         * height='100%' is necessary for scroll function in tables!
         *
         * to access the height from child components use:
         *   const outletStackRef = React.useRef<HTMLElement | null>(null);
         *     React.useLayoutEffect(() => {
         *     outletStackRef.current = document.getElementById('outletStack');
         *     if (outletStackRef.current) {
         *       outletStackRef.current.style.height = 'fit-content';
         *     }
         *
         *     return () => {
         *       if (outletStackRef.current) {
         *         outletStackRef.current.style.height = '100%';
         *       }
         *     };
         *   }, []);
         */}
        <Stack id='outletStack' gap={0} height='100%'>
          <Outlet />
        </Stack>
      </Box>
    </LayoutContainer>
  );
};

export default Layout;
