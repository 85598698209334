import React from 'react';
import { Menu } from '@contentful/f36-components';
import dayjs from 'dayjs';
import { DAY_RANGE_LIST, DayRangeEnum } from './duck';
import { Button } from '@mui/material';
import { CaretDown, CaretUp } from '@phosphor-icons/react';

interface BasicMenuProps {
  setStartDate: (date: Date | undefined) => void,
  setText: (text: DayRangeEnum) => void,
  text: string,
  size?: 'small' | 'medium' | 'large'
}

const BasicMenu: React.FC<BasicMenuProps> = ({
  setStartDate,
  setText,
  text,
  size = 'medium',
}) => {

  const setDate = React.useCallback((item: DayRangeEnum) => {
    const now = dayjs().toDate();

    switch (item) {
      case DayRangeEnum.WEEK: {
        now.setDate(now.getDate() - 7);
        setStartDate(now);
        setText(DayRangeEnum.WEEK);
        return;
      }
      case DayRangeEnum.DAY: {
        now.setDate(now.getDate() - 1);
        setStartDate(now);
        setText(DayRangeEnum.DAY);
        return;
      }
      case DayRangeEnum.MONTH: {
        now.setDate(now.getDate() - 30);
        setStartDate(now);
        setText(DayRangeEnum.MONTH);
        return;
      }
      case DayRangeEnum.ALL: {
        setStartDate(undefined);
        setText(DayRangeEnum.ALL);
        return;
      }
    }
  }, []);

  const [isOpen, setOpen] = React.useState(false);

  return (
    <Menu
      isOpen={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isFullWidth
    >
      <Menu.Trigger>
        <Button
          size={size}
          endIcon={isOpen ? <CaretUp /> : <CaretDown />}
          sx={{
            minWidth: '8rem',
          }}
        >
          {text}
        </Button>
      </Menu.Trigger>
      <Menu.List>
        {DAY_RANGE_LIST.map((item, index) => {
          return (
            <Menu.Item
              key={item + index}
              onClick={() => setDate(item)}
              style={{ minWidth: 'unset' }}
            >
              {item}
            </Menu.Item>
          );
        })
        }
      </Menu.List>
    </Menu>
  );
};

export default BasicMenu;