import { User } from '@api-client';
import { BlueFolder } from '@assets';
import { AvatarGroup, Link } from '@mui/material';
import { UIUserAvatar, tableTypes } from '@shared/components';
import { allRoutesEnum, globalModels } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';

export const projectsColumns = (meId?: string): tableTypes.ColumnProps<globalModels.ProjectViewModel>[] => [
  {
    title: 'Name',
    columnKey: 'name',
    cellInfo: {
      cellTextWeight: 600,
      getIcon() {
        return <UIUserAvatar src={BlueFolder} avatarSize={2.5} />;
      },
      description(item) {
        return item.description || '';
      },
      href(item) {
        return allRoutesEnum.ProjectDetails.replace(':projectId', item.id);
      }
    },
    minWidth: '24.5rem',
  },
  {
    title: 'Targets',
    columnKey: 'targets_count',
    minWidth: '5rem',
  },
  {
    title: 'Owner',
    columnKey: 'owner',
    customCellRenderer(value) {
      const owner = value as User;
      return (
        <UIUserAvatar
          src={owner.avatar_url || ''}
          fullName={`${owner.first_name} ${owner.last_name}`.trim()}
          withFullNameText
        />
      );
    },
    minWidth: '14rem',
  },
  {
    title: 'Collaborators',
    columnKey: 'customField',
    customCellRenderer(_, item) {
      const sharedWithUsers = item.teamSize > 3
        ? item.collaborators.filter(p => p.id !== meId).slice(0, 3)
        : item.collaborators;

      return (
        <Link
          href={allRoutesEnum.ProjectDetailsCollaborators.replace(':projectId', item.id)}
          sx={{ zIndex: '1!important', ':hover': {
            textDecoration: 'none',
          } }}
        >
          <AvatarGroup
            total={item.teamSize}
            sx={{
              alignItems: 'center',
              '& > .MuiAvatar-root': {
                zIndex: '100',
                width: '1.75rem',
                height: '1.75rem',
                fontSize: '0.75rem',
                color: muiPalette.blue?.[600],
                backgroundColor: muiPalette.blue?.[100],
                ml: '-1rem',
              }
            }}
            onClick={e => e.stopPropagation()}
          >
            {sharedWithUsers.map((user, index) =>
              <UIUserAvatar
                key={`shared-with-user-${index}`}
                src={user.avatar_url || ''}
                fullName={`${user.first_name} ${user.last_name}`.trim()}
                sx={{
                  ml: index && '-1rem',
                  zIndex: 10 + index,
                }}
              />
            )}
          </AvatarGroup>
        </Link>
      );
    },
    width: '10rem',
  },
];