import React from 'react';
import { FormikControlMui } from '@shared/components';
import { LearnMorePopup } from './components';
import { Alert, Link, Stack, Typography } from '@mui/material';
import { UrlInput } from '../url-input';

interface PlaywrightScriptProps {
  inEdit?: boolean;
}

const PlaywrightScript: React.FC<PlaywrightScriptProps> = ({
  inEdit = false,
}) => {
  const [isOpenPopup, setOpenPopup] = React.useState(false);

  return (
    <Stack justifyContent='space-between' flexGrow='1'>
      <FormikControlMui name='playwrightUrl' label='Target URL'>
        <UrlInput
          inEdit={inEdit}
          placeholder='Add URL'
          size='small'
        />
      </FormikControlMui>

      <Alert severity='info' style={{ marginTop: '3rem' }}>
        {'NightVision eliminates the need for writing complex scripts for authentication. Simply record your login sequence using Playwright.'}
        <Typography mt='0.5rem' fontSize='inherit'>
          <Link
            onClick={() => setOpenPopup(true)}
            fontWeight={500}
          >
            {'Learn more about Playwright'}
          </Link>
        </Typography>
      </Alert>

      <LearnMorePopup
        isOpen={isOpenPopup}
        closeModal={() => setOpenPopup(false)}
      />
    </Stack>
  );
};

export default PlaywrightScript;