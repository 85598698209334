import React from 'react';
import { MuiContentLoader } from '@shared/components';
import { Form, Formik } from 'formik';
import { CreateWebTargetsModalValuesSchema, getNameFromUrl, TargetsValues, useGetWebUtils, WebTargetsContentValues } from './duck';
import { Button, CircularProgress, DialogActions, DialogContent, Stack } from '@mui/material';
import { TargetsContentValuesProps } from '../../duck';
import { CommonContentHeader } from '../common-content-header';
import { WebTargetsContent } from './web-targets-content';

export const WebTargetsContentWrapper: React.FC<TargetsContentValuesProps<WebTargetsContentValues>> = ({
  closeModal,
  onAfterCreate,
  onAfterUpdate,
  project,
  onProjectChange,
  enableProjectSelect,
  isDefaultProjectLoading,
  target,
  currentTab,
  onTabChange,
}: TargetsContentValuesProps<WebTargetsContentValues>) => {
  const {
    onUpdateTargets,
    onCreateTargets,
    getInitialValues,
  } = useGetWebUtils({
    target: target,
    project: project,
    onAfterUpdate: onAfterUpdate,
    onAfterCreate: onAfterCreate,
  });

  return (
    <Formik<WebTargetsContentValues>
      initialValues={getInitialValues()}
      validateOnMount
      validateOnChange
      onSubmit={target ? onUpdateTargets : onCreateTargets}
      validationSchema={CreateWebTargetsModalValuesSchema}
      enableReinitialize
    >
      {({ isSubmitting, isValid, handleSubmit, values, setFieldValue, initialValues, validateForm }) => {
        const onSecondPageOpen = async () => {
          const newUrls = values.stringUrl.toString().split('\n').map(url => url.trim()).filter(url => url.length);
          const newTargets: TargetsValues[] = newUrls.map((url, index) => {
            return ({
              ...values.targets[index] && (values.targets[index].url === newUrls[index]) ?
                { ...values.targets[index] } : { ...values.targets[index], isAccessible: undefined, isAccessTested: false },
              name: getNameFromUrl(url) || '',
              url: url,
              isNameTested: false,
            });
          });
          setFieldValue('firstPage', false);
          setFieldValue('targets', newTargets);
        };

        const onFirstPageOpen = () => {
          const newStringUrl = values.targets.map(target => target.url).join('\n');
          setFieldValue('targets', [], false);
          setFieldValue('stringUrl', newStringUrl, false);
          setFieldValue('firstPage', true, false);
          validateForm({ ...values, targets: [], stringUrl: newStringUrl, firstPage: true });
        };

        const isExclusionsChanged = JSON.stringify(initialValues.configuration) !== JSON.stringify(values.configuration);

        const isInitialEqualsToCurrent = values.targets.map((target, index) =>
          target?.name === initialValues.targets[index]?.name && target?.url === initialValues.targets[index]?.url)
          .some(x => x) && !isExclusionsChanged;

        return (
          <>
            <Form style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
              <DialogContent dividers>
                <MuiContentLoader
                  isLoading={isDefaultProjectLoading}
                >
                  <CommonContentHeader
                    project={values.project}
                    enableProjectSelect={enableProjectSelect}
                    onProjectChange={onProjectChange}
                    showToggleButtons={!target}
                    currentTab={currentTab}
                    onTabChange={onTabChange}
                  />

                  <WebTargetsContent
                    target={target}
                    project={values.project}
                  />
                </MuiContentLoader>
              </DialogContent>
            </Form>

            <DialogActions sx={{ gap: '1.5rem', justifyContent: 'space-between' }}>
              <Button size='small' onClick={closeModal}>
                Cancel
              </Button>
              <Stack gap='1rem' direction='row'>
                {!target && <Button
                  size='small'
                  onClick={values.firstPage ? undefined : () => onFirstPageOpen()}
                  disabled={values.firstPage}
                >
                  Back
                </Button>}
                <Button
                  size='small'
                  variant='contained'
                  onClick={() => values.firstPage ? onSecondPageOpen() : handleSubmit()}
                  endIcon={isSubmitting && (
                    <CircularProgress color='inherit' size={18} />
                  )}
                  disabled={!isValid || !project || isSubmitting || isInitialEqualsToCurrent}
                >
                  {values.firstPage ? 'Next' : target ? 'Update' : 'Create Target(s)'}
                </Button>
              </Stack>
            </DialogActions>
          </>
        );
      }}
    </Formik >
  );
};