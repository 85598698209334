import { Box, Grid, Stack } from '@mui/material';
import { MuiContentLoader, UITabs } from '@shared/components';
import { globalEnums, globalQueries, globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { useMedia } from '@shared/hooks';
import FalsePositivesTab from 'shared/components/target-details/components/false-positives-tab/false-positives-tab';
import {
  CommonTargetHeader,
  EndpointsTab,
  GeneralTab,
  CustomPathsTab,
  RightPanelContainer,
  HarTab,
} from './components';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ErrorContext } from '@shared/duck/contexts';
import { errorResponseHandler } from 'views/utils/errorHandlers';

export enum TABID {
  GENERAL = 'general',
  ENDPOINTS = 'endpoints',
  PATHS = 'paths',
  FALSE_POSITIVES = 'false-positives',
  HAR_TRAFFIC = 'http-traffic',
}

const tabParameter = 'tab';

const TargetDetails: React.FC = () => {
  const { targetId = '' } = useParams<{ targetId: string }>();
  const [tabsValue, setTabsValue] = React.useState<string | number>(TABID.GENERAL);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSmallScreen } = useMedia();

  const { setError } = React.useContext(ErrorContext);
  const { target, isTargetLoading } = globalQueries.useGetTarget(
    { id: targetId },
    {
      onError: (er) => errorResponseHandler(er, 'target', setError),
    },
  );

  const isWebTarget = React.useMemo(() => {
    return target?.type?.toString()?.toUpperCase() === globalEnums.ETargetType.URL;
  }, [target?.type]);

  const handleTabsChange = (value: string | number) => {
    setSearchParams((urlParams) => {
      urlParams.set(tabParameter, (value || TABID.GENERAL) as string);
      return urlParams;
    });
  };

  React.useEffect(() => {
    const tab = searchParams.get(tabParameter);
    const isValid = Object.values(TABID).find(el => el === tab);
    if (isValid && !!tab) {
      setTabsValue(tab);
    }
    else {
      setTabsValue(TABID.GENERAL);
      setSearchParams((urlParams) => {
        urlParams.set(tabParameter, TABID.GENERAL);
        return urlParams;
      }, { replace: true });
    }
  }, [searchParams]);

  const tabsConfig: globalTypes.UITabsProps = React.useMemo(() => {
    return {
      tabsProps: {
        value: tabsValue,
        onChange: (e, value) => handleTabsChange(value),
        'aria-label': 'target_details_tabs',
        variant: 'scrollable',
        scrollButtons: 'auto'
      },
      tabs: [
        {
          label: 'General',
          value: TABID.GENERAL,
        },
        {
          label: 'Endpoints',
          value: TABID.ENDPOINTS,
          isExist: !isWebTarget,
        },
        {
          label: 'User-defined Paths',
          value: TABID.PATHS,
          isExist: isWebTarget,
        },
        {
          label: 'False Positives',
          value: TABID.FALSE_POSITIVES,
        },
        {
          label: 'HTTP Traffic',
          value: TABID.HAR_TRAFFIC,
        },
      ],
      tabsPanels: [
        {
          tabPanelId: TABID.GENERAL,
          openTabId: tabsValue,
          children: <GeneralTab target={target} />,
        },
        {
          tabPanelId: TABID.ENDPOINTS,
          openTabId: tabsValue,
          children: <EndpointsTab openApiTargetId={target?.id || ''} />,
        },
        {
          tabPanelId: TABID.PATHS,
          openTabId: tabsValue,
          children: <CustomPathsTab webTargetId={target?.id || ''} />,
        },
        {
          tabPanelId: TABID.FALSE_POSITIVES,
          openTabId: tabsValue,
          children: <FalsePositivesTab targetId={target?.id || ''} />,
        },
        {
          tabPanelId: TABID.HAR_TRAFFIC,
          openTabId: tabsValue,
          children: <HarTab targetId={target?.id || ''} />,
        },
      ],
    };
  }, [tabsValue, target]);

  return (
    </* data-testid='parent'*/>
      <MuiContentLoader
        isLoading={isTargetLoading}
      >
        <Stack gap={1.5} height='100%'>
          <CommonTargetHeader target={target} />
          <Box sx={{ height: '100%', overflow: isSmallScreen ? 'unset' : 'hidden' }}>
            <Grid
              container
              spacing={0}
              height={isSmallScreen ? 'fit-content' : '100%'}
            >
              <Grid
                item
                md={6} xs={12}
                padding={'1rem'}
                sx={{ backgroundColor: 'white' }}
                display='flex'
                overflow='hidden'
                height='100%'
              >
                <UITabs {...tabsConfig} />
              </Grid>
              <Grid
                item
                md={6} xs={12}
                padding={'1rem'}
                sx={{ backgroundColor: 'white' }}
                borderLeft={isSmallScreen ? 'initial' : `1px solid ${muiPalette.grey?.[300]}`}
                marginTop={isSmallScreen ? 1 : 0}
                display='flex'
                overflow='auto'
                height='100%'
              >
                <RightPanelContainer
                  targetId={target?.id || ''}
                  isTargetLoading={isTargetLoading}
                  closeModal={() => null}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </MuiContentLoader>
    </>
  );
};

export default TargetDetails;