import { ScanRequest, InitiatedWithEnum, UrlsTargetRequest, UrlsTargetUpdateRequest, GitHubTargetConfiguration } from '@api-client';
import { allRoutesEnum, globalConstants, globalQueries, globalUtils } from '@shared/duck';
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { formDataTypes } from '../../components';
import { enqueueSnackbar } from 'notistack';
import { targetExclusionsUtils } from '@shared/components';

export const scanWebValidationSchema = (cliFlow: boolean) => Yup.object().shape({
  project: Yup.object().required('Please select a project').nullable(),
  target: Yup.object().nullable()
    .when('newTargetName', {
      is: (newTargetName?: string) => newTargetName && newTargetName?.length > 0,
      then: schema => schema.optional()
        .test('validateName', globalConstants.INVALID_NAME_FORMAT, (_, context) => {
          return globalUtils.validateName(context.parent.newTargetName || '');
        })
        .when('nameExists', {
          is: true,
          then: schema => schema.test('testName', 'This name is already taken in this Project', () => false),
        }),
      otherwise: schema => schema.required('Please enter the name of the target')
        .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: any) => {
          return globalUtils.validateName(value?.name || '');
        })
        .when('nameExists', {
          is: true,
          then: schema => schema.test('testName', 'This name is already taken in this Project', () => false),
        }),
    }),
  bypassUrlValidationCheckbox: Yup.boolean(),
  baseUrl: Yup.string().required('Please enter a URL').nullable()
    .test('test-schema', 'Invalid format: URL schema required', (value?: string | null) => {
      return !cliFlow || !!(value?.startsWith('http://') || value?.startsWith('https://'));
    })
    .when('isUrlAccessible', {
      is: false,
      then: schema => schema
        .test('starts-with-https', 'Invalid format: URL schema required', (value?: string | null) => {
          return !value || !value?.match(globalConstants.HTTPS_PARTS_REGEX);
        })
    })
    .when('bypassUrlValidationCheckbox', {
      is: false,
      then: scheme =>
        scheme
          .test('isValid', 'Invalid format', (value?: string | null) => {
            return globalUtils.validateUrls(value ? [value] : []);
          })
          .when('isUrlAccessible', {
            is: false,
            then: schema => schema.test('isValid', 'URL is not accessible', () => cliFlow),
          }),
      otherwise: schema => schema
        .test('isValid', 'Invalid format', (value?: string | null) => {
          return globalUtils.validateUrls(value ? [value] : []);
        }),
    }),
  newTargetName: Yup.lazy(() => Yup.string()
    .when('target', {
      is: (target?: any) => !!target,
      then: schema => schema.optional(),
      otherwise: schema => schema.required('Please enter the name of the target'),
    })),
  nameExists: Yup.boolean().oneOf([false], 'Name must be unique').required('Please test the name').nullable(false),
  createNewTargetCheckbox: Yup.boolean()
    .when(['target', 'newTargetName', 'nameExists', 'enableTestCheckbox'], {
      is: (target?: any, newTargetName?: string, nameExists?: boolean, enableTestCheckbox?: boolean) =>
        !target && !!newTargetName && nameExists !== undefined && !nameExists && enableTestCheckbox,
      then: schema => schema.test('createNewTarget', 'Please check Create new Target to proceed', (value?: boolean) => {
        return value || false;
      }),
      otherwise: schema => schema.optional(),
    }),
  enableTestCheckbox: Yup.lazy(() => Yup.boolean()
    .when(['target', 'newTargetName', 'nameExists', 'createNewTargetCheckbox'], {
      is: (target?: any, newTargetName?: string, nameExists?: boolean, createNewTargetCheckbox?: boolean) =>
        !target && !!newTargetName && nameExists !== undefined && !nameExists && !createNewTargetCheckbox,
      then: schema => schema.test('checkCreateNewTarget', 'Remove cursor from Target Name field', () => {
        return false;
      }),
    }),
  ),
  configuration: targetExclusionsUtils.TargetExclusionsValuesSchema,
});

export const onSubmit = () => {
  const navigate = useNavigate();

  const { createUrlTargets } = globalQueries.useCreateTargetUrl();
  const { updateUrlTargets } = globalQueries.useUpdateTargetUrl();
  const { createScans } = globalQueries.useCreateScans();

  const onCreateScan = async (
    values: formDataTypes.FormDataFormValues,
    formikHelpers: FormikHelpers<formDataTypes.FormDataFormValues>,
    isOpenCliCommand: boolean,
  ) => {
    try {
      // Initialization
      const scanRequest: ScanRequest =
      {
        credentials_id: values.authentication?.id as string | null,
        target_id: values.target?.id || '',
        initiated_with: InitiatedWithEnum.Web,
      };

      const configuration: GitHubTargetConfiguration = {
        excluded_url_patterns: values.configuration.excluded_url_patterns,
        excluded_x_paths: values.configuration.excluded_x_paths,
      };

      // Create new Target
      if (!isOpenCliCommand && values.createNewTargetCheckbox) {
        const targetToCreate: UrlsTargetRequest = {
          name: values.newTargetName || '',
          location: values.baseUrl || '',
          project: values.project?.id || '',
          internet_accessible: values.bypassUrlValidationCheckbox ? true : values.isUrlAccessible,
        };
        const res = await createUrlTargets({ urlsTargetRequest: [targetToCreate] });

        formikHelpers.setFieldValue('target', res.data[0]);
        formikHelpers.setFieldValue('newTargetName', '');
        formikHelpers.setFieldValue('createNewTargetCheckbox', false);
        formikHelpers.setFieldValue('baseUrl', res.data[0].location);

        scanRequest.target_id = res.data[0].id;
      }

      // Update target if name/url/exclusions was changed
      const initTargetName = values?.target?.name;
      const initTargetUrl = values?.target?.location;
      const newTargetName = values?.newTargetName;
      const newTargetUrl = values?.baseUrl;

      const isTargetNameChanged = newTargetName && (
        initTargetName !== values?.newTargetName
      );
      const isTargetLocationChanged = (
        initTargetUrl !== newTargetUrl
      );
      const isExclusionsChanged = JSON.stringify(values.target?.configuration) !== JSON.stringify(values.configuration);

      if (!isOpenCliCommand && !values?.createNewTargetCheckbox && (
        isTargetNameChanged || isTargetLocationChanged || isExclusionsChanged
      )) {
        const targetToUpdate: UrlsTargetUpdateRequest = {
          name: isTargetNameChanged ? newTargetName : initTargetName,
          location: isTargetLocationChanged ? newTargetUrl : initTargetUrl,
          configuration: isExclusionsChanged ? configuration : undefined,
        };
        const res = await updateUrlTargets({
          id: values.target?.id || '',
          urlsTargetUpdateRequest: targetToUpdate,
        });

        formikHelpers.setFieldValue('target', res.data);
        formikHelpers.setFieldValue('newTargetName', '');
        formikHelpers.setFieldValue('createNewTargetCheckbox', false);
        formikHelpers.setFieldValue('baseUrl', res.data.location);
      }

      // Start a scan from WEB flow
      const res = await createScans({ scanRequest: [scanRequest] });
      navigate(allRoutesEnum.ScanDetails.replace(':scanId', res.data[0].id));
    } catch (error) {
      enqueueSnackbar('Failed to initiate scanning', { variant: 'error' });
      console.error(error);
    }
  };

  return { onCreateScan };
};