import React from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useApp } from '@shared/hooks';
import { allRoutesEnum, globalTypes, globalUtils } from '@shared/duck';
import { observer } from 'mobx-react';
import { useWorkspaceRouter, useBillingRoutes, NotFoundPage, NoAccessPage, ErrorPage, SSOPage } from '@pages';
import { MuiContentLoader } from '@shared/components';
import { Box, Typography } from '@mui/material';

const WorkspaceRoutes: React.FC = observer(() => {
  const app = useApp();
  const navigate = useNavigate();

  const [canNavigate, setCanNavigate] = React.useState<boolean>(false);

  // FIXME: handle public pages properly
  const isPublicRoute = window.location.pathname.startsWith(allRoutesEnum.Error) || window.location.pathname.startsWith(allRoutesEnum.SSO);

  // Wait for subscription info after a successful payment: "?payment=success"
  const params = new URLSearchParams(document.location.search);
  const shouldWaitForSubscription = params.get('payment') === 'success';

  const [isWaitingForSubscription, setIsWaitingForSubscription] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { waitForSubscription } = globalUtils.useWaitForSubscriptionHandler({
    maxAttemptCount: 10,
    probeIntervalMs: 1000,
  });

  // Workaround for React StrictMode to avoid double rendering
  const [isAfterFirstRender, setIsAfterFirstRender] = React.useState(false);
  React.useEffect(() => setIsAfterFirstRender(true), []);

  // Waiting for subscription info...
  React.useEffect(() => {
    // FIXME: handle public pages properly
    if (isPublicRoute) return;
    if (!isAfterFirstRender) return;
    if (!isWaitingForSubscription) return;
    const noSubscription = !app.checkHasFullSubscription() && !app.checkHasPayingSubscription();
    if (noSubscription && shouldWaitForSubscription) {
      waitForSubscription((hasSubscription) => setIsWaitingForSubscription(!hasSubscription))
        .then(({ error }) => error && setErrorMessage(error))
        .finally(() => setIsWaitingForSubscription(false));
    }
  }, [isAfterFirstRender, isWaitingForSubscription]);

  React.useEffect(() => {
    // FIXME: handle public pages properly
    if (isPublicRoute) return;
    if (app.users.me?.firstLogin) {
      // Show "Let's begin!" on first login
      navigate(allRoutesEnum.GetStarted, { replace: true });
    }
    else {
      const noFullSubscription = !app.checkHasFullSubscription();
      const noSubscription = !app.checkHasFullSubscription() && !app.checkHasPayingSubscription();
      if (noSubscription && shouldWaitForSubscription) {
        setIsWaitingForSubscription(true);
      } else if (noFullSubscription) {
        // !!! WARNING do not remove `app.users.plan` check !!!
        // It protects from redirect to Billing page on access token and csrf token refresh.
        if (app.users.plan) {
          // Show "Choose a Plan" if no subscription or API ENVY
          navigate(allRoutesEnum.SelectBillingPlan, { replace: true });
        }
      } else {
        setCanNavigate(true);
      }
    }
  }, [app.users.me?.firstLogin, app.users.plan]);

  const workspacePages = useWorkspaceRouter();
  const billingPages = useBillingRoutes();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedProjects, setSelectedProjects] = React.useState<globalTypes.UIOptionItem<string>[]>([]);
  const [lastPage, setLastPage] = React.useState('');

  const location = useLocation();
  React.useLayoutEffect(() => {
    if (location.pathname !== window.location.pathname) return;
    const projectsParams = searchParams.get('project');
    const currPage = location.pathname;
    if (projectsParams && !!location.search) {
      const newValuesString = projectsParams?.split(',');

      const newValues = newValuesString?.map(filter => {
        const label = filter.split('/')[0];
        const value = filter.split('/')[1];
        return ({
          value: value, label: label, key: value,
        });
      });
      setSearchParams((urlParams) => {
        if (newValues.length) {
          const newSearchValues = newValues.map(filter =>
            `${filter.label}/${filter.value}`).join(',') || '';
          urlParams.set('project', newSearchValues);
          setSelectedProjects(newValues);
        } else {
          urlParams.delete('project');
          setSelectedProjects([]);
        }
        return urlParams;
      }, { replace: true });
    }
    else if (selectedProjects.length
      && currPage !== lastPage
    ) {
      setSearchParams((urlParams) => {
        const newSearchValues = selectedProjects.map(filter =>
          `${filter.label}/${filter.value}`).join(',') || '';
        urlParams.set('project', newSearchValues);
        return urlParams;
      }, { replace: true });
    }
    else {
      setSelectedProjects([]);
    }
    setLastPage(location.pathname);
  }, [location.pathname, searchParams]);

  if (isWaitingForSubscription) {
    return (
      <Box>
        <MuiContentLoader
          isLoading={true}
          variant='dots'
        >
          <></>
        </MuiContentLoader>
        <Box pl='1rem'>
          <Typography>Waiting for subscription information...</Typography>
        </Box>
      </Box>
    );
  }
  if (errorMessage) {
    return (
      <Box p='1rem'>
        <Typography>{errorMessage}</Typography>
      </Box>
    );
  }

  return (
    /** Note: here we will regulate the conditions for the availability of routes
     * including a free plan and a paid plan (or what ever be in the future)
     */
    <Routes>
      {canNavigate && workspacePages}

      {billingPages}

      <Route path={allRoutesEnum.NoAccess} element={<NoAccessPage />} />

      <Route path={allRoutesEnum.Error} element={<ErrorPage />} />

      <Route path={allRoutesEnum.SSO} element={<SSOPage />} />

      {/* `canNavigate` to fix blinking NotFound page at the fresh start */}
      {canNavigate && <Route path='*' element={<NotFoundPage />} />}
    </Routes>
  );
});

export default WorkspaceRoutes;
