import { styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const NoItemsContainer = styled('div') <{ width?: string; height?: string; padded?: boolean }>`
    ${props => (
      props.padded ? 'padding: 0.75rem;' : ''
    )}
    box-sizing: border-box;
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};

    .inner-content {
        gap: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: ${muiPalette.grey?.[100]};
        padding: 1.5rem;
        box-sizing: border-box;
        border-radius: 0.375rem;
    }

    .message {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 0.875rem;
        margin-top: 0.75rem;
        color: ${muiPalette.grey?.[500]};
        min-width: 16rem;
        white-space: pre-line;
        text-align: center;
    }
`;

export { NoItemsContainer };
