import { Tooltip, Typography, IconButton, Stack } from '@mui/material';
import { UIUserAvatar } from '@shared/components';
import React from 'react';
import { globalQueries, globalUtils } from '@shared/duck';
import { Credentials } from '@api-client';
import { useLocation, useNavigate } from 'react-router-dom';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack';
import { muiPalette } from '@shared/general-mui-theme';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { CopyIconC } from '@assets';

interface TargetsTableProps {
  credentials?: Credentials;
  isCredentialsLoading: boolean;
}

const CommonHeader: React.FC<TargetsTableProps> = ({
  credentials,
  isCredentialsLoading,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showModal } = useGlobalModalContext();

  const onBackClick = () => {
    navigate(globalUtils.extractBackLink(pathname));
  };

  const { deleteCredentials } = globalQueries.useDeleteCredentials();
  const onConfirmDelete = async () => {
    try {
      if (credentials?.id) {
        onBackClick();
        await deleteCredentials({ id: credentials.id });
        enqueueSnackbar(<><b>{credentials.name}</b> has been deleted successfully</>, { variant: 'success' });
      }
    }
    catch (error) {
      console.error(error);
      enqueueSnackbar(`Failed to delete ${credentials?.name}`, { variant: 'error' });
    }
  };

  const onDeleteAuthentication = async () => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Authentication?',
        name: credentials?.name,
        onConfirm: onConfirmDelete,
      },
    });
  };

  const onEditCredentials = () => showModal({
    modalType: MODAL_TYPE.AuthenticationModal,
    modalProps: { authenticationId: credentials?.id },
  });

  const title = isCredentialsLoading ? 'Loading...' : credentials?.name;

  const onCopyAuthName = () => {
    navigator.clipboard.writeText(credentials?.name || '')
      .then(() => {
        enqueueSnackbar('Copied to clipboard!', { variant: 'info', autoHideDuration: 1000, closeBtn: false });
      });
  };

  const fullName = `${credentials?.own_user?.first_name || ''} ${credentials?.own_user?.last_name || ''}`.trim();
  return (
    <Stack direction='row' mb='2rem' gap='1rem' alignItems='center'>
      <Tooltip arrow title={title} placement='top'>
        <Typography
          variant='h5'
          lineHeight='2.25rem'
          noWrap
        >
          {title}
        </Typography>
      </Tooltip>
      <CopyIconC
        onClick={onCopyAuthName}
      />
      <Stack direction='row' flexGrow='1' justifyContent='flex-end'>
        <UIUserAvatar
          src={credentials?.own_user?.avatar_url}
          fullName={fullName}
          tooltipText={fullName}
        />
        <Stack gap='0' >
          <Typography variant='default' noWrap>
            Created by
          </Typography>
          <Typography variant='default' color={muiPalette.grey?.[500]} noWrap>
            {credentials?.own_user?.first_name}
          </Typography>
        </Stack>
        <IconButton
          aria-label='Delete'
          color='outlined'
          onClick={onDeleteAuthentication}
        >
          <Trash size={20} />
        </IconButton>
        <IconButton
          aria-label='Edit'
          color='outlined'
          onClick={onEditCredentials}
        >
          <PencilSimple size={20} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CommonHeader;