import { IssueSetResolutionRequest, ResolutionEnum } from '@api-client';
import UIMenu from '@shared/components/menu';
import { globalEnums, globalQueries } from '@shared/duck';
import { IssueResolutionEnum } from 'models/Enums';
import React from 'react';

export interface FalsePositiveMenuProps {
  isFP: boolean;
  request?: Omit<IssueSetResolutionRequest, 'new_resolution'>;
  disabled?: boolean;
  onAfterUpdate?: () => void;
}

export const FalsePositiveMenu: React.FC<FalsePositiveMenuProps> = ({
  isFP,
  request,
  disabled = false,
  onAfterUpdate,
}) => {
  const { setIssuesResolution } = globalQueries.useSetIssuesResolution();

  const onMarkAsFP = async () => {
    if (request) {
      await setIssuesResolution({ issueSetResolutionRequest: { ...request, new_resolution: IssueResolutionEnum.falsePositive as ResolutionEnum } });
      onAfterUpdate?.();
    }
  };

  const onUnmarkAsFP = async () => {
    if (request) {
      await setIssuesResolution({ issueSetResolutionRequest: { ...request, new_resolution: IssueResolutionEnum.open as ResolutionEnum } });
      onAfterUpdate?.();
    }
  };

  const fpLabel = isFP ? 'Unmark as false positive' : 'Mark as false positive';
  const fpAction = isFP ? onUnmarkAsFP : onMarkAsFP ;

  const itemList = [{
    title: fpLabel,
    itemAction: fpAction,
    disabled: disabled,
    tooltipText: 'Please select Issues',
  }];

  return (
    <UIMenu
      itemList={itemList}
      iconVariant={globalEnums.EMuiIconButtonVariants.DEFAULT}
      size='small'
    />
  );
};