import React from 'react';
import { Target } from '@api-client';
import { MuiFilterAutocomplete } from '@shared/components';
import { globalQueries } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { AutocompleteChangeReason } from '@mui/material';
import { UIOptionItemNew } from 'pages/workspace-router/scans/scans-page-wrapper';

interface TargetFilterProps<T> {
  projectIds: string[];
  selectedTargets?: UIOptionItemNew<T>[];
  setSelectedTargets?: (newValues: UIOptionItemNew<T>[], options?: UIOptionItemNew<T>[], reason?: AutocompleteChangeReason) => void;
}

export const TargetFilter = <T,>({
  projectIds,
  selectedTargets,
  setSelectedTargets,
}: TargetFilterProps<T>) => {
  const { debouncedValue, onChange: onInputChange, value = '' } = useDebounce<string>();

  const {
    targets = [],
    fetchNextTargetsPage,
    targetsHasNextPage,
    isTargetsListLoading,
  } = globalQueries.useGetTargetsList({
    project: projectIds,
    order: ['name'],
    pageSize: 25,
    filter: debouncedValue,
  });


  const createNewValues = (target: Target): UIOptionItemNew<T> => (
    { key: target.id, label: target.name, value: target.id as T }
  );

  const { getTarget } = globalQueries.useGetTargetMutation();
  const [isTargetsChecked, setIsTargetsChecked] = React.useState(false);
  React.useEffect(() => {
    const checkTargets = async () => {
      if (selectedTargets?.length && !isTargetsChecked) {
        setIsTargetsChecked(true);
        const optionsToDelete: UIOptionItemNew<T>[] = [];
        const newOptions: UIOptionItemNew<T>[] = [];
        await Promise.all(
          selectedTargets.map(async target => {
            try {
              const { data } = await getTarget({
                id: target.value as string,
              });
              if (data.id === target.value && data.name === target.label) {
                newOptions.push(target);
              }
              else {
                optionsToDelete.push(target);
              }
            }
            catch {
              optionsToDelete.push(target);
            }
          })
        );
        if (optionsToDelete.length) {
          setSelectedTargets?.(newOptions, optionsToDelete, 'removeOption');
        }
      }
    };

    checkTargets();
  }, [selectedTargets]);

  return (
    <MuiFilterAutocomplete<UIOptionItemNew<T>>
      filterName='Target'
      options={targets.map(target => createNewValues(target))}
      loading={isTargetsListLoading}
      inputValue={value}
      onInputChange={onInputChange}
      selectedValues={selectedTargets}
      setSelectedValues={setSelectedTargets}
      getOptionKey={option => option.key || option.value as string}
      getOptionLabel={option => option.label}
      fetchNextPage={() => targetsHasNextPage && fetchNextTargetsPage()}
    />
  );
};