import React from 'react';
import { Mail, /* Phone */ } from 'assets';
import { globalConstants, globalUtils } from '@shared/duck';
import { Link, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const FullScreenFooter: React.FC = () => {
  return (
    <Stack gap='2rem' p='2rem' >
      <Typography variant='default' alignSelf='center'>
        For Enterprise plans, contact&nbsp;
        <Link
          href={'mailto:' + globalConstants.SALES_EMAIL}
          sx={{ ':hover': { textDecorationColor: muiPalette.grey?.[500] } }}
        >
          <Typography variant='default' color={muiPalette.grey?.[500]}>
            {globalConstants.SALES_EMAIL}
          </Typography>
        </Link>
      </Typography>
      <Stack direction='row' justifyContent='space-between'flexWrap='wrap'>
        <Typography variant='default' color={muiPalette.grey?.[500]}>
          &copy; NightVision {new Date().getFullYear()}
        </Typography>
        <Stack direction='row'>
          {/* <Asset src={Phone} />
        <a href={'tel:' + globalConstants.CONTACT_TEL}>
          <Text fontColor="gray500" marginLeft="spacingXs" marginRight="spacingL">
            {globalConstants.CONTACT_TEL}
          </Text>
        </a> */}
          {globalUtils.createCustomIcon(Mail, {})}
          <Link
            href={'mailto:' + globalConstants.CONTACT_EMAIL}
            sx={{ ':hover': { textDecorationColor: muiPalette.grey?.[500] } }}
          >
            <Typography variant='default' color={muiPalette.grey?.[500]}>
              {globalConstants.CONTACT_EMAIL}
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FullScreenFooter;
