import { styled, Typography } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

const Title = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${baseTokens.gray700};
  margin-top: 1.5rem;
`;

const Description = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${baseTokens.gray700};
`;

export { Title, Description };