import { TextLink } from '@contentful/f36-components';
import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

export const StyledCustomIconLink = styled(TextLink)`
  color: ${baseTokens.gray800};
  text-decoration: unset;

  &:hover, &:focus {
    color: ${baseTokens.gray800};
    text-decoration: unset;
  }
`;
