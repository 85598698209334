import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

const HorizontalDivider = styled('hr')`
  background-color: ${baseTokens.gray200};
  height: 0.0625rem;
  width: 100%;
  border: none;
  margin: 0;
`;

export default HorizontalDivider;