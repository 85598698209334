import { Heading, SubHeading, Image, Content } from './blocked-user.styled';
import { CONTACT_EMAIL } from '@shared/duck/constants';
import { Box, Link, Stack } from '@mui/material';

const BlockedUserPage = () => {
  return (
    <Content>
      <Stack alignItems='flex-start' gap='3rem' flex='1 0 0'>
        <Box ml='5rem' mr='5rem'>
          <Heading>You are not allowed to access the service</Heading>
          <SubHeading>
            Your account is blocked.
            <br/>
            {'Please contact support at '}
            <Link href={`mailto:${CONTACT_EMAIL}`}>
              {CONTACT_EMAIL}
            </Link>
            {' to solve this issue.'}
          </SubHeading>
        </Box>
      </Stack>
      <Image />
    </Content>
  );
};

export default BlockedUserPage;
