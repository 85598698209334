
import { UIDialogTitle } from '@shared/components';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { globalQueries, globalUtils } from '@shared/duck';
import { Dayjs } from 'dayjs';
import React from 'react';
import { GeneratedTokenContent, ExpirationSelectContent } from './components';
import { EXPIRATION_SELECT_OPTIONS, TokenResult, getNewDate } from './duck';
import { enqueueSnackbar } from 'notistack';

interface GenerateTokenModalProps {
  open: boolean;
  onClose: () => void;
}

const GenerateTokenModal: React.FC<GenerateTokenModalProps> = ({
  open,
  onClose: onCloseModal,
}: GenerateTokenModalProps) => {
  const [selectedExpireDate, setSelectedExpireDate] = React.useState<Dayjs | null>(getNewDate(EXPIRATION_SELECT_OPTIONS[1].value));
  const [tokenResult, setTokenResult] = React.useState<TokenResult | undefined>();

  const { createNightVisionToken, isLoading } = globalQueries.useCreateNightVisionToken();

  const cleanup = () => {
    setTokenResult(undefined);
  };

  const onClose = () => {
    cleanup();
    onCloseModal();
  };

  const onCopyToken = (token?: string) => {
    token && globalUtils.copyTextToClipboard(token, 'Copied to clipboard!', 2000);
  };

  const onGenerateClick = async (expiry: Dayjs | null) => {
    try {
      const { data } = await createNightVisionToken({
        cliTokenCreateRequest: {
          expiry: expiry?.format('YYYY-MM-DDT00:00') || null
        }
      });
      setTokenResult({ token: data.token, expireDate: data.expiry || undefined });
      onCopyToken(data.token);
    }
    catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to create NightVision token', { variant: 'error' });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <UIDialogTitle
        title={tokenResult ? 'Token generated!' : 'Generate API token'}
        onCloseBtnAction={onClose}
      />
      <DialogContent dividers>
        {!tokenResult ? (
          <ExpirationSelectContent
            expireDate={selectedExpireDate}
            setExpireDate={setSelectedExpireDate}
          />
        ) : (
          <GeneratedTokenContent
            tokenResult={tokenResult}
            onCopyTokenClick={() => onCopyToken(tokenResult.token)}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          variant={'outlined'}
          onClick={() => tokenResult ? cleanup() : onClose()}
        >
          {tokenResult ? 'Generate another one' : 'Cancel'}
        </Button>
        <Button
          variant='contained'
          disabled={isLoading}
          endIcon={isLoading && (
            <CircularProgress color='inherit' size={20} />
          )}
          onClick={() => tokenResult ? onClose() : onGenerateClick(selectedExpireDate)}
        >
          {tokenResult ? 'Done' : 'Generate'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateTokenModal;
