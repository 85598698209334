import {  SelectProps, TextField } from '@mui/material';
import { MuiAutocomplete } from '@shared/components';
import { globalTypes } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { useFormikContext } from 'formik';
import React from 'react';

export const JobTitleOptions: string[] = [
  'C-Suite Executive',
  'Board Director',
  'Security Leader/Manager',
  'Security Researcher',
  'DevOps/SecOps',
  'Software Developer',
  'Penetration Tester',
  'InfoSec Training Professional',
];

const JobTitle: React.FC<SelectProps> = ({
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext<globalTypes.ProfileFormValues>();
  const { debouncedValue = '', onChange: onDebounceChange, value = '' } = useDebounce<string>(values.jobTitle || '');

  React.useEffect(() => {
    if (values.jobTitle !== debouncedValue) {
      setFieldValue('jobTitle', debouncedValue);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (values.jobTitle !== value) {
      onDebounceChange(values.jobTitle);
    }
  }, [values.jobTitle]);

  return (
    <MuiAutocomplete
      value={value}
      onChange={(_, newValue) => {
        onDebounceChange(newValue || '');
      }}
      onBlur={(e: any) => {
        onDebounceChange(e.target.value);
      }}
      onInputChange={(_, value) => {
        onDebounceChange(value);
      }}
      autoComplete
      autoHighlight
      filterSelectedOptions
      hidePopupIndicator
      options={JobTitleOptions}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Enter your job title'
          onBlur={props.onBlur}
          name={props.name}
          error={props.error}
        />
      )}
    />
  );
};

export default JobTitle;