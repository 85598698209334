import React from 'react';
import { StyledTextInput, TextInputContainer } from './text-input.styled';
import { globalTypes } from '@shared/duck';


const UITextInput = React.forwardRef<HTMLInputElement, globalTypes.UITextInputProps>((props: globalTypes.UITextInputProps, ref) => {
  const { endIcon, startIcon, ...otherProps } = props;

  return endIcon || startIcon ? (
    <TextInputContainer endIcon={endIcon} startIcon={startIcon}>
      <div className='start-icon-container'>{startIcon}</div>
      <StyledTextInput {...otherProps} ref={ref} />
      <div className='end-icon-container'>{endIcon}</div>
    </TextInputContainer>
  ) : (
    <StyledTextInput {...otherProps} ref={ref} />
  );
});

UITextInput.displayName = 'TextInput';

export default UITextInput;
