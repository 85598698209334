import { useCallback, useEffect, useState } from 'react';
import { SortByColumn, TableProps } from './Table.interfaces';
import { globalTypes } from '@shared/duck';

interface TableSorting {
  sortBy?: SortByColumn;
  onSortColumnClick: (columnKey: string) => void;
}

type TableSortingProps<DataItem> = Pick<TableProps<DataItem>, 'initialSortBy' | 'onSortByChanged'>;

function useTableSorting<DataItem extends globalTypes.UIDItem>(props: TableSortingProps<DataItem>): TableSorting {
  const { initialSortBy, onSortByChanged } = props;
  const [sortBy, setSortBy] = useState<SortByColumn | undefined>(initialSortBy);

  const onSortColumnClick = useCallback(
    (columnKey: string) => {
      if (sortBy?.columnKey !== columnKey) {
        setSortBy({
          order: globalTypes.SortingDirections.asc,
          columnKey,
        });
      } else if (sortBy?.order === globalTypes.SortingDirections.desc) {
        setSortBy(undefined);
      } else {
        setSortBy({
          order: globalTypes.SortingDirections.desc,
          columnKey,
        });
      }
    },
    [sortBy],
  );

  useEffect(() => {
    if (onSortByChanged) {
      onSortByChanged(sortBy);
    }
  }, [sortBy]);

  return {
    sortBy,
    onSortColumnClick,
  };
}

export default useTableSorting;
