import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useDebounce } from '@shared/hooks';
import { StyledGridItem } from './endpoints-tab.styled';
import { globalConstants, globalQueries, globalTypes, globalUtils } from '@shared/duck';
import { ExportMenu } from './components';
import { endpointsToCsv } from './duck';
import saveAs from 'file-saver';
import {
  UIHttpMethodBadge,
  NoContentPlaceholder,
  MuiSearchInput,
  MuiContentLoader,
} from '@shared/components';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { SpecStatusEnum } from '@api-client';

export interface EndpointsTabProps {
  openApiTargetId: string;
}

const EndpointsTab: React.FC<EndpointsTabProps> = ({
  openApiTargetId,
}: EndpointsTabProps) => {
  const { setError } = React.useContext(ErrorContext);
  const [currSpecStatus, setCurrSpecStatus] = React.useState<SpecStatusEnum>();
  const stopPingTargetSpecStatuses = [...globalConstants.ERROR_SPEC_STATUSES, ...globalConstants.COMPLETED_SPEC_STATUSES];

  const { openApiTarget, isOpenApiTargetLoading } = globalQueries.useGetOpenApiTarget({
    id: openApiTargetId,
  }, {
    refetchInterval: currSpecStatus && !stopPingTargetSpecStatuses.includes(currSpecStatus) && 5000,
    onError: err => errorResponseHandler(err, 'target', setError),
  });
  const [endpoints, setEndpoints] = React.useState<{ [key: string]: any }[]>([]);
  const { debouncedValue, onChange: onSearchChange } = useDebounce<string>();
  const itemList: globalTypes.UIMenuItemsList[] = [{ title: 'CSV', itemAction: () => onExportClick() }];

  React.useEffect(() => {
    const filteredEndpoints =
      openApiTarget?.endpoints?.filter(item => (
        item.url_path || ''
      ).toLowerCase().includes(debouncedValue?.toLowerCase() || ''),
      ) || [];
    setEndpoints(filteredEndpoints);
    setCurrSpecStatus(openApiTarget?.spec_status);
  }, [debouncedValue, openApiTarget]);

  const onExportClick = React.useCallback(() => {
    const text = endpointsToCsv(endpoints);
    const value = new Blob([text], { type: 'text/plain;charset=utf-8' });
    saveAs(value, `endpoints-${openApiTargetId}.csv`);
  }, [endpoints]);

  return (
    <Box
      width='100%'
      paddingTop='1.5rem'
      display='flex'
    >
      <Stack gap={1.5} width='100%'>
        <Stack flexDirection={'row'}>
          <MuiSearchInput
            width='100%'
            placeholder='Search'
            onChange={e => onSearchChange(e.target.value)}
          />
          <ExportMenu text='Export as' itemList={itemList} isDisabled={endpoints?.length === 0} />
        </Stack>

        <MuiContentLoader
          variant='linear'
          isLoading={isOpenApiTargetLoading || (openApiTarget?.endpoints?.length ? !endpoints.length : false) || (currSpecStatus && !stopPingTargetSpecStatuses.includes(currSpecStatus))}
        >
          {endpoints?.length > 0 ? (
            <Grid container overflow='auto'>
              {endpoints?.map(item => (
                <React.Fragment key={item.url_path + item.http_method}>
                  <StyledGridItem item xl={1.5} sm={2.5} xs={4}>
                    <UIHttpMethodBadge method={item.http_method as string} />
                  </StyledGridItem>
                  <StyledGridItem item xl={10.5} sm={9.5} xs={8}>
                    <Tooltip arrow title={item.url_path} enterDelay={500} enterNextDelay={500} placement='top'>
                      <Typography variant='subtitle2' color='textSecondary' noWrap>
                        {item.url_path}
                      </Typography>
                    </Tooltip>
                  </StyledGridItem>
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <NoContentPlaceholder
              height='18.75rem'
              message={'No endpoints found' + ((currSpecStatus && globalConstants.ERROR_SPEC_STATUSES.includes(currSpecStatus))
                ? `\n${globalUtils.specStatusToTextMessage(currSpecStatus)}`
                : '')}
            />
          )}
        </MuiContentLoader>
      </Stack>
    </Box>
  );
};

export default EndpointsTab;
