import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

interface TokensTableApiResult {
  tokens: globalModels.TokenViewModel[],
  totalItemsCount: number,
  isTokensListLoading: boolean,
  onTokensRowClick?: (item: globalModels.TokenViewModel) => void,
}

export const useTokensTableApi = (
  {
    page,
    pageSize,
  }: {
    page: number;
    pageSize?: number;
  }): TokensTableApiResult => {
  const {
    tokens: dtoTokens,
    totalItemsCount,
    isTokensListLoading,
    isTokensListFetching,
  } = globalQueries.useGetTokensList({
    page: page + 1,
    pageSize: pageSize,
  });

  const tokens = React.useMemo(
    () =>
      dtoTokens?.map(
        (item) => globalUtils.mapTokensDtoToViewModel(item)) || [],
    [dtoTokens],
  );

  return {
    tokens,
    totalItemsCount,
    isTokensListLoading: isTokensListLoading || isTokensListFetching,
  };
};

export const useDeleteTokenHandler = () => {
  const { deleteToken } = globalQueries.useDeleteToken();
  const onDeleteToken = async (digest: string) => {
    try {
      await deleteToken({ digest });
      enqueueSnackbar('Token has been deleted successfully', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to delete token', { variant: 'error' });
    }
  };

  const { showModal } = useGlobalModalContext();

  const deleteTokenHandler = async (digest: string, tokenKey: string) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Token?',
        deleteText: 'Are you sure you want to delete this token',
        name: tokenKey,
        onConfirm:() => onDeleteToken(digest),
      },
    });
  };

  return { deleteTokenHandler };
};
