import React from 'react';
import { Description, Title } from './cli-commands.styled';
import { UICodeFragment } from '@shared/components';
import { SupportedLangs } from '@shared/duck/enums/global-enums';
import { useFormikContext } from 'formik';
import { formDataTypes } from '../../../components';
import { globalConstants, globalEnums } from '@shared/duck';
import _ from 'lodash';

const CliCommands: React.FC = () => {
  const { values } = useFormikContext<formDataTypes.FormDataFormValues>();
  const initTargetName = values?.target?.name;
  const initTargetUrl = values?.target?.location;
  const newTargetName = values?.newTargetName;
  const newTargetUrl = values?.baseUrl;
  const projectId = values?.project?.id;

  const initUrlPatterns = values.target?.configuration?.excluded_url_patterns || [];
  const initXPaths = values.target?.configuration?.excluded_x_paths || [];
  const newUrlPatterns = values.configuration.excluded_url_patterns?.filter(Boolean);
  const newXPaths = values.configuration.excluded_x_paths?.filter(Boolean);
  const configuration = {
    excluded_url_patterns: newUrlPatterns.length
      ? newUrlPatterns.map(el => `${globalConstants.EXCLUDE_URL_FLAG} "${el}"`)
      : [`${globalConstants.EXCLUDE_URL_FLAG} ""`],
    excluded_x_paths: newXPaths.length
      ? newXPaths.map(el => `${globalConstants.EXCLUDE_XPATH_FLAG} "${el}"`)
      : [`${globalConstants.EXCLUDE_XPATH_FLAG} ""`],
  };

  const isTargetNameChanged = newTargetName && (initTargetName !== values?.newTargetName);
  const isTargetLocationChanged = initTargetUrl !== newTargetUrl;
  const isTargetUrlPatternsChanged = !_.isEqual(initUrlPatterns, newUrlPatterns);
  const isTargetXPathsChanged = !_.isEqual(initXPaths, newXPaths);
  const isNewTarget = values.createNewTargetCheckbox;
  let step = 1;

  return (
    <Description variant='caption'>
      {isNewTarget && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'Let’s create a new target first.'}
          <UICodeFragment
            code={
              `# Create target\nnightvision target create ${newTargetName} ${newTargetUrl}` +
              (newUrlPatterns.length ? ` ${configuration.excluded_url_patterns.join(' ')}` : '') +
              (newXPaths.length ? ` ${configuration.excluded_x_paths.join(' ')}` : '') +
              ` -P ${projectId} --type ${globalEnums.ECLITargetType.WEB}`
            }
            language={SupportedLangs.bash} />
          <br />
        </>
      )}

      {!isNewTarget && (isTargetNameChanged || isTargetLocationChanged || isTargetUrlPatternsChanged || isTargetXPathsChanged) && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'We have noticed that you made changes to the target; let\'s apply those first:'}
          <UICodeFragment
            code={
              `# Update target\nnightvision target update ${initTargetName}` +
            (isTargetNameChanged ? ` -n ${newTargetName}` : '') +
            (isTargetLocationChanged ? ` -u ${newTargetUrl}` : '') +
            (isTargetUrlPatternsChanged ? ` ${configuration.excluded_url_patterns.join(' ')}` : '') +
            (isTargetXPathsChanged ? ` ${configuration.excluded_x_paths.join(' ')}` : '') +
            ` -P ${projectId}`
            }
            language={SupportedLangs.bash} />
          <br />
        </>
      )}

      <Title>{step === 1 ? <br /> : `Step ${step++}`}</Title>
      {'Run scan:'}
      <UICodeFragment code={`# Run scan\nnightvision scan ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} -P ${projectId} ${values?.authentication ? `--auth ${values?.authentication.name}` : ''}`} language={SupportedLangs.bash} />
    </Description>
  );
};

export default CliCommands;