import { Credentials } from '@api-client';
import { globalQueries } from '@shared/duck';
import { useDebounce } from '@shared/hooks';
import { AutocompleteChangeReason } from '@mui/material';
import { UIOptionItemNew } from 'pages/workspace-router/scans/scans-page-wrapper';
import { MuiFilterAutocomplete } from '@shared/components';
import React from 'react';

interface MuiAuthenticationFilterProps<T> {
  projectIds: string[];
  selectedAuthentications?: UIOptionItemNew<T>[];
  setSelectedAuthentication?: (newValues: UIOptionItemNew<T>[], options?: UIOptionItemNew<T>[], reason?: AutocompleteChangeReason) => void;
}

export const MuiAuthenticationFilter = <T,>({
  projectIds,
  selectedAuthentications,
  setSelectedAuthentication,
}: MuiAuthenticationFilterProps<T>) => {
  const { debouncedValue, onChange: onInputChange, value = '' } = useDebounce<string>();

  const {
    credentials = [],
    fetchNextCredentialsPage,
    credentialsHasNextPage,
    isCredentialsListLoading,
  } = globalQueries.useGetCredentialsList({
    project: projectIds,
    order: ['name'],
    pageSize: 25,
    filter: debouncedValue,
  });

  const createNewValues = (auth: Credentials): UIOptionItemNew<T> => (
    { key: auth.id, label: auth.name, value: auth.id as T }
  );

  const { getAuthentication } = globalQueries.useGetCredentialsMutation();
  const [isAuthenticationChecked, setIsAuthenticationChecked] = React.useState(false);
  React.useEffect(() => {
    const checkProjects = async () => {
      if (selectedAuthentications?.length && !isAuthenticationChecked) {
        setIsAuthenticationChecked(true);
        const optionsToDelete: UIOptionItemNew<T>[] = [];
        const newOptions: UIOptionItemNew<T>[] = [];
        await Promise.all(
          selectedAuthentications.map(async app => {
            try {
              const { data } = await getAuthentication({
                id: app.value as string,
              });
              if (data.id === app.value && data.name === app.label) {
                newOptions.push(app);
              }
              else {
                optionsToDelete.push(app);
              }
            }
            catch {
              optionsToDelete.push(app);
            }
          })
        );
        if (optionsToDelete.length) {
          setSelectedAuthentication?.(newOptions, optionsToDelete, 'removeOption');
        }
      }
    };

    checkProjects();
  }, [selectedAuthentications]);

  return (
    <MuiFilterAutocomplete<UIOptionItemNew<T>>
      filterName='Authentication'
      options={credentials.map(cred => createNewValues(cred))}
      loading={isCredentialsListLoading}
      inputValue={value}
      onInputChange={onInputChange}
      selectedValues={selectedAuthentications}
      setSelectedValues={setSelectedAuthentication}
      getOptionKey={option => option.key || option.value as string}
      getOptionLabel={option => option.label}
      fetchNextPage={() => credentialsHasNextPage && fetchNextCredentialsPage()}
    />
  );
};