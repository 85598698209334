import { CredentialsType, InitiatedWithEnum } from '@api-client';

export enum ELinksType {
  TEXT = 'text',
  EXT_ICON = 'external_icon',
  CUSTOM_ICON = 'custom_icon'
}

export enum EMenuType {
  BASIC,
  COMPLEX
}

export enum ETableType {
  BASIC,
}

export enum ETableColorTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ETargetTypes {
  WEB,
  OPEN_API
}

export enum EKeyboardKeys {
  BACKSPACE = 'Backspace',
  ARROWDOWN = 'ArrowDown',
  ARROWUP = 'ArrowUp',
  ENTER = 'Enter',
}

export enum CheckStatusEnum {
  FAIL = 0,
  PASS = 1,
  DISRUPTED = 2,
  IN_PROGRESS = 3,
}

export const CheckStatusName = new Map<CheckStatusEnum, string>([
  [CheckStatusEnum.FAIL, 'Discovered'],
  [CheckStatusEnum.PASS, 'Pass'],
  [CheckStatusEnum.DISRUPTED, 'Disrupted'],
  [CheckStatusEnum.IN_PROGRESS, 'In Progress'],
]);

export enum ESortingDirections {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SubscriptionPlan {
  NONE = 'none',
  TRIAL = 'trial',
  API_ENVY = 'api_envy',
  PROFESSIONAL = 'professional',
  ENTERPRISE = 'enterprise',
  CANCELED = 'canceled',
}

export enum SubscriptionStatus {
  NONE = 'none',
  INCOMPLETE = 'incomplete',
  // INCOMPLETE_EXPIRED = 'incomplete_expired',
  ACTIVE = 'active',
  ENDING = 'ending',
  PAST_DUE = 'past_due',
  OVERDUE = 'overdue',
  PAUSED = 'paused',
  TRIALING = 'trialing',
  TRIAL_WILL_END = 'trial_will_end',
  CANCELED = 'canceled',
}

export enum ENucleiType {
  BUILT_IN = 'NUCLEI_BUILT_IN',
  CUSTOM = 'NUCLEI_CUSTOM',
}

export enum ENucleiSubType {
  PROJECT_DISCOVERY = 'project_discovery_nuclei_template',
  NIGHTVISION = 'nightvision_nuclei_template',
}

export enum EBadgeVariants {
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  WARNING = 'warning',
  SECONDARY = 'secondary',
  FEATURED = 'featured',
  YELLOW = 'yellow',
  UNSPECIFIED = 'Unspecified'
}

export enum MuiBadgeColor {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum EButtonVariants {
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
}

export enum EMuiButtonColors {
  PRIMARY = 'primary',
  WARNING = 'warning',
  SECONDARY = 'secondary',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  TRANSPARENT = 'transparent',
}

export enum EMuiButtonVariants {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

export enum EMuiIconButtonVariants {
  DEFAULT = 'default',
  OUTLINED = 'outlined'
}

export enum ENoteVariants {
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  WARNING = 'warning',
  PREMIUM = 'premium',
}

export enum EIconVariants {
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  WARNING = 'warning',
  SECONDARY = 'secondary',
  MUTED = 'muted',
  WHITE = 'white',
}

export enum ETargetType {
  OpenAPI = 'OPENAPI',
  URL = 'URL',
  GitHub = 'GITHUB',
}

export enum ECLITargetType {
  API = 'API',
  WEB = 'WEB',
}

export interface EFileExtension {
  mime: string[];
  exts?: string[];
}

export const EFileExtension: Record<string, EFileExtension> = {
  YAML: { mime: ['application/x-yaml'], exts: ['yaml', 'yml'] },
  JSON: { mime: ['application/json'], exts: ['json'] },
  IMAGE: { mime: ['image/*'], exts: ['gif', 'png', 'jpg', 'jpeg', 'svg'] },
  CSV: { mime: ['text/csv', 'csv/*'], exts: ['csv'] },
  HAR: { mime: ['application/json'], exts: ['har'] },
};

export const authenticationTypeNameMap = new Map<CredentialsType, string>([
  [CredentialsType.Header, 'Header Authentication'],
  [CredentialsType.Cookie, 'Cookie Authentication'],
  [CredentialsType.UserPass, 'Username/Password'],
  [CredentialsType.Script, 'Recorded Authentication (Playwright)'],
]);

export const scanInitiatedWithMap = new Map<InitiatedWithEnum, string>([
  [InitiatedWithEnum.Web, 'Web App'],
  [InitiatedWithEnum.Cli, 'NightVision CLI'],
]);

export enum EAvatarVariants {
  CIRCULAR = 'circular',
  ROUNDED = 'rounded',
  SQUARE = 'square',
}

export enum SupportedLangs {
  javascript = 'javascript',
  python = 'python',
  html = 'html',
  bash = 'bash',
  yaml = 'yaml',
  markdown = 'markdown',
  evidence_markup = 'evidence_markup',
}

export enum SupportedPlugins {
  commandLine = 'command-line',
  lineNumbers = 'line-numbers',
  autolinker = 'autolinker',
  lineHighlight = 'line-highlight',
}

/** Note: In order to avoid console warnings:
 *  Warning: Received `true` for a non-boolean attribute `ATTRIBUTE_NAME`.
 */
export enum EmotionBoolean {
  True = 1,
  False = 0,
}

export enum HttpMethodEnum {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export enum CopyIconVariantsEnum {
  DEFAULT = 'default',
  BLACK = 'black',
  BLUE = 'blue'
}

export enum OS {
  Windows = 'Windows',
  Mac = 'Mac',
  Linux = 'Linux',
  Android = 'Android',
  Unknown = 'Unknown',
}

export enum Processor {
  x64 = 'x64',
  x86 = 'x86',
  ARM = 'ARM',
  ARM64 = 'ARM 64-bit',
  Intel = 'Intel',
  PowerPC = 'PowerPC or 68K',
  Unknown = 'Unknown',
}

export enum HAR_FILE_STATUS {
  NO_FILE = 'NO_FILE',
  WAITING_FOR_UPLOAD = 'WAITING_FOR_UPLOAD',
  VALID = 'VALID',
}