import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { UIDialogTitle } from '@shared/components/dialog';
import * as Yup from 'yup';
import { FormikControlMui } from '@shared/components/formik';
import { MuiTextInput } from '@shared/components/text-input';
import { globalQueries } from '@shared/duck';
import { enqueueSnackbar } from 'notistack';
import { useGlobalModalContext } from '@shared/duck/contexts';

interface EditHarNameFormValues {
  name: string;
  description: string;
}

export const EditHarNameValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').max(100, 'Name must be at most 100 characters'),
  description: Yup.string().optional(),
});

export interface EditHarInfoModalProps {
  targetId: string;
  harId: number;
  initialName: string;
  initialDescription?: string;
}

export const EditHarInfoModal: React.FC<EditHarInfoModalProps> = ({
  targetId,
  harId,
  initialName,
  initialDescription = '',
}: EditHarInfoModalProps) => {
  const [openModal, setOpenModal] = React.useState(true);

  const handleClose = () => {
    setOpenModal(false);
  };

  const { hideModal } = useGlobalModalContext();

  const { editHarFileInfo } = globalQueries.useEditHarFileInfo();
  const onSubmit = async (values: EditHarNameFormValues, formikHelpers: FormikHelpers<EditHarNameFormValues>) => {
    try {
      await editHarFileInfo({ id: targetId, harFileId: harId, harFileUpdateRequest: { name: values.name, description: values.description } });
      handleClose();
    }
    catch (error: any) {
      if (error?.response?.data['errors'][0].code === 'duplicate_name') {
        formikHelpers.setFieldError('name', 'HAR file with this name already exists');
      }
      else {
        enqueueSnackbar('Error updating HAR file name', { variant: 'error' });
      }
    }
  };

  return (
    <Formik<EditHarNameFormValues>
      initialValues={{ name: initialName, description: initialDescription }}
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={EditHarNameValidationSchema}
    >
      {({ values, isSubmitting, isValid }) => {
        const changed = values.name !== initialName || values.description !== initialDescription;
        return (
          <Dialog
            open={openModal}
            onClose={handleClose}
            onTransitionExited={hideModal}
            maxWidth='sm'
            fullWidth
          >
            <Form>
              <UIDialogTitle
                title='Update HAR file info'
                onCloseBtnAction={handleClose}
              />
              <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <FormikControlMui name='name' fullWidth label='Name' showError>
                  <MuiTextInput placeholder='Enter name' />
                </FormikControlMui>
                <FormikControlMui name='description' fullWidth label='Description' showError>
                  <MuiTextInput placeholder='Enter description' />
                </FormikControlMui>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                  size='small'
                  disabled={isSubmitting}
                  onClick={handleClose}
                >
                  {'Cancel'}
                </Button>
                <Button
                  size='small'
                  type='submit'
                  variant='contained'
                  disabled={!isValid || isSubmitting || !changed}
                  endIcon={isSubmitting && (
                    <CircularProgress color='inherit' size={20} />
                  )}
                >
                  {'Update'}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};