import { allRoutesEnum } from '@shared/duck';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Content,
} from './not-found-page.styled';
import { Typography, Button, Box } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Content>
      <Box className='content-box'>
        <Typography variant='default' color={muiPalette.blue?.[600]} mb='1rem'>404 error</Typography>
        <Typography
          fontWeight='600' fontSize='3rem' lineHeight='3.5rem'
          letterSpacing='0.02275rem'
        >
          Uh oh, we can’t find that page...
        </Typography>
        <Typography
          fontWeight='600' fontSize='1.25rem' lineHeight='2rem'
          color={muiPalette.grey?.[500]} mt='1rem'
        >
          We searched high and low, but couldn’t find what you’re looking for.
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            navigate(allRoutesEnum.Home);
          }}
          sx={{
            mt: '2rem',
          }}
        >
          Go back home
        </Button>
      </Box>
    </Content>
  );
};

export default NotFoundPage;
