import { AttentionGray } from '@assets';
import { Paper, TableContainer, TablePagination } from '@mui/material';
import { NoContentPlaceholder } from '@shared/components';
import { BasicMuiTable } from './basic';
import { LoadMoreButton } from './components';
import { globalConstants, globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';
import { tableTypes } from './duck';

interface TableProps<DataItem> extends tableTypes.MuiTableProps<DataItem> {
  data: DataItem[],
  columns: tableTypes.ColumnProps<DataItem>[];
}

const MuiTable = <DataItem extends globalTypes.UIDItem>({
  data,
  columns,
  loading,
  noContentMessage,
  noContentIcon = AttentionGray,
  noContentHeight = '18.75rem',
  withExternalBorders = true,
  pagination,
  isLoadMoreAvailable = false,
  onLoadMoreClick,
  ...props
}: TableProps<DataItem>) => {

  const maxHeight = React.useMemo(() => {
    const isPagination = pagination || isLoadMoreAvailable;
    const footerHeight = isPagination ? '3.25rem' : '0.1rem';
    if (loading) {
      return data.length ? `calc(100% - ${footerHeight})` : 'initial';
    } else {
      return `calc(100% - ${footerHeight})`;
    }
  }, [loading, data]);

  React.useEffect(() => {
    if (!data.length && !loading && pagination?.page && pagination.page > 0) {
      pagination?.onPageChange && pagination?.onPageChange(null, pagination.page - 1);
    }
  }, [data, loading]);

  return (
    <Paper
      sx={{
        width: '100%',
        boxShadow: 'initial',
        boxSizing: 'border-box',
        border: withExternalBorders ? `1px solid ${muiPalette.grey?.[200]}` : 'initial',
        overflow: 'hidden',
      }}
    >
      <TableContainer sx={{ maxHeight: maxHeight }}>
        <BasicMuiTable<DataItem>
          data={data}
          columns={columns}
          loading={loading}
          {...props} />
      </TableContainer>

      {!loading && !data.length && (
        <NoContentPlaceholder
          height={noContentHeight}
          iconSrc={noContentIcon}
          message={noContentMessage || 'No items found'}
        />
      )}

      {data.length && pagination ? (
        <TablePagination
          component='div'
          rowsPerPageOptions={globalConstants.ITEMS_PER_PAGE_OPTIONS}
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={pagination.onPageChange}
          onRowsPerPageChange={pagination.onRowsPerPageChange}
        />
      ) : null}


      {/** Note: need LoadMoreButton for tables with DynamoDB */}
      {data.length && !pagination && isLoadMoreAvailable && onLoadMoreClick ? (
        <LoadMoreButton
          isMui
          onLoadMoreClick={onLoadMoreClick}
          colorTheme={props.colorTheme}
        />
      ) : null}
    </Paper>
  );
};

export default MuiTable;