import { styled, css } from '@mui/material';
import { IssueResolutionEnum } from 'models/Enums';
import { ResolutionOpen, ResolutionResolved, ResolutionFalsePositive } from '@assets';
import baseTokens from '@contentful/f36-tokens';
import { IssueResolutionSelectProps } from './issue-resolution-select-old';
import { SelectOld } from '..';

const getBackgroundStyles = ({
  resolution,
  withBorders,
  withPaddings,
  bgColor,
}: IssueResolutionSelectProps) => {
  let icon = '';
  let borderColor = '';
  let border = '';

  switch (resolution) {
    case IssueResolutionEnum.open:
      icon = ResolutionOpen;

      withBorders ? borderColor = baseTokens.orange400 : border = 'initial';
      break;

    case IssueResolutionEnum.resolved:
      icon = ResolutionResolved;

      withBorders ? borderColor = baseTokens.green400 : border = 'initial';

      break;

    case IssueResolutionEnum.falsePositive:
      icon = ResolutionFalsePositive;

      withBorders ? borderColor = baseTokens.blue400 : border = 'initial';

      break;
  }

  return icon
    ? css`
      & input {
        background-image: url(${icon});
        background-repeat: no-repeat;
        background-position-x: ${withPaddings ? '1rem' : 'initial'};
        background-position-y: center;
        background-size: 1.125rem;
        padding-left: ${withPaddings ? '2.325rem' : '1.575rem'};
        border-color: ${borderColor};
        border: ${border};
        background-color: ${bgColor};

        &:focus {
          border-color: ${borderColor};
          border: ${border};
          box-shadow: initial;
        }
      }

      & button {
        border-color: ${borderColor};
        border: ${border};
        background-color: ${bgColor};
        min-width: ${withPaddings ? '2.5rem' : 'initial'};
        padding: ${withPaddings ? '0.5rem' : 'initial'};

        &:hover {
          background-color: ${bgColor};
        }
      }
    `
    : css`
      & input {
        background-image: none;
        border-color: ${baseTokens.gray300};
        border: ${border};
        background-color: ${bgColor};

        &:focus {
          border-color: ${borderColor};
          border: ${border};
          box-shadow: initial;
        }
      }

      & button {
        border-color: ${baseTokens.gray300};
        border: ${border};
        background-color: ${bgColor};
        min-width: ${withPaddings ? '2.5rem' : 'initial'};
        padding: ${withPaddings ? '0.5rem' : 'initial'};

        &:hover {
          background-color: ${bgColor};
        }
      }
    `;
};

const StyledResolutionSelect = styled(SelectOld)<IssueResolutionSelectProps>`
  min-width: 9.375rem;

  ${getBackgroundStyles}
`;

export { StyledResolutionSelect };
