import { useApp } from '@shared/hooks';
import { Route } from 'react-router-dom';
import { allRoutesEnum } from '@shared/duck';
import BillingPlanPage from './billing-plan';
import { FullScreenPage } from './full-screen-page';
import { SignUpPage } from 'pages/billing-router/sign-up';

const useBillingRoutes = () => {
  const app = useApp();
  const isBackButtonShown = app.checkHasFullSubscription();
  const isEnterpriseSubscription = app.checkHasEnterpriseSubscription();

  return (
    <>
      {!isEnterpriseSubscription && (
        <Route
          path={allRoutesEnum.SelectBillingPlan}
          element={
            <FullScreenPage name='Choose a Plan' backButton={isBackButtonShown} stretchContent>
              <BillingPlanPage />
            </FullScreenPage>
          }
        />
      )}
      <Route path={allRoutesEnum.GetStarted} element={<SignUpPage />} />
    </>
  );
};

export default useBillingRoutes;
