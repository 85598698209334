import { Dialog, DialogContent, Typography, FormControlLabel, Checkbox, DialogActions, Button, Link } from '@mui/material';
import { UIDialogTitle } from '@shared/components';
import React from 'react';

export interface ConfirmationModalProps {
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  openCliFlow: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onClose,
  onConfirm,
  onCancel,
  openCliFlow,
}) => {
  const [isShown, setIsShown] = React.useState<boolean>(true);
  const [checked, setChecked] = React.useState(false);

  const handleClose = (result: boolean) => {
    setIsShown(false);
    if (result) {
      onConfirm();
    }
    else {
      onCancel?.();
    }
  };
  const hideModal = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      open={isShown}
      onClose={() => handleClose(false)}
      onTransitionExited={hideModal}
    >
      <UIDialogTitle
        title={'URL is not publicly accessible'}
        onCloseBtnAction={() => handleClose(false)}
      />
      <DialogContent dividers sx={{ borderBottom: 'none', gap: '0.5rem' }}>
        <Typography
          variant='subtitle2'
          fontWeight={400}
          whiteSpace='pre-line'
          sx={{ wordBreak: 'break-word', mb: '0.5rem' }}
        >
          {'Scan will fail for URL accessible only from local network.\nUse the '}
          <Link
            onClick={() => { openCliFlow(); hideModal(); }}
            underline='hover'
            sx={{
              fontWeight: '600',
            }}
          >
            CLI helper
          </Link>
          {' to scan a private URL or:'}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
              disableRipple
              sx={{
                padding: '0 0.25rem 0 0',
              }}
            />
          }
          label={'Confirm the URL is publicly available'}
          componentsProps={{
            typography: {
              variant: 'subtitle2',
              fontWeight: 400,
            },
          }}
          sx={{ margin: 'initial' }}
        />
      </DialogContent>
      <DialogActions sx={{ pt: '0', gap: '0.5rem' }}>
        <Button
          autoFocus
          onClick={() => handleClose(false)}
          size='small'
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => handleClose(true)}
          disabled={!checked}
          size='small'
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};