import React from 'react';
import { MuiContentLoader, MuiTextInput } from '@shared/components';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useApp, useMedia } from '@shared/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AllRoutesEnum from '@shared/duck/enums/all-routes-enum';
import { globalConstants, globalUtils } from '@shared/duck';
import { ArrowLeft, WarningCircle } from '@phosphor-icons/react';
import { muiPalette } from '@shared/general-mui-theme';
import { Greetings } from '../../billing-router/sign-up/components';
import { NightvisionSignupBg } from '@assets';
import { appUtils } from '@app/components/context/provider/duck';

const LOGO_LINK = 'https://avatars.githubusercontent.com/u/123520317?s=200&v=4';

const SSOPage: React.FC = () => {
  const navigate = useNavigate();
  const app = useApp();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');

  const [searchParams,] = useSearchParams();
  const cliStateStr = searchParams.get(globalConstants.CLI_STATE_PARAM) ?? undefined;
  let cliState: string | undefined = undefined;
  try {
    if (cliStateStr) {
      cliState = atob(cliStateStr);
    }
  }
  catch {
    console.warn('Invalid CLI state');
  }

  React.useEffect(() => {
    // Do not redirect to home when SSO for CLI
    if (!cliState && app.users.me) {
      navigate(AllRoutesEnum.Home);
    }
  }, [cliState, app.users.me]);

  // Workaround for React StrictMode to avoid double rendering
  const [isAfterFirstRender, setIsAfterFirstRender] = React.useState(false);
  React.useEffect(() => setIsAfterFirstRender(true), []);

  React.useEffect(() => {
    if (isAfterFirstRender) {
      void app.authenticateSSO(navigate).then(({ invalidCode, notInvited, userBlocked }) => {
        setIsLoading(false);
        if (invalidCode) {
          setError('Session is invalid or expired. Please try again.');
        }
        else if (notInvited || userBlocked) {
          setError('You are not allowed to access the service. Please contact your administrator.');
        }
      });
    }
  }, [isAfterFirstRender]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (email) {
      const { notFound, notInvited } = await app.startSSO(email, cliState);
      if (notInvited) {
        setError('You don\'t have access to the service. Please contact your administrator.');
      }
      else if (notFound) {
        setError('Organization not found. Please contact your administrator.');
      }
    }
    else {
      setError('Enter Email');
    }
    setIsSubmitting(false);
  };

  const onBackClick = () => {
    // Pass cliState to Social Login
    appUtils.socialLoginRedirect(app.users, app.authApi, cliState);
  };

  const { isLargeScreen, isBetween, isSxScreen } = useMedia();
  const isSmallScreen = isBetween('sm', 'lg');

  return (
    <MuiContentLoader isLoading={isLoading} variant='dots'>
      <Grid
        height='100%'
        container
      >
        <Grid item lg={6.5} xs={12}
          display='flex' justifyContent='center' alignItems='center'
          sx={{
            background: isSxScreen ? '#FFFFFF' : muiPalette.grey?.[100],
            p: '2.5rem',

            ...(isSmallScreen && {
              backgroundImage: `url(${NightvisionSignupBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }),
          }}
        >
          <Stack
            alignItems='center'
            gap='1.5rem'
            sx={{
              background: 'white',
              borderRadius: '0.375rem',
              p: '2.5rem',
              width: '100%',
              maxWidth: '20rem',
              boxShadow: !isSxScreen ? '0 12px 40px rgba(0, 0, 0, 0.12)' : '',
            }}
          >
            {globalUtils.createCustomIcon(LOGO_LINK, { style: { height: '3.25rem' } })}

            <Typography variant='subtitle1' fontSize='1.5rem'>
              Sign in to continue
            </Typography>

            <Stack>
              <Typography variant='subtitle2' fontWeight='400' color={muiPalette.grey?.[500]}>
                {'Single sign-on allows you to log into NightVision using your organization\'s identify provider.'}
              </Typography>

              <Typography variant='subtitle2' fontWeight='400' color={muiPalette.grey?.[500]}>
                {'If you are unsure whether your organization is using single sign-on, reach out to your administrator.'}
              </Typography>
            </Stack>

            <Stack width='100%'>
              <MuiTextInput
                value={email}
                placeholder='Email address'
                onChange={e => {
                  setEmail(e.target.value);
                  setError('');
                }}
                error={!!error}
              />

              {error && (
                <Stack direction='row' alignItems='center'>
                  <Stack flexShrink='0'>
                    <WarningCircle size={17} weight='bold' color={muiPalette.red?.[600]} />
                  </Stack>
                  <Typography variant='subtitle2' color={muiPalette.red?.[600]} fontWeight='400'>{error}</Typography>
                </Stack>
              )}
            </Stack>

            <Button
              variant='contained'
              fullWidth
              onClick={onSubmit}
              disabled={isSubmitting}
              endIcon={isSubmitting && (
                <CircularProgress color='inherit' size={20} />
              )}
            >
              <Typography fontSize='0.9375rem' fontWeight='500' lineHeight='1.85rem' noWrap>
                Continue with Single Sign-On
              </Typography>
            </Button>

            <Button variant='text' onClick={onBackClick}>
              <Stack direction='row' alignItems='center'>
                <ArrowLeft size={16} color='black' />
                <Typography variant='subtitle2'>
                  Back to Sign In
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Grid>

        {isLargeScreen && (
          <Greetings />
        )}
      </Grid>
    </MuiContentLoader>
  );
};

export default SSOPage;
