import { globalConstants, globalEnums, globalQueries, globalUtils } from '@shared/duck';
import { NameCellRender, UrlTextInput } from './components';
import React from 'react';
import { FormikControl, FormikControlMui, UIFileInput, DocsLink, TargetExclusions } from '@shared/components';
import SwaggerFileInput from './components/swagger-url/swagger-url';
import {
  FormControlLabel, RadioGroup, Stack, Typography, Radio, Alert,
  Box,
} from '@mui/material';
import { OpenApiTarget } from '@api-client';
import { useFormikContext } from 'formik';
import { ApiTargetsContentValues } from './duck';
import { FormWarningMessage } from '@shared/components/formik/formik-control/components';
import { ErrorContext } from '@shared/duck/contexts';
import { errorResponseHandler } from 'views/utils/errorHandlers';

interface ApiTargetsContentProps {
  openApiTarget?: OpenApiTarget;
  project?: string;
  setFileEdited: (nextState: boolean) => void;
}

const ApiTargetsContent: React.FC<ApiTargetsContentProps> = ({
  openApiTarget,
  project,
  setFileEdited,
}: ApiTargetsContentProps) => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<ApiTargetsContentValues>();
  const { setError } = React.useContext(ErrorContext);

  const shouldGenerateDownloadUrl = openApiTarget?.spec_status && !globalConstants.NO_SPEC_STATUSES.includes(openApiTarget.spec_status);

  const { url: specUrl, isUrlLoading } = globalQueries.useGetOpenApiSpecUrl({
    id: openApiTarget?.id || ''
  }, {
    enabled: !!openApiTarget?.id && shouldGenerateDownloadUrl && !touched.newTarget?.file,
    onError: err => errorResponseHandler(err, 'target', setError),
  });

  const supportedTypes = [
    globalEnums.EFileExtension.YAML,
    globalEnums.EFileExtension.JSON,
  ];

  const fileValidation = async (file: File): Promise<{ success: boolean; error?: string; }> => {
    const res = await globalUtils.validateOpenApiSpec({ api: file, isFile: true });
    return {
      success: !!res.isValid,
      error: res.isValid ? '' : res.errorText || globalConstants.INVALID_YAML_DEFINITION
    };
  };

  return (
    <>
      <Stack flexDirection='row' gap='1.5rem' mt='2rem'>
        <FormikControl name='newTarget.name' label='Target Name' style={{ width: '100%' }} showError>
          <NameCellRender placeholder='example-api' name='newTarget.name' fieldName={'newTarget'} project={project} isInEdit={!!openApiTarget} />
        </FormikControl>
        <FormikControl name='newTarget.location' label='Base URL' style={{ width: '100%' }}>
          <UrlTextInput placeholder='api.nightvision.net' />
        </FormikControl>
      </Stack>

      <Stack gap='1rem'>
        <Typography
          variant='subtitle1'
          lineHeight='2rem' fontSize='1.25rem'
          fontWeight={600}
        >
          Add API Specs
        </Typography>
        <RadioGroup
          row
          value={values.isFileUrl ? 'url' : 'open_api'}
          onChange={e => {
            setFieldValue('isFileUrl', e.target.value === 'url');
          }}
          sx={{
            '& .MuiTypography-root': {
              lineHeight: '1.25rem',
              color: '#111B2B',
              fontSize: '0.875rem',
              fontWeight: 500,
            }
          }
          }
        >
          <FormControlLabel value='url' control={<Radio size='small' />} label='OpenAPI URL' />
          <FormControlLabel value='open_api' control={<Radio size='small' />} label='Upload File' />
        </RadioGroup>

        <Stack gap='0'>
          {values.isFileUrl ? (
            <FormikControlMui name='newTarget.fileLocation' label='OpenAPI URL' mb='1.5rem'>
              <SwaggerFileInput
                placeholder='Enter OpenAPI URL here...'
                error={(touched.newTarget?.fileLocation && !!errors.newTarget?.fileLocation)
                || (openApiTarget?.swaggerfile_url && openApiTarget?.spec_status && globalConstants.ERROR_SPEC_STATUSES.includes(openApiTarget.spec_status) )}
              />
            </FormikControlMui>
          ) : (
            <FormikControlMui label='Upload a Swagger file' name='newTarget.file' mb='1.5rem'>
              <UIFileInput
                initFile={values.newTarget.file}
                onAfterChange={async file => {
                  setFieldTouched('newTarget.file', true);
                  setFieldValue('newTarget.file', file);
                  setFileEdited(true);
                }}
                accept={supportedTypes}
                fileValidation={fileValidation}
                helpText='.YML, .YAML, or .JSON'
                downloadUrl={touched.newTarget?.file ? undefined : specUrl?.url}
                isUrlLoading={shouldGenerateDownloadUrl && isUrlLoading}
                error={(touched.newTarget?.file && !!errors.newTarget?.file)
                || (!!openApiTarget?.swaggerfile_name && openApiTarget?.spec_status && globalConstants.ERROR_SPEC_STATUSES.includes(openApiTarget.spec_status) )}
              />
            </FormikControlMui>
          )}
          {openApiTarget?.spec_status && globalConstants.ERROR_SPEC_STATUSES.includes(openApiTarget.spec_status) &&
            ((!(touched.newTarget?.file && !!errors.newTarget?.file) && !!openApiTarget?.swaggerfile_name && !values.isFileUrl) ||
            (!(touched.newTarget?.fileLocation && !!errors.newTarget?.fileLocation) && !!openApiTarget?.swaggerfile_url && values.isFileUrl)) && (
            <Box sx={{ mt: '-1.5rem', mb: '1.5rem' }}>
              <FormWarningMessage error={globalUtils.specStatusToTextMessage(openApiTarget.spec_status)}/>
            </Box>
          )}
        </Stack>
      </Stack>

      <Stack gap='1.5rem'>
        <Alert severity='info' >
          {'You can create a target with '}
          <Typography variant='subtitle2' display='inline-block'>
            {'higher end-point coverage'}
          </Typography>
          {' using our API Discovery first, before scanning.'}
          <br />
          <DocsLink
            to={globalConstants.DOCS_PATHS.API_EXTRACTION_OVERVIEW}
            style={{ marginTop: '0.5rem', display: 'block', width: 'fit-content' }}
          >
            {'API Discovery tutorial'}
          </DocsLink>
        </Alert>

        <TargetExclusions isApiTarget isCreateCommand={!openApiTarget}/>
      </Stack>
    </>
  );
};

export default ApiTargetsContent;
