import React from 'react';
import { useApp } from '@shared/hooks';
import { useSearchParams } from 'react-router-dom';
import { MuiContentLoader } from '@shared/components';

const DocumentationRedirect: React.FC = () => {
  const app = useApp();

  const [searchParams] = useSearchParams();

  React.useLayoutEffect(() => {
    app.redirectToReadme(searchParams);
  }, [searchParams]);

  return (
    <MuiContentLoader
      isLoading={true}
      variant='dots'
    >
      <></>
    </MuiContentLoader>);
};

export default DocumentationRedirect;
