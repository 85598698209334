import { Typography } from '@mui/material';
import { tableTypes, UIUserAvatar } from '@shared/components';
import { globalModels } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';

export const collaboratorsColumns = (projectOwnerId: string): tableTypes.ColumnProps<globalModels.UserViewModel>[] => [
  {
    title: 'Name',
    columnKey: 'fullName',
    cellInfo: {
      cellTextWeight: 600,
      getIcon(item) {
        return <UIUserAvatar src={item.avatarUrl} fullName={item.fullName} />;
      },
      description(item) {
        return item.username;
      },
    },
    minWidth: '20rem',
  },
  {
    title: 'Role',
    columnKey: 'customField',
    customCellRenderer(value, item) {
      const role = projectOwnerId === item.id ? 'Owner' : 'Collaborator';
      return (
        <Typography
          variant='subtitle2'
          color={muiPalette.grey?.[600]}
          fontWeight='400'
        >
          {role}
        </Typography>
      );
    },
    minWidth: '10rem',
  },
];