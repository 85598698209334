import { Box, CircularProgress, Collapse, Divider, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';
import { FormikControlMui, MuiTextInput } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { harTypes } from '../../duck';
import { X } from '@phosphor-icons/react/dist/ssr';
import { Check } from '@phosphor-icons/react';
import { muiPalette } from '@shared/general-mui-theme';
// import { useDebounce } from '@shared/hooks';
// import { globalQueries } from '@shared/duck';
// import { CanceledError } from 'axios';

export interface AttachedFileProps {
  index: number;
  targetId: string;
}

const CircularProgressWithLabel = ({
  value,
  canceling,
}: {
  value: number;
  canceling: boolean;
})  => {
  return (
    canceling ? (
      <Typography
        variant='default'
        sx={{ color: 'text.secondary' }}
        fontSize='0.6rem'
      >
        Canceling
      </Typography>
    ) : (
      value < 100 ? (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" value={value} size={30}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='default'
              sx={{ color: 'text.secondary' }}
              fontSize='0.6rem'
            >
              {`${Math.round(value)}%`}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Check size={22} color={muiPalette.green?.[600]}/>
      ))
  );
};

enum DescriptionLabel {
  Add = 'Add description',
  Hide = 'Hide description',
  Show = 'Show description',
}

export const AttachedFile: React.FC<AttachedFileProps> = ({
  index,
  // targetId,
}) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<harTypes.HarUploadModalValues>();
  const [canceling, showCancelLoading] = React.useState(false);
  const [isDescriptionOpen, setIsOpenDescription] = React.useState(false);
  const [descriptionLabel, setDescriptionLabel] = React.useState(DescriptionLabel.Add);

  const toggleDescription = () => {
    setIsOpenDescription(old => !old);
  };

  const onDelete = () => {
    if (isSubmitting) {
      const controller = values.values[index].abortController;
      setFieldValue(`values[${index}].abortController`, controller?.abort());
      if (values.values[index].status !== harTypes.HAR_UPLOAD_STATUS.SUBMITTING)
        setFieldValue(`values[${index}].deleted`, true);
      else {
        showCancelLoading(true);
      }
    }
    else {
      const newFiles = [...values.values];
      newFiles.splice(index, 1);
      setFieldValue('values', newFiles);
    }
  };

  React.useEffect(() => {
    if (values.values[index].status !== harTypes.HAR_UPLOAD_STATUS.UPLOADED && !!values.values[index].status) {
      setFieldValue(`values[${index}].status`, '');
    }
  }, [values.values[index].fileName]);

  React.useEffect(() => {
    if (isDescriptionOpen) {
      setDescriptionLabel(DescriptionLabel.Hide);
    }
    else if (values.values[index].description) {
      setDescriptionLabel(DescriptionLabel.Show);
    }
    else {
      setDescriptionLabel(DescriptionLabel.Add);
    }
  }, [values.values[index].description, isDescriptionOpen]);

  // const { debouncedValue = '', onChange: onDebounceChange } = useDebounce<string>(values.values[index].fileName);
  // const [abortController, setAbortController] = React.useState(new AbortController());

  // const { getHarFileByName, isLoading } = globalQueries.useGetHarFileByName(abortController.signal);
  // const testName = async () => {
  //   try {
  //     await getHarFileByName({ name: debouncedValue, id: targetId });
  //   }
  //   catch (e) {
  //     if (e instanceof CanceledError) {
  //       // TODO
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   testName();
  // }, [debouncedValue]);
  const invaludStatuses = [harTypes.HAR_UPLOAD_STATUS.ERROR, harTypes.HAR_UPLOAD_STATUS.UPLOAD_ERROR];
  const uploaded = values.values[index].status === harTypes.HAR_UPLOAD_STATUS.UPLOADED;

  return (
    <>
      {!!index && <Divider sx={{ borderColor: muiPalette.grey?.[300], my: '0.5rem' }} />}
      <Stack direction='row' alignItems='flex-start' >
        <Box flexGrow='1' display='flex' flexDirection='column'>
          <FormikControlMui name={`values[${index}].fileName`} showError>
            <MuiTextInput
              onChange={(e) => {
              // abortController.abort();
              // setAbortController(new AbortController());
              // onDebounceChange(e.target.value);
                setFieldValue(`values[${index}].fileName`, e.target.value);

              }}
              disabled={uploaded || isSubmitting}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {
                      // isLoading ? <CircularProgress size={20}/>
                      //   :
                      values.values[index].status === harTypes.HAR_UPLOAD_STATUS.UPLOADED
                        ? <Check size={22} color={muiPalette.green?.[600]}/>
                        : values.values[index].status && invaludStatuses.includes(values.values[index].status)
                          ? <X size={22} color={muiPalette.red?.[600]} />
                          : isSubmitting && values.values[index].status !== harTypes.HAR_UPLOAD_STATUS.DUPLICATE_NAME
                            ? <CircularProgressWithLabel value={values.values[index].progress || 0} canceling={canceling} />
                            : <></>}
                  </InputAdornment>
                )
              }}
            />
          </FormikControlMui>
          <Link onClick={toggleDescription} color='rgb(141,147,157) !important' pt='0.5rem'>{descriptionLabel}</Link>
          <Collapse in={isDescriptionOpen}>
            <FormikControlMui name={`values[${index}].description`} showError sx={{ mt: '0.5rem' }}>
              <MuiTextInput
                placeholder='Enter description'
                disabled={uploaded || isSubmitting}
              />
            </FormikControlMui>
          </Collapse>
        </Box>
        {!uploaded && (
          canceling ? (
            <CircularProgress size={18} sx={{ alignSelf: 'center' }} />
          ) : (
            <IconButton onClick={onDelete} sx={{ mt: '0.3rem' }}>
              <X size='18' />
            </IconButton>
          )
        )}
      </Stack>
    </>
  );
};