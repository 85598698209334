import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';
import {
  Checkbox,
  FilledTextFieldProps,
  FormControlLabel,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { globalConstants } from '@shared/duck';

const CreateTargetCheckbox: React.FC<Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, 'variant'>> = ({ ...props }) => {
  const { values, setFieldValue } = useFormikContext<formDataTypes.FormDataFormValues>();
  const [checked, setChecked] = React.useState(!!values.createNewTargetCheckbox);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setFieldValue('createNewTargetCheckbox', event.target.checked);
    props.onChange?.(event);
  };

  React.useEffect(() => {
    setChecked(!!values.createNewTargetCheckbox);
  }, [values.createNewTargetCheckbox]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={props.name}
          checked={checked}
          onChange={handleChange}
          disableRipple
          sx={{
            padding: '0 0.25rem 0 0',
            color: props.error ? globalConstants.INPUT_BORDER.ERROR : '',
          }}
        />
      }
      label={'Create new Target'}
      componentsProps={{
        typography: {
          variant: 'subtitle2',
          fontWeight: 400,
          color: muiPalette.grey?.['600'],
        },
      }}
      sx={{ margin: 'initial' }}
    />
  );
};

export default CreateTargetCheckbox;