import { LinearProgress, Stack } from '@mui/material';
import { NoContentPlaceholder } from '@shared/components';
import { globalModels } from '@shared/duck';
import React from 'react';
import { LoadMoreButton, ParameterTableWrapper, PathRow } from './components';
import { muiPalette } from '@shared/general-mui-theme';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import { Issue } from '@api-client';
import { AttentionGray } from '@assets';

export interface PathsTableProps {
  vulnerablePaths: globalModels.VulnerablePathsViewModel[];
  isVulnerablePathsLoading: boolean;
  isVulnerablePathsFetching: boolean;
  vulnerablePathsListHasNextPage?: boolean;
  fetchPathsNextPage?: () => void;
  pathsSelectionApi: ItemSelectionApi<globalModels.VulnerablePathsViewModel>;
  expandedPaths: Set<string>,
  togglePath: (pathId: string) => void;
  firstScrollToIssue: boolean;

  scanId: string;
  kindId: number;
  setCurrentIssueId: (issueId?: string) => void;
  currentIssue?: Issue;
  currentIssueId?: string;
  setFirstScrollToIssue: (value: boolean) => void;
}

export const PathsTable: React.FC<PathsTableProps> = ({
  vulnerablePaths,
  isVulnerablePathsLoading,
  isVulnerablePathsFetching,
  vulnerablePathsListHasNextPage = false,
  fetchPathsNextPage,
  pathsSelectionApi,
  expandedPaths,
  togglePath,
  firstScrollToIssue,
  ...props
}) => {
  const mainHeaderRef = React.useRef<HTMLDivElement>(null);
  const pathHeaderRefs = React.useRef([]);
  pathHeaderRefs.current = vulnerablePaths.map((_, i) => pathHeaderRefs.current[i] ?? React.createRef<HTMLDivElement>());

  const [mainHeaderHeight, setMainHeaderHeight] = React.useState(0);
  const [pathHeaderHeights, setPathHeaderHeights] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (!mainHeaderRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setMainHeaderHeight(mainHeaderRef.current?.getBoundingClientRect().height || 0);

      const newHeights: number[] = [];
      pathHeaderRefs.current.forEach((el: React.RefObject<HTMLDivElement>, i) => {
        newHeights[i] = el.current?.getBoundingClientRect().height || 0;
      });
      setPathHeaderHeights(newHeights);
    });
    resizeObserver.observe(mainHeaderRef.current);

    return () => resizeObserver.disconnect();
  }, [mainHeaderRef, firstScrollToIssue]);

  const onTogglePath = (e: any, pathId: string, currentRef: React.RefObject<HTMLDivElement>) => {
    e.stopPropagation();
    const stickyRect = currentRef.current?.getBoundingClientRect().top || 0;
    togglePath(pathId);

    // Waits for the browser to complete all pending layout and style calculations and
    // schedule the code to run just before the next repaint
    requestAnimationFrame(() => {
      const scrollDiff = (currentRef.current?.getBoundingClientRect().top || 0) - stickyRect;
      const scrollYAfter = scrollRef.current?.scrollTop || 0;
      scrollRef.current?.scrollTo(0, scrollYAfter + scrollDiff);
    });
  };

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [initialParamSettled, setInitialParamSettled] = React.useState(false);

  return (
    <Stack sx={{ wordBreak: 'break-word', overflow: 'auto', gap: 0, position: 'relative', height: '100%' }} ref={scrollRef}>
      {/* Main header */}
      <Stack
        gap='0'
        ref={mainHeaderRef}
        sx={{
          position: 'sticky', top: '0', background: 'white', zIndex: '10',
          borderBottom: `1px solid ${muiPalette.grey?.[300]}`
        }}
      >
        <PathRow
          header
          pathsSelectionApi={pathsSelectionApi}
          checkboxDisabled={!vulnerablePaths.length}
          scanId={props.scanId}
          kindId={props.kindId}
        />
        {(isVulnerablePathsLoading || isVulnerablePathsFetching) && <LinearProgress />}
      </Stack>

      {/* Main Content */}
      {!isVulnerablePathsLoading && (
        vulnerablePaths.length ? (
          <Stack gap='0'>
            {vulnerablePaths.map((path, index) => {
              const currentRowRef: React.RefObject<HTMLDivElement> = pathHeaderRefs.current[index];
              const expanded = expandedPaths.has(path.id);

              return (
                <Stack gap='0' key={`${path.urlPath}-${index}`} sx={{
                  borderBottom: `1px solid ${muiPalette.grey?.[300]}`
                }}>
                  <PathRow
                    pathsSelectionApi={pathsSelectionApi}
                    expanded={expanded}
                    path={path}
                    onTogglePath={onTogglePath}
                    rowRef={currentRowRef}
                    onRowClick={e => onTogglePath(e, path.id, currentRowRef)}
                    mainHeaderHeight={mainHeaderHeight}
                    scanId={props.scanId}
                    kindId={props.kindId}
                  />

                  {expanded && <ParameterTableWrapper
                    urlPath={path.urlPath}
                    httpMethod={path.httpMethod}
                    parentHeaderHeight={mainHeaderHeight + pathHeaderHeights[index]}
                    firstScrollToIssue={firstScrollToIssue}
                    scrollRef={scrollRef}
                    initialParamSettled={initialParamSettled}
                    setInitialParamSettled={setInitialParamSettled}
                    {...props}
                  />}
                </Stack>
              );
            })}
          </Stack>
        ) : (
          <NoContentPlaceholder message='No Issues found.' iconSrc={AttentionGray} />
        )
      )}

      {/* Load More */}
      {vulnerablePathsListHasNextPage && (
        <LoadMoreButton fetchPathsNextPage={fetchPathsNextPage} />
      )}
    </Stack>
  );
};