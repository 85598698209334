import { SpecStatusEnum } from '@api-client';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import { globalConstants, globalUtils } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

export interface ScanStatusProgressProps {
  showProgress?: boolean;
  specStatus?: SpecStatusEnum;
  creatingTarget?: boolean;
  updatingTarget?: boolean;
}

export const ScanStatusProgress: React.FC<ScanStatusProgressProps> = ({
  showProgress = true,
  specStatus,
  creatingTarget = false,
  updatingTarget = false,
}) => {
  return (
    showProgress ? (
      <Stack direction='row' alignItems='center'>
        {specStatus && globalConstants.ERROR_SPEC_STATUSES.includes(specStatus)
          ? <WarningCircle size={16} weight="bold" color={muiPalette.red?.[600]}/>
          : <CircularProgress size={16} color='primary'/>
        }
        <Typography variant='default' color={muiPalette.grey?.[500]}>
          {creatingTarget
            ? 'Creating a target'
            : updatingTarget
              ? 'Updating a target'
              : specStatus && globalConstants.COMPLETED_SPEC_STATUSES.includes(specStatus)
                ? 'Starting a scan'
                : globalUtils.specStatusToTextMessage(specStatus)
          }
        </Typography>
      </Stack>
    ) : <></>
  );
};
