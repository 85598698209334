import { Autocomplete, AutocompleteProps, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withSafeProps } from 'views/utils/common';

const popupIndicatorStyles = ({ hidePopupIndicator }: { hidePopupIndicator?: boolean }) =>
  hidePopupIndicator
    ? css`
              .MuiAutocomplete-popupIndicator {
                  display: none;
              }
    ` : css``;

export const MuiAutocompleteStyled = styled(
  withSafeProps<{
    hidePopupIndicator?: boolean
  } & AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>>(
    Autocomplete,
    ['hidePopupIndicator'],
  ),
)`
    ${popupIndicatorStyles}
`;