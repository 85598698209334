import { globalEnums } from '@shared/duck';
import { Box, css, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledSnippet = styled(Box) <{ inline?: globalEnums.EmotionBoolean }>`
  font-family: SF Mono, monospace;
  padding: 0.125rem 0.25rem;
  display: flex;
  align-items: center;
  color: ${muiPalette.blue?.[700]};
  background: ${muiPalette.grey?.[100]};
  width: fit-content;
  word-break: break-all;
  border-radius: 0.375rem;
  border: 1px solid ${muiPalette.grey?.[200]};

  ${({ inline }) =>
    inline
      ? css`
          & > div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        `
      : ''}

  & button:last-of-type {
    padding: 0;
    min-height: 0;
  }

  line-height: 1rem;
  font-size: 0.75rem;
`;

export { StyledSnippet };
