import React from 'react';
import { Chip, Collapse, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';
import { CaretRight } from '@phosphor-icons/react/dist/ssr';

interface ExclusionProps {
  title: string;
  exclusions?: string[];
  length: number;
}

export const Exclusion: React.FC<ExclusionProps> = ({
  title,
  exclusions,
  length,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      <Grid item xs={5}
        onClick={() => setExpanded(old => !old)}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography variant='subtitle2' fontWeight={600}>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={7}
        onClick={() => setExpanded(old => !old)}
        sx={{
          cursor: 'pointer',
        }}
      >
        {length ? (
          <IconButton
            size='small'
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
              p: 0,
              gap: '0.5rem',
            }}
          >
            {expanded ? <CaretDown /> : <CaretRight />}
            <Typography variant='subtitle2' color='textSecondary'>
              {length} items
            </Typography>
          </IconButton>
        ) : (
          <Typography variant='subtitle2' color='textSecondary'>
            N/A
          </Typography>
        )}
      </Grid>

      {!!length && (
        <Grid item xs={12} pt='0 !important' >
          <Collapse in={expanded}>
            <Stack direction='row' flexWrap='wrap' pt='0.5rem'>
              {exclusions?.map((label, index) => {
                return (
                  <Tooltip key={`${label}-${index}`} arrow title={label} enterDelay={500} enterNextDelay={500} placement='top'>
                    <Chip
                      label={label}
                    />
                  </Tooltip>
                );
              })}
            </Stack>
          </Collapse>
        </Grid>
      )}
    </>
  );
};