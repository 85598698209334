import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface ScansTableApiResult {
  scans: globalModels.ScansViewModelNew[],
  totalItemsCount: number,
  isScansListLoading: boolean,
  scansHasNextPage: boolean,
  fetchNextScansPage: () => void,
  onScansRowClick?: (item: globalModels.ScansViewModelNew) => void,
}

export const useScansTableApi = ({
  target,
  page,
  pageSize,
}:
{
  target: string;
  page: number;
  pageSize?: number;
}): ScansTableApiResult => {
  const REFETCH_INTERVAL = 20 * 1000;

  const {
    scans: dtoScans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage = false,
    fetchNextScansPage,
  } = globalQueries.useGetScansList(
    {
      target: [target],
      page: page + 1,
      pageSize: pageSize,
    },
    { refetchInterval: REFETCH_INTERVAL, enabled: !!target },
  );

  const scans = React.useMemo(
    () =>
      dtoScans?.map(
        (item) => globalUtils.mapScansDtoToViewModelNew(item)) || [],
    [dtoScans],
  );

  return {
    scans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage,
    fetchNextScansPage,
  };
};