import { Color } from '@mui/material';
import { blue } from '@mui/material/colors';
import { PaletteColorOptions } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface CustomPalette {
    transparent?: PaletteColorOptions;
  }

  interface Palette extends CustomPalette {
  }

  interface PaletteOptions extends CustomPalette {
    blue: Partial<Color> | undefined;
    green: Partial<Color> | undefined;
    orange: Partial<Color> | undefined;
    purple: Partial<Color> | undefined;
    red: Partial<Color> | undefined;
    yellow: Partial<Color> | undefined;
  }
}

export const muiPalette: PaletteOptions = {
  primary: {
    main: '#036FE3',
    dark: blue[800],
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FFFFFF',
    contrastText: '#111B2B',
  },
  warning: {
    main: '#FDE5C0',
    contrastText: '#B12D00',
  },
  transparent: {
    contrastText: '#FFFFFF',
    main: '#111B2B',
  },
  background: {
    default: '#FFFFFF',
  },
  error: {
    main: '#BD002A'
  },
  text: {
    primary: '#1B273A', // grey 800
    secondary: '#5A657C', // grey 600
  },
  divider: '#CFD9E0',
  blue: {
    100: '#E8F5FF',
    200: '#CEECFF',
    300: '#98CBFF',
    400: '#40A0FF',
    500: '#036FE3',
    600: '#0059C8',
    700: '#0041AB',
    800: '#003298',
    900: '#002A8E',
  },
  grey: {
    100: '#F7F9FA',
    200: '#E7EBEE',
    300: '#CFD9E0',
    400: '#AEC1CC',
    500: '#67728A',
    600: '#5A657C',
    700: '#414D63',
    800: '#1B273A',
    900: '#111B2B',
  },
  green: {
    200: '#CDF3C6',
    400: '#5DB057',
    600: '#006D23',
  },
  orange: {
    100: '#FFF2E4',
    200: '#FDE5C0',
    300: '#FDB882',
    400: '#F07F23',
    500: '#CC4500',
    600: '#B12D00',
  },
  purple: {
    200: '#EDE3FF',
    600: '#6C3ECF',
  },
  red: {
    200: '#FFE0E0',
    600: '#BD002A',
  },
  yellow: {
    200: '#FFF6CC',
    800: '#956300',
  }
};