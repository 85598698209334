import {
  Checkbox,
  FilledTextFieldProps,
  FormControlLabel,
  OutlinedTextFieldProps,
  Stack,
  StandardTextFieldProps,
  Tooltip,
} from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import { muiPalette } from '@shared/general-mui-theme';
import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';

const BypassUrlValidationCheckbox: React.FC<Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, 'variant'>> = ({ ...props }) => {
  const { setFieldValue } = useFormikContext<formDataTypes.FormDataFormValues>();
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setFieldValue('bypassUrlValidationCheckbox', checked);
  }, [checked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.onChange?.(event);
  };

  return (
    <Stack direction='row' alignItems='center' gap={0.5}>
      <FormControlLabel
        control={
          <Checkbox
            name={props.name}
            checked={checked} onChange={handleChange}
            disableRipple
            sx={{
              padding: '0 0.25rem 0 0',
            }}
          />
        }
        label={'Confirm the URL is publicly available'}
        componentsProps={{
          typography: {
            variant: 'subtitle2',
            fontWeight: 400,
            color: muiPalette.grey?.['600'],
          },
        }}
        sx={{ margin: 'initial' }}
      />

      <Tooltip
        arrow
        disableInteractive
        placement='right'
        title={'Use this checkbox to bypass the URL\'s public availability check'}
      >
        <WarningCircle size={16} />
      </Tooltip>
    </Stack>
  );
};

export default BypassUrlValidationCheckbox;