import {
  TableRow as BaseTableRow,
  TableCell as BaseTableCell,
  TableCellProps as BaseTableCellProps,
  BoxProps,
} from '@contentful/f36-components';
import { globalEnums } from '@shared/duck';
import { withSafeProps } from 'views/utils/common';
import baseTokens from '@contentful/f36-tokens';
import { TableColorTheme } from './Table.interfaces';
import { BreakpointX } from 'views/utils/design';
import { Box, css, styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledSpinnerRow = styled(BaseTableRow)`
  width: 100%;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  border-radius: 1.25rem;
  padding: 0;
  border-bottom: none;
`;

const StyledSpinner = styled('td')`
  height: 0.1875rem;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 0.1875rem;
    width: 25%;
    background-color: ${baseTokens.blue600};
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    border-radius: 1.25rem;
  }

  @keyframes lineAnim {
    0% {
      left: 0;
      width: 0;
    }
    25% {
      left: 0;
      width: 40%;
    }

    50% {
      left: 30%;
      width: 40%;
    }

    75% {
      left: 60%;
      width: 40%;
    }

    100% {
      left: 100%;
      width: 0;
    }
  }
`;

const TableSpinner = ({ colSpan }: { colSpan: number }) => (
  <StyledSpinnerRow>
    <StyledSpinner colSpan={colSpan} />
  </StyledSpinnerRow>
);

interface TableCellProps extends BaseTableCellProps {
  small?: globalEnums.EmotionBoolean;
  width?: string;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
}

const getTableColorThemeStyles = ({ colorTheme }: { colorTheme?: TableColorTheme }) => {
  switch (colorTheme) {
    case 'dark':
      return css`
        & > div table {
          background-color: ${baseTokens.gray100};

          th {
            background-color: ${baseTokens.gray200};
          }
          td {
            background-color: ${baseTokens.gray100};
          }

          & tr:hover {
            td {
              background-color: ${baseTokens.gray200};
            }
          }
        }
      `;
    case 'light':
      return css`
        & > div table {
          background-color: ${baseTokens.colorWhite};

          th {
            background-color: ${baseTokens.gray100};
          }

          td {
            background-color: ${baseTokens.colorWhite};
          }
        }
      `;
    default:
      return css``;
  }
};

const TableWrapper = styled(
  withSafeProps<
    {
      isEmpty?: globalEnums.EmotionBoolean
      colorTheme?: TableColorTheme
      minWidth?: string
      rowsClickable: globalEnums.EmotionBoolean
    } & BoxProps
  >(Box, ['isEmpty', 'colorTheme', 'minWidth', 'rowsClickable']),
)`
  overflow: auto;

  ${getTableColorThemeStyles}

  & table {
    min-height: ${({ isEmpty }) => (
      isEmpty ? '12.5rem;' : ''
    )};
    height: 100%;
    min-width: ${({ minWidth }) => minWidth};

    & tr:hover {
      td {
        ${({ rowsClickable }) =>
          rowsClickable
            ? css`
                background-color: ${baseTokens.gray100};
                cursor: pointer;
              `
            : css`
                background-color: inherit;
                cursor: default;
              `}
      }
    }
    & tr.selected > td {
      background-color: ${baseTokens.blue100};
    }

    & td {
      word-break: break-word;
    }
  }
`;

const LoadMoreWrapper = styled(withSafeProps<{ colorTheme?: TableColorTheme } & BoxProps>(Box, ['colorTheme']))`
  ${({ colorTheme }) => {
    switch (colorTheme) {
      case 'dark':
        return css`
          background-color: ${baseTokens.gray100};
        `;
      case 'light':
        return css`
          background-color: ${baseTokens.colorWhite};
        `;
      default:
        return css``;
    }
  }};
  padding: 0.75rem;
  margin-top: 1rem;
  border-radius: 0.375rem;
  border: 1px solid ${muiPalette.grey?.[200]};
`;

const TableCell = styled(BaseTableCell) <TableCellProps>`
  vertical-align: middle !important;
  width: ${({ width }) => width};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding: ${({ padding }) => padding};
  ${({ small }) =>
    small &&
    css`
      width: 1.875rem;
      overflow: hidden;
    `}
`;

const ExpandedRow = styled(BaseTableRow)`
  &:hover {
    cursor: default;
  }
`;

const ExpandedRowOld = styled(BaseTableRow)`
  &:hover {
    cursor: default;
  }

  & > td {
    background-color: ${baseTokens.gray100};
    padding: 0;
    border-left: 2px solid ${baseTokens.blue500};
  }
`;


const FiltersPanel = styled(Box)`
  border-radius: 0.375rem;
  border: 1px solid ${muiPalette.grey?.[200]};
  border-bottom: none;
  background-color: #FFFFFF;
  padding: 1rem;

  & .search {
    width: 17.5rem;
  }

  & .filter-button svg {
    fill: none;
  }

  @media (max-width: ${BreakpointX.Mobile.max}px) {
    & .search {
      min-width: 100%;
    }
  }
`;

const StyledGroupRow = styled(BaseTableRow)`
  td {
    color: ${baseTokens.gray500};
    padding: ${baseTokens.spacingXs} ${baseTokens.spacingM};

    &:hover {
      cursor: default;
      background-color: ${baseTokens.colorWhite};
    }
  }
`;

export { TableSpinner, TableWrapper, TableCell, FiltersPanel, ExpandedRow, ExpandedRowOld, LoadMoreWrapper, StyledGroupRow };
