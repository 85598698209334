import { globalEnums, globalQueries } from '@shared/duck';
import React from 'react';
import { InfoCircleIcon, MoreVerticalIcon } from 'views/components/v1/Icons';
import {
  Menu, MenuItem, MenuList, MenuTrigger,
  IconButton, Switch
} from '@contentful/f36-components';
import { Tooltip, Stack } from '@mui/material';
import { Issue, IssueSetResolutionRequest, ResolutionEnum } from '@api-client';
import { IssueResolutionEnum } from 'models/Enums';
interface ActionsInfoMenuProps {
  issue?: Issue;
  isRaw: boolean;
  setIsRaw: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionsInfoMenu: React.FC<ActionsInfoMenuProps> = ({
  issue,
  isRaw,
  setIsRaw,
}) => {
  const { setIssuesResolution } = globalQueries.useSetIssuesResolution();
  const isFP = issue?.resolution === IssueResolutionEnum.falsePositive;
  const request: IssueSetResolutionRequest = {
    scan: issue?.scan_id || '',
    kind: [issue?.kind_id || 0],
    path_method: [{ path: issue?.url_path || '', method: issue?.http_method || '' }],
    parameter_name: [issue?.parameter_name || ''],
    new_resolution: (isFP ? IssueResolutionEnum.open : IssueResolutionEnum.falsePositive) as ResolutionEnum,
  };

  const onMarkAsFP = async () => {
    if (issue) {
      await setIssuesResolution({ issueSetResolutionRequest: request });
    }
  };

  const onUnmarkAsFP = async () => {
    if (issue) {
      await setIssuesResolution({ issueSetResolutionRequest: request });
    }
  };

  const fpLabel = isFP ? 'Unmark as false positive' : 'Mark as false positive';
  const fpAction = isFP ? onUnmarkAsFP : onMarkAsFP;
  return (
    <>
      <Menu closeOnSelect={false}>
        <MenuTrigger>
          <IconButton
            variant={globalEnums.EButtonVariants.SECONDARY}
            icon={<MoreVerticalIcon />} aria-label='actions' />
        </MenuTrigger>
        <MenuList>
          <MenuItem>
            <Switch name='view-har' isChecked={isRaw} onChange={() => setIsRaw(!isRaw)}>
              <Stack direction='row' gap='1rem' alignItems='center'>
                View HAR
                <Tooltip title='HTTP Archive Record (HAR)'>
                  <span>
                    <InfoCircleIcon size='small' variant={globalEnums.EIconVariants.SECONDARY} />
                  </span>
                </Tooltip>
              </Stack>
            </Switch>
          </MenuItem>
          <MenuItem>
            <Stack direction='row' gap='1rem' alignItems='center' onClick={fpAction}>
              {fpLabel}
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ActionsInfoMenu;