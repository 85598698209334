import React from 'react';
import { Stack, FormHelperText as MuiFormHelperText, Box } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';

export interface FormWarningMessageProps {
  error: string;
  additionalError?: React.ReactElement;
}

export const FormWarningMessage: React.FC<FormWarningMessageProps> = ({
  error,
  additionalError,
}) => {
  return (
    <MuiFormHelperText
      error
      component={'span'}
      sx={{
        display: 'flex',
        gap: '0.25rem',
        alignItems: 'center',
        ml: 0,
        mt: '0.5rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      }}
    >
      <Stack flexShrink='0'>
        <WarningCircle size={17} weight="bold" />
      </Stack>
      <Box flexWrap='wrap'>
        {error}
        {additionalError}
      </Box>
    </MuiFormHelperText>
  );
};

export default FormWarningMessage;