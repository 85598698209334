import { css } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

const neutralGray = '#e0e0e0';

const styles = {
  Page: css`
    padding: 2em;
  `,

  HoverPointer: css`
    &:hover {
      cursor: pointer;
    }
  `,

  PageTopBar: css`
    background-color: ${baseTokens.colorWhite};
    padding: 1em 1em;
    padding-right: 2em;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${neutralGray};

    a {
      margin-top: 0.5em !important;

      img {
        height: 1.5em !important;
        min-width: 3em !important;
      }
    }
  `,

  PageHeader: css`
    line-height: 2.5;
    margin-bottom: 1em;
    overflow: hidden;
    align-items: center;

    h1 {
      font-size: 150%;
      line-height: 1.6;
      font-weight: bold;
      margin: 0;
      margin-right: 0.5em;
      flex-shrink: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      img {
        vertical-align: text-bottom;
        margin-right: 0.25em;
      }
    }

    nav {
      margin-left: 1em;
    }
  `,

  Toolbar: css`
    line-height: 2.5;
    padding: 1em;
    border-bottom: 1px solid ${neutralGray};

    nav {
      margin-left: 1em;
    }
  `,

  SelectorBar: css`
    line-height: 2.5;
    padding: 1.5em 0;
  `,

  Tab: css`
    line-height: 3;
    padding: 0 1em;
    color: ${baseTokens.gray600};
    border-bottom: 2px solid ${neutralGray};
    cursor: pointer;
  `,

  ActiveTab: css`
    line-height: 3;
    padding: 0 1em;
    color: ${baseTokens.blue600};
    border-bottom: 2px solid ${baseTokens.blue600};
  `,

  Selector: css`
    line-height: 2.5;
    padding: 0 1em;
    border: 1px solid ${neutralGray};
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 0.35rem;
      border-bottom-left-radius: 0.35rem;
    }

    &:last-child {
      border-top-right-radius: 0.35rem;
      border-bottom-right-radius: 0.35rem;
    }
  `,

  ActiveSelector: css`
    line-height: 2.5;
    padding: 0 1em;
    color: ${baseTokens.blue600};
    border: 1px solid ${baseTokens.blue600};
  `,

  ContentContainer: css`
    background-color: white;
    border: 1px solid ${neutralGray};
    border-radius: 0.4em;
    overflow: auto;
    flex-shrink: 1;
  `,

  List: css`
    background-color: ${baseTokens.colorWhite};
    border-radius: 0.4em;
    border: 1px solid ${neutralGray};
    overflow: hidden;
    flex-shrink: 1;
    flex-grow: 1;

    & > section {
      flex-grow: 1;
      flex-basis: 0;
    }
  `,

  Table: css`
    flex-shrink: 1;
    color: ${baseTokens.gray800};

    td > * {
      padding-left: 1em;
      padding-right: 1em;
    }

    td > input {
      /* margin-left: 1em; */
    }
  `,

  TableHeader: css`
    font-weight: bold;

    & > * {
      position: sticky;
      top: 0;
      background-color: white;
    }
  `,

  TableFooter: css`
    & > * {
      position: sticky;
      bottom: 0;
      background-color: white;
    }
  `,

  TableItemOutline: css`
    min-height: 3.5em;
    border-bottom: 1px solid ${neutralGray};
  `,

  TableActiveItemOutline: css`
    min-height: 3.5em;
    border-bottom: 1px solid ${neutralGray};
    background-color: rgba(127, 127, 127, 0.1);
  `,

  TableItemOutlineNoBorder: css`
    min-height: 3.5em;
    justify-self: center;
    align-self: center;
    align-content: center;
    justify-items: center;
    align-items: center;
  `,

  TextArea: css`
    flex-grow: 1;
    min-height: 10em;
    outline: none;
    border: none;
    padding: 0.5em;
    font-family: inherit;
    &::placeholder {
      color: ${baseTokens.gray500};
    }
  `,

  Spinner: css`
    overflow: visible;
    height: 0;
    z-index: 100000;

    & > div {
      min-height: 2px;
      background-color: ${baseTokens.blue500};
      width: 25%;
      height: 100%;
      margin-top: 0;
      margin-left: -25%;
      animation: spinner 2s infinite ease-in-out both;
    }
  `,

  Dot: css`
    display: inline-block;
    border-radius: 50%;
    width: 0.5em;
    height: 0.5em;
    vertical-align: middle;
  `,

  Disabled: css`
    cursor: default;
    /* filter: grayscale(1); */

    a,
    a * {
      cursor: default;
    }
  `,
};

export default styles;
