import { Issue, IssuesOccurrencesResponse } from '@api-client';
import {  globalQueries } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import _ from 'lodash';
import React from 'react';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ParameterTable } from './parameter-table';
import useItemSelection from '@shared/hooks/use-item-selection';

export interface ParameterTableWrapperProps {
  scanId: string;
  kindId: number;
  urlPath: string;
  httpMethod: string;

  currentIssue?: Issue;
  currentIssueId?: string;
  setCurrentIssueId: (issueId?: string) => void;
  initialParamSettled: boolean;
  setInitialParamSettled: (value: boolean) => void;

  parentHeaderHeight: number;
  firstScrollToIssue: boolean;
  setFirstScrollToIssue: (value: boolean) => void;
  scrollRef: React.RefObject<HTMLDivElement>;
}

export const ParameterTableWrapper: React.FC<ParameterTableWrapperProps> = ({
  scanId,
  kindId,
  urlPath,
  httpMethod,
  currentIssue,
  currentIssueId,
  setCurrentIssueId,
  initialParamSettled,
  setInitialParamSettled,
  ...props
}) => {
  const { setError } = React.useContext(ErrorContext);
  const [groupedIssues, setGroupedIssues] = React.useState<_.Dictionary<IssuesOccurrencesResponse[]>>();

  const {
    issues = [], isIssuesLoading,
    isIssuesFetchedAfterMount,
    issuesListHasNextPage, fetchIssuesListNextPage, isFetchingNextPage,
  } = globalQueries.useGetIssueOccurrencesList({
    issuesOccurrencesFiltersRequest: {
      scan_id: scanId,
      kind_id: kindId,
      url_path: urlPath,
      http_method: httpMethod,
    },
  }, {
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  React.useEffect(() => {
    if (issuesListHasNextPage && !(isIssuesLoading || isFetchingNextPage)) {
      fetchIssuesListNextPage();
    }
  }, [issuesListHasNextPage, fetchIssuesListNextPage, isIssuesLoading, isFetchingNextPage]);

  React.useEffect(() => {
    const grouped = _.groupBy(issues, 'parameter_name');
    setGroupedIssues(grouped);
  }, [issues]);

  const parameterData: IssuesOccurrencesResponse[] = groupedIssues
    ? Object.keys(groupedIssues).map(key => groupedIssues[key][0])
    : [];

  const parameterSelectionApi = useItemSelection<IssuesOccurrencesResponse>({
    data: parameterData,
    keyBy: item => item.parameter_name,
    labelBy: item => item.parameter_name,
  });

  React.useEffect(() => {
    const selectedItems = new Map(parameterSelectionApi.selectedItems);
    const parameterData: IssuesOccurrencesResponse[] = groupedIssues
      ? Object.keys(groupedIssues).map(key => groupedIssues[key][0])
      : [];
    parameterSelectionApi.reinitializeData(parameterData);

    parameterSelectionApi.selectedItems.forEach((item) => {
      if (selectedItems.has(item.parameter_name) && !parameterData.find((issue) => issue.parameter_name === item.parameter_name)) {
        selectedItems.delete(item.parameter_name);
      }
    });
    parameterSelectionApi.reinitialize(selectedItems);
  }, [JSON.stringify(groupedIssues)]);

  const [expandedParameters, setExpandedParameters] = React.useState<Set<string>>(new Set());

  const toggleParameter = (name: string) => {
    const open = !expandedParameters.has(name);
    const newExpandedParameters = new Set(expandedParameters);
    if (open) {
      newExpandedParameters.add(name);
    } else {
      newExpandedParameters.delete(name);
    }
    setExpandedParameters(newExpandedParameters);
  };

  React.useEffect(() => {
    if (!currentIssueId && isIssuesFetchedAfterMount && !isIssuesLoading && groupedIssues && Object.keys(groupedIssues).length) {
      const [first] = Object.keys(groupedIssues);
      setCurrentIssueId(groupedIssues[first]?.[0].id);
    }
  }, [groupedIssues, isIssuesFetchedAfterMount, currentIssueId, isIssuesLoading]);

  React.useEffect(() => {
    if (currentIssue && !isFetchingNextPage && groupedIssues && Object.keys(groupedIssues).length && !initialParamSettled) {
      const issueIndex = groupedIssues[currentIssue.parameter_name || '']?.findIndex(issue => issue.id === currentIssue.id) || -1;
      if (issueIndex !== -1) {
        if (issueIndex !== 0 && !expandedParameters.has(currentIssue.parameter_name || '')) {
          toggleParameter(currentIssue.parameter_name || '');
        }
        setInitialParamSettled(true);
      }
      // no issue
    }
  }, [groupedIssues, currentIssue, issuesListHasNextPage, isFetchingNextPage, initialParamSettled]);

  return (
    <ParameterTable
      groupedIssues={groupedIssues}
      isIssuesLoading={isIssuesLoading || isFetchingNextPage || !!issuesListHasNextPage}
      parameterSelectionApi={parameterSelectionApi}
      expandedParameters={expandedParameters}
      toggleParameter={toggleParameter}
      setCurrentIssueId={setCurrentIssueId}
      currentIssueId={currentIssueId}
      scanId={scanId}
      kindId={kindId}
      path={urlPath}
      method={httpMethod}
      {...props}
    />
  );
};