import { Table, TableBodyProps } from '@contentful/f36-components';
import { styled } from '@mui/material';
import { withSafeProps } from 'views/utils/common';

interface StyledBasicTableBodyProps extends TableBodyProps {
  withRowSelection: boolean;
  withSelectableRows: boolean;
}

const StyledBasicTableBody = styled(withSafeProps<StyledBasicTableBodyProps>(Table.Body, ['withRowSelection', 'withSelectableRows']))`
  tr:hover td{
    background-color: ${({ withRowSelection }) => !withRowSelection && 'white !important'};
    cursor: ${({ withSelectableRows }) => withSelectableRows ? 'pointer' : 'initial'};
  }

  tr td{
    background-color: ${({ withRowSelection }) => !withRowSelection && 'white !important'};
  }
`;

export { StyledBasicTableBody };