import { Alert, AlertTitle, ThemeProvider, createTheme } from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import React from 'react';
import { getBorderColor } from './duck';
import { SnackbarNotificationProps } from '@shared/duck/types';

type NotificationProps = SnackbarNotificationProps & Omit<CustomContentProps, 'action'>;

export const NotificationMui = React.forwardRef<HTMLDivElement, NotificationProps>(({
  id,
  message,
  background = 'standard',
  variant,
  closeBtn = true,
  title,
  action,
  sx,
}: NotificationProps, ref) => {
  const { closeSnackbar } = useSnackbar();

  const onCloseSnackbar = React.useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <ThemeProvider theme={createTheme()}>
      <SnackbarContent ref={ref}>
        <Alert
          severity={variant}
          onClose={closeBtn ? onCloseSnackbar : undefined}
          action={action}
          variant={background}
          sx={{
            width: '100%',
            bgcolor: background === 'outlined' ? 'background.paper' : undefined,
            border: background === 'standard' ? `1px solid ${getBorderColor(variant)}` : undefined,
            ...sx
          }}

        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </SnackbarContent>
    </ThemeProvider>
  );
});

NotificationMui.displayName = 'NotificationMui';