import React from 'react';
import { NoContentPlaceholder, UIProjectSelect, F36Select, FormikControl, FormikControlMui, MuiTextInput } from '@shared/components';
import { CustomCredentialsType, SupportedCredentials } from '../../duck';
import { useDebounce } from '@shared/hooks';
import { AttentionGray } from '@assets';
import { CookiesBasedForm, HeaderBasedForm, NameInput, PlaywrightScript } from './components';
import { Grid, GridItem } from '@contentful/f36-components';

interface GeneralPageProps {
  currentType: CustomCredentialsType | undefined;
  project: string;
  enableProjectSelect?: boolean;
  authenticationId?: string;
}

const GeneralPage: React.FC<GeneralPageProps> = ({
  currentType,
  project,
  enableProjectSelect = true,
  authenticationId,
}: GeneralPageProps) => {
  const { debouncedValue: typeSearch = '', onChange: onAuthTypeChange } = useDebounce<string>();
  const { debouncedValue: projectSearch = '', onChange: onProjectChange } = useDebounce<string>();

  const options = SupportedCredentials
    .filter((cred) => cred.label.toLowerCase().includes(typeSearch.trim().toLowerCase()));

  const initType = options.find(option => option.value === currentType);

  return (
    <>
      <Grid columns='1fr 1fr'>
        {
          <GridItem area='span 1 / span 2'>
            <FormikControl name='project' label='Project'>
              <UIProjectSelect
                onSearchChange={onProjectChange}
                search={projectSearch}
                currentValue={project}
                withEmptyItem={true}
                disabled={!enableProjectSelect}
              />
            </FormikControl>
          </GridItem>
        }
        {(!authenticationId && Boolean(project)) && (
          <GridItem area='span 1 / span 2'>
            <FormikControl name='type' label='Authentication Type'>
              <F36Select
                initialValue={initType}
                options={options}
                placeholder='Select an option'
                onSearchChange={onAuthTypeChange}
                withEmptyItem={false}
                withSearch={false}
                noItemsMessage={
                  <NoContentPlaceholder
                    iconSrc={AttentionGray}
                    message='No Authentications found'
                  />}
              />
            </FormikControl>
          </GridItem>
        )}
        {currentType?.length && project && (
          <>
            <GridItem>
              <FormikControlMui name='name' label='Authentication name' showError mb='1.5rem'>
                <NameInput
                  isInEdit={!!authenticationId}
                  projectId={project}
                />
              </FormikControlMui>
            </GridItem>
            <GridItem>
              <FormikControlMui name='description' label='Description' mb='1.5rem'>
                <MuiTextInput
                  placeholder='Enter description'
                  size='small'
                />
              </FormikControlMui>
            </GridItem>
          </>
        )}
      </Grid>
      {currentType === CustomCredentialsType.HeaderBased && <HeaderBasedForm />}
      {currentType === CustomCredentialsType.CookiesBased && <CookiesBasedForm />}
      {currentType === CustomCredentialsType.Script && <PlaywrightScript inEdit={!!authenticationId}/>}
    </>
  );
};

export default GeneralPage;