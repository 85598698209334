import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  buttonTypes,
  chipTypes,
  iconButtonTypes,
  inputTypes,
  dialogTypes,
  autocompleteTypes,
  tooltipTypes,
  muiPalette,
  cardTypes,
  appBarTypes,
  linkTypes,
  paperTypes,
  alertTypes,
  checkboxTypes,
  menuTypes,
  muiTypography,
  stackTypes,
  tableTypes,
  tabsTypes,
  selectTypes,
  accordionTypes,
} from './components';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: buttonTypes.ButtonStyles,
      defaultProps: buttonTypes.ButtonDefaultProps,
      variants: buttonTypes.ButtonVariants,
    },
    MuiToggleButton: {
      styleOverrides: buttonTypes.ToggleButtonStyles,
      defaultProps: buttonTypes.ToggleButtonDefaultProps,
      variants: buttonTypes.ToggleButtonVariants,
    },
    MuiIconButton: {
      styleOverrides: iconButtonTypes.IconButtonStyles,
      defaultProps: iconButtonTypes.IconButtonDefaultProps,
      variants: iconButtonTypes.IconButtonVariants,
    },
    MuiChip: {
      styleOverrides: chipTypes.ChipStyles,
      defaultProps: chipTypes.ChipDefaultProps,
      variants: chipTypes.ChipVariants,
    },
    MuiInputBase: {
      styleOverrides: inputTypes.InputBaseStyles,
    },
    MuiDialog: {
      styleOverrides: dialogTypes.DialogStyles,
      variants: dialogTypes.DialogVariants,
    },
    MuiDialogTitle: {
      styleOverrides: dialogTypes.DialogTitleStyles,
    },
    MuiDialogContent: {
      styleOverrides: dialogTypes.DialogContentStyles,
    },
    MuiDialogActions: {
      styleOverrides: dialogTypes.DialogActionsStyles,
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'unset',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: autocompleteTypes.AutocompleteStyles,
      defaultProps: autocompleteTypes.AutocompleteDefaultProps,
    },
    MuiSelect: {
      styleOverrides: selectTypes.SelectStyles,
      defaultProps: selectTypes.SelectDefaultProps,
    },
    MuiOutlinedInput: {
      styleOverrides: inputTypes.InputOutlinedStyles,
    },
    MuiTooltip: {
      defaultProps: tooltipTypes.TooltipDefaultProps,
      styleOverrides: tooltipTypes.TooltipStyles,
    },
    MuiCard: {
      styleOverrides: cardTypes.CardStyles,
      defaultProps: cardTypes.CardDefaultProps,
    },
    MuiCardActionArea: {
      styleOverrides: cardTypes.CardActionAreaStyles,
      defaultProps: cardTypes.CardActionAreaDefaultProps,
    },
    MuiAppBar: {
      styleOverrides: appBarTypes.AppBarStyles,
    },
    MuiLink: {
      defaultProps: linkTypes.LinkDefaultProps,
      styleOverrides: linkTypes.LinkStyles,
    },
    MuiFormControl: {
      styleOverrides: inputTypes.MuiFormControlStyles,
    },
    MuiPaper: {
      defaultProps: paperTypes.PaperDefaultProps,
    },
    MuiAlert: {
      styleOverrides: alertTypes.AlertStyles,
      variants: alertTypes.AlertVariants,
    },
    MuiCheckbox: {
      styleOverrides: checkboxTypes.CheckboxStyles,
    },
    MuiMenu: {
      styleOverrides: menuTypes.MenuStyles,
    },
    MuiMenuItem: {
      styleOverrides: menuTypes.MenuItemStyles,
      variants: menuTypes.MenuItemVariants,
    },
    MuiListItemIcon: {
      styleOverrides: menuTypes.ListItemIconStyles,
    },
    MuiStack: {
      styleOverrides: stackTypes.StackStyles,
    },
    MuiTab: {
      styleOverrides: tabsTypes.TabsStyles,
    },
    MuiTable: {
      styleOverrides: tableTypes.TableStyles,
    },
    MuiTableCell: {
      styleOverrides: tableTypes.CellStyles,
    },
    MuiTableRow: {
      styleOverrides: tableTypes.RowStyles,
    },
    MuiTablePagination: {
      styleOverrides: tableTypes.PaginationStyles,
    },
    MuiAccordion: {
      defaultProps: accordionTypes.AccordionDefaultProps,
      styleOverrides: accordionTypes.AccordionStyles,
    },
    MuiAccordionSummary: {
      defaultProps: accordionTypes.SummaryDefaultProps,
      styleOverrides: accordionTypes.SummaryStyles,
    },
    MuiAccordionDetails: {
      styleOverrides: accordionTypes.DetailsStyles,
    },
  },
  palette: muiPalette,
  typography: muiTypography,
  spacing: 16,
});

export const generalMuiTheme = responsiveFontSizes(theme);