export enum DayRangeEnum {
  ALL = 'All',
  DAY = 'Last 24 hours',
  WEEK = 'Last 7 days',
  MONTH = 'Last 30 days',
}

export const DAY_RANGE_LIST: DayRangeEnum[] = [
  DayRangeEnum.ALL,
  DayRangeEnum.MONTH,
  DayRangeEnum.WEEK,
  DayRangeEnum.DAY,
];