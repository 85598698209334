import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { generalMuiTheme } from '@shared/general-mui-theme';
import { SnackbarProvider } from '@shared/duck/contexts';
import { AppWindowContainer } from './app-provider.styled';
import { AppContext, Workspace, Authenticator, GlobalErrorHandler } from './components';

const AppProvider: React.FC = () => {
  return (
    <ThemeProvider theme={generalMuiTheme}>
      <AppWindowContainer>
        <SnackbarProvider>
          <BrowserRouter>
            <AppContext>
              <GlobalErrorHandler>
                <Authenticator>
                  <Workspace />
                </Authenticator>
              </GlobalErrorHandler>
            </AppContext>
          </BrowserRouter>
        </SnackbarProvider>
      </AppWindowContainer>
    </ThemeProvider>
  );
};

export { AppProvider };
