import { MuiContentLoader } from '@shared/components';
import { globalQueries } from '@shared/duck';
import { useGetCredentials } from '@shared/duck/queries';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { AuthModalControls, DownloadPage, GeneralPage, GenerateCommandPage, ModalHeader, RerecordCommandPage, TestAuthPage } from './components';
import {
  CookiesBasedFormValues,
  ICookiesBasedForm,
} from './components/general-page/components/cookies-based-form/cookies-based-form';
import {
  HeaderBasedFormValues,
  IHeaderBasedForm,
} from './components/general-page/components/header-based-form/header-based-form';
import { AddCredentialsPage, convertTypeToCustom, CustomCredentialsType, validationSchema } from './duck';
import { ErrorContext } from '@shared/duck/contexts';
import { useQueryClient } from '@tanstack/react-query';
import { CredentialQueryKeys } from '@shared/duck/queries/queriesKeys';
import { Slide, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export interface AuthenticationModalProps {
  onClose: () => void;
  onSubmit: (values: AuthenticationFormValues, formikHelpers: FormikHelpers<AuthenticationFormValues>) => Promise<boolean>;
  authenticationId?: string;
  enableProjectSelect?: boolean;
  initialProjectId?: string;
}

export interface AuthenticationFormValues {
  name: string;
  description?: string;
  project?: string;
  type: CustomCredentialsType | undefined;
  headerBasedForm: IHeaderBasedForm;
  cookiesBasedForm: ICookiesBasedForm;
  playwrightUrl: string;
  playwrightUrlTested: boolean;
  nameExists?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const AuthenticationModal: React.FC<AuthenticationModalProps> = ({
  onClose,
  onSubmit,
  authenticationId,
  enableProjectSelect,
  initialProjectId,
}: AuthenticationModalProps) => {
  const queryClient = useQueryClient();
  const { checkPublicUrl, isLoading: isGenerateLoading } = globalQueries.useCheckPublicUrl();

  const { project: initProject = null, isProjectLoading = false } = globalQueries.useGetProject(
    { id: initialProjectId || '' }, { enabled: !!initialProjectId });

  const { defaultProject = null, isDefaultProjectLoading } = globalQueries.useGetDefaultProject();

  const { setError } = React.useContext(ErrorContext);
  const { credentials: authentication, isCredentialsLoading } = useGetCredentials(
    { id: authenticationId || '' },
    {
      enabled: !!authenticationId,
      onError: (er) => errorResponseHandler(er, 'authentication', setError),
    },
  );

  const credentialsInitialValues: AuthenticationFormValues = React.useMemo(() => {
    return {
      name: authentication?.name || '',
      nameExists: authentication ? false : undefined,
      description: authentication?.description || '',
      type: authentication ? convertTypeToCustom(authentication.type) : undefined,
      project: authentication?.project || (
        initProject ? initProject.id : defaultProject ? defaultProject.id : undefined
      ),
      headerBasedForm: authentication ? {
        headers: authentication.headers,
      } as IHeaderBasedForm : HeaderBasedFormValues,
      cookiesBasedForm: authentication ? {
        cookie: authentication.cookie,
      } as ICookiesBasedForm : CookiesBasedFormValues,
      playwrightUrl: authentication?.script_first_url || '',
      playwrightUrlTested: !!authentication,
    };
  }, [authentication, defaultProject, initProject]);

  const [page, setPage] = React.useState<AddCredentialsPage>(AddCredentialsPage.GENERAL);

  const [isShown, setIsShown] = React.useState<boolean>(true);
  const handleClose = () => setIsShown(false);

  return (
    <Dialog
      fullScreen
      open={isShown}
      onClose={handleClose}
      onTransitionExited={onClose}
      scroll='paper'
      TransitionComponent={Transition}
      sx={{
        '.MuiDialog-container': {
          justifyContent: 'end',
        },
        '& .MuiDialog-paper': {
          width: '50%',
          borderRadius: '0.375rem 0 0 0.375rem',
        },
      }}
    >
      <Formik<AuthenticationFormValues>
        initialValues={credentialsInitialValues}
        onSubmit={async (values, helpers) => {
          const shouldClose = await onSubmit(values, helpers);
          shouldClose && handleClose();
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnMount
        enableReinitialize
      >
        {({ values, errors, setErrors, touched, setFieldValue, initialValues }) => {

          if (!values.name && !errors.name && touched.name)
            setErrors({ 'name': 'Name is required' });

          const onCloseModal = ({ invalidateQueries }: { invalidateQueries: boolean }) => {
            if (invalidateQueries) {
              queryClient.invalidateQueries(({ queryKey: [CredentialQueryKeys.credentialsList] }));
            }
            handleClose();
          };

          React.useEffect(() => {
            if (initialValues.name === values.name) {
              setFieldValue('nameExists', false);
            }
          }, [values.name]);

          const onGenerateCommandClick = async () => {
            const { data } = await checkPublicUrl(values.playwrightUrl);
            setFieldValue('playwrightUrl', data.requested_url);
            setPage(AddCredentialsPage.GENERATE_COMMAND);
          };

          return (
            <>
              <DialogTitle>
                <ModalHeader
                  page={page}
                  authenticationId={authenticationId}
                  closeModal={handleClose}
                  setNewPage={setPage}
                />
              </DialogTitle>
              <Form style={{ height: '100%', display: 'flex', overflow: 'auto' }}>
                <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  <MuiContentLoader
                    isLoading={isDefaultProjectLoading
                      || (
                        authenticationId && isCredentialsLoading
                      )
                      || (
                        isProjectLoading && !!initialProjectId
                      )}
                  >
                    {(
                      () => {
                        switch (page) {
                          case AddCredentialsPage.GENERAL:
                            return (
                              <GeneralPage
                                currentType={values.type}
                                project={initProject ? initProject.id : values.project || ''}
                                enableProjectSelect={enableProjectSelect}
                                authenticationId={authenticationId}
                              />
                            );
                          case AddCredentialsPage.TEST:
                            return (
                              <TestAuthPage
                                setOpenDownloadPage={() => setPage(AddCredentialsPage.DOWNLOAD)}
                              />
                            );
                          case AddCredentialsPage.DOWNLOAD:
                            return (
                              <DownloadPage />
                            );
                          case AddCredentialsPage.GENERATE_COMMAND:
                            return (
                              <GenerateCommandPage authId={authenticationId} setNewPage={setPage} />
                            );
                          case AddCredentialsPage.RERECORD:
                            return (
                              <RerecordCommandPage setNewPage={setPage} />
                            );
                        }
                      }
                    )()}
                  </MuiContentLoader>
                </DialogContent>
              </Form>
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <AuthModalControls
                  page={page}
                  authenticationId={authenticationId}
                  returnToGeneralPage={() => setPage(AddCredentialsPage.GENERAL)}
                  openReRecordPage={() => setPage(AddCredentialsPage.RERECORD)}
                  onGenerateCommandClick={onGenerateCommandClick}
                  isGenerateLoading={isGenerateLoading}
                  onClose={onCloseModal}
                />
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AuthenticationModal;