import { Box, Stack, Typography } from '@mui/material';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

export interface ExpandButtonProps {
  issuesLength: number;
  expanded: boolean;
  onToggle?: React.MouseEventHandler<HTMLDivElement>,
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({
  issuesLength,
  expanded,
  onToggle,
}) => {
  const shadowColor = 'rgba(25, 37, 50, 0.10)';

  return (
    <Box
      mb='0.3rem'
      position='relative'
      onClick={onToggle}
      sx={{
        cursor: 'pointer'
      }}
    >
      <Box
        sx={{
          p: '0.1rem 0.75rem 0.1rem 4.5rem',
          borderTop: `1px solid ${muiPalette.grey?.[300]}`,
          backgroundColor: `${muiPalette.grey?.[200]} !important`,
          boxShadow: `0rem 0.375rem 0.75rem ${shadowColor}, 0rem 0.1875rem 0.4375rem ${shadowColor}`,
          padding: '0.3rem 0.75rem 0.4rem 2.75rem',
          borderRadius: '0 0 0.375rem 0.375rem',
        }}>
        <Stack direction='row' gap='0.25rem' alignItems='center'>
          {expanded ? <CaretUp size='14'/> : <CaretDown size='14'/>}
          <Typography variant='body2'>
            {expanded ? `Hide ${issuesLength} entries` :
              `Expand ${issuesLength} more entries`}
          </Typography>
        </Stack>
      </Box>
      <Stack sx={{
        borderRadius: '0.375rem',
        left: '0.2rem',
        background: muiPalette.grey?.[300],
        position: 'absolute',
        height: '0.5rem',
        bottom: '-0.2rem',
        right: '0.2rem',
        zIndex: -1,
      }} />
    </Box>
  );
};