import { ScanRequest, SpecStatusEnum } from '@api-client';
import { Note } from '@contentful/f36-components';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Modal, ModalContent, ModalHeader, ScanStatusProgress, UICodeFragment, UIDialogTitle } from '@shared/components';
import { globalEnums, globalConstants, globalQueries, globalUtils } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { errorResponseHandler } from 'views/utils/errorHandlers';

interface ModalPopup {
  scanId: string | '';
  closeModal: () => void;
  onRescanClick: (values: ScanRequest) => Promise<void>;
}

const RescanPopup: React.FC<ModalPopup> = ({
  scanId,
  closeModal,
  onRescanClick,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const { scan = undefined, isScanLoading } = globalQueries.useGetScan({ id: scanId }, {
    enabled: !!scanId,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });
  const isAuthDeleted = scan?.credentials?.is_deleted;

  const [code, setCode] = React.useState('');
  React.useEffect(() => {
    const onRescan = async () => {
      if (scan?.relay_id) {
        if (!isAuthDeleted) {
          setCode(`nightvision scan ${scan?.target.name} \\\n` +
            `-P ${scan?.target.project} ` +
            (
              scan?.credentials ? `\\\n--auth ${scan?.credentials?.name}` : ''
            )
          );
        }
        else {
          enqueueSnackbar(globalConstants.RESCAN_IS_NOT_AVAILABLE_AUTH, { variant: 'error' });
          closeModal();
        }
      }
    };

    onRescan();
  }, [scan?.id]);

  const [isReadyToScan, setIsReadyToScan] = React.useState<boolean>();
  const [specStatus, setSpecStatus] = React.useState<SpecStatusEnum>();
  const { waitTargetForReadyToScan } = globalUtils.waitTargetForReadyToScanHandler();

  React.useEffect(() => {
    const onRescan = async () => {
      if (scan && !scan.relay_id && !isScanLoading) {
        if (!isAuthDeleted) {
          const values: ScanRequest = {
            credentials_id: scan.credentials_id || '',
            target_id: scan.target.id || '',
          };

          if (scan.target_type.toUpperCase() === globalEnums.ETargetType.OpenAPI) {
            const { error, aborted } = await waitTargetForReadyToScan(
              values.target_id,
              status => setSpecStatus(status),
              isReady => setIsReadyToScan(isReady)
            );

            if (error) {
              enqueueSnackbar(error, { variant: 'error' });
              return;
            }
            if (aborted) return;
          }
          await onRescanClick(values);
        }
        else {
          enqueueSnackbar(globalConstants.RESCAN_IS_NOT_AVAILABLE_AUTH, { variant: 'error' });
        }
        closeModal();
      }
    };

    onRescan();
  }, [scan?.id]);

  return (
    scan?.relay_id && !isAuthDeleted ? (
      <Modal
        isShown={!!scanId || !isScanLoading}
        onClose={closeModal}
        size='large'
      >
        {() => (
          <>
            <ModalHeader onClose={closeModal} title='Unable to run scan directly...' />
            <ModalContent style={{ display: 'flex', flexDirection: 'column', maxHeight: '40rem' }}>
              <Typography variant='default' mb='1rem'>
                {'This looks like a private target to which NightVision does not have direct access. To re-run the scan, try the following command from your CLI'}
              </Typography>
              <UICodeFragment
                code={code}
                language={globalEnums.SupportedLangs.evidence_markup}
                useTabsForMultiline
              />

              <Note
                variant={globalEnums.ENoteVariants.PRIMARY}
                style={{ marginTop: '1rem' }}
              >
                {'Make sure your environment has network access to the application.'}
              </Note>
            </ModalContent>
          </>
        )}
      </Modal>
    ) : (
      scan?.target_type.toUpperCase() === globalEnums.ETargetType.OpenAPI ? <Dialog
        maxWidth='xs'
        fullWidth
        open={true}
        onClose={() => closeModal()}
        onTransitionExited={closeModal}
      >
        <UIDialogTitle
          title={'OpenAPI spec status'}
          onCloseBtnAction={() => closeModal()}
        />
        <DialogContent dividers sx={{ borderBottom: 'none' }}>
          <ScanStatusProgress
            specStatus={specStatus}
          />
        </DialogContent>
        <DialogActions sx={{ pt: '0', gap: '0.5rem' }}>
          <Button
            autoFocus
            disabled={isReadyToScan}
            onClick={() => closeModal()}
            size='small'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
        : <></>
    )
  );
};

export default RescanPopup;