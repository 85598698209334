import { Stack, StackProps, styled } from '@mui/material';
import { withSafeProps } from 'views/utils/common';

interface NameWrapperProps extends StackProps {
  editMode: boolean;
}

const NameWrapper = styled(withSafeProps<NameWrapperProps>(Stack, ['editMode']))`
  justify-content: flex-start;
  flex-grow: 1;
  overflow: ${({ editMode }) => editMode ? '' : 'hidden'};
  margin-left: ${({ editMode }) => editMode ? '-0.5rem' : ''};
`;

export { NameWrapper };