import { Grid } from '@contentful/f36-components';
import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

const StyledGrid = styled(Grid)`
  word-break: break-word;
  color: ${baseTokens.gray700};

  & > div:nth-of-type(2n + 1) {
    font-weight: 600;
    color: ${baseTokens.gray900};
    display: flex;
  }

  .external-link {
    padding: 0;
    min-height: 1.25rem; /* equal to line-height */
    margin-left: 0.5rem;
    background: transparent;
  }
`;

export { StyledGrid };
