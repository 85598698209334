import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

const VerticalDivider = styled('div')`
  width: 1px;
  align-self: stretch;
  background: ${baseTokens.gray300};
`;

export default VerticalDivider;
