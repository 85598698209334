import { Issue } from '@api-client';
import React from 'react';
import { useDebounce } from '@shared/hooks';
import { MuiSearchInput } from '@shared/components';
import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { PathsTableWrapper } from '../paths-table';

interface IssuesOccurrencesTableProps {
  scanId: string;
  targetId: string;
  kindId: number;

  currentIssue?: Issue;
  currentIssueId?: string;
  setCurrentIssueId: (issueId?: string) => void;
  setNoContent: (value: boolean) => void;
  unselectCurrentIssueOnResolutionFilterChange?: boolean;
  firstScrollToIssue: boolean;
  setFirstScrollToIssue: (value: boolean) => void;
}

const DetailsVulPathsTable: React.FC<IssuesOccurrencesTableProps> = ({
  scanId,
  targetId,
  kindId,
  currentIssue,
  currentIssueId,
  setCurrentIssueId,
  firstScrollToIssue,
  setFirstScrollToIssue,
}) => {
  const { debouncedValue: debouncedSearch, onChange: onSearchChange } = useDebounce<string>();

  return (
    <Stack gap='1rem' height='100%'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
        open={firstScrollToIssue}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack gap='0' width='100%' overflow='auto' height='100%'>
        <Stack
          direction='row' justifyContent='space-between'
          sx={{
            borderBottom: `1px solid ${muiPalette.grey?.[300]}`,
            p: '0.75rem',
          }}
        >
          <MuiSearchInput
            width='18.75rem'
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </Stack>

        <PathsTableWrapper
          scanId={scanId}
          targetId={targetId}
          kindId={kindId}
          debouncedSearch={debouncedSearch}
          setCurrentIssueId={setCurrentIssueId}
          currentIssue={currentIssue}
          currentIssueId={currentIssueId}
          firstScrollToIssue={firstScrollToIssue}
          setFirstScrollToIssue={setFirstScrollToIssue}
        />
      </Stack>
    </Stack>
  );
};

export default DetailsVulPathsTable;
