import { InfiniteQueryObserverOptions, QueryObserverOptions, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import * as apiClient from 'api-client';
import { AxiosError, AxiosResponse } from 'axios';
import { useApp } from '@shared/hooks';
import { IssueKindsQueryKeys } from './queriesKeys';
import { getNextPageParam, useFlatResults } from './utils';

const useGetIssueKind = (
  request: apiClient.IssueKindsApiIssueKindsRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<apiClient.IssueKind>, AxiosError>>,
) => {
  const { issueKindApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [IssueKindsQueryKeys.issueKindById, ...Object.values(request)],
    queryFn: () => issueKindApi.issueKindsRetrieve(request),
    ...config,
  });

  return {
    issueKind: data?.data,
    isIssueKindLoading: isLoading,
  };
};

const useGetIssueKindsList = (
  request: apiClient.IssueKindsApiIssueKindsListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedIssueKindList>, AxiosError>>,
) => {
  const { issueKindApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [IssueKindsQueryKeys.issueKindsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => issueKindApi.issueKindsList({ ...request, page: pageParam }),
    getNextPageParam: getNextPageParam,
    ...config,
  });

  const issueKinds = useFlatResults(data);

  return {
    issueKinds,
    isIssueKindsListLoading: isLoading || isFetchingNextPage,
    issueKindsHasNextPage: hasNextPage,
    fetchNextIssueKindsPage: fetchNextPage,
  };
};

export {
  useGetIssueKind,
  useGetIssueKindsList,
};
