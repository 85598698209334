import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Stack, Alert } from '@mui/material';
import { FormikControlMui } from '@shared/components/formik';
import { muiPalette } from '@shared/general-mui-theme';
import { Exclusion } from './components';
import { globalConstants } from '@shared/duck';

export interface TargetExclusionsProps {
  targetsCount?: number;
  isApiTarget?: boolean;
  isCreateCommand?: boolean;
}

export const TargetExclusions: React.FC<TargetExclusionsProps> = ({
  targetsCount = 1,
  isApiTarget = false,
  isCreateCommand = false,
}) => {
  const defaultExclusions = isCreateCommand ? globalConstants.DEFAULT_EXCLUSIONS : [];

  return (
    <Stack>
      <Accordion>
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
            },
          }}
        >
          <Typography fontWeight='500'>Exclusions</Typography>
          <Typography
            variant='subtitle2'
            color={muiPalette.grey?.[500]}
            sx={{ opacity: '0.5' }}
          >
            &nbsp;(optional)
          </Typography>
        </AccordionSummary>
        {targetsCount === 1 ? (
          <AccordionDetails>
            <Stack gap='0.75rem'>
              <Alert severity='warning'>
                {`Requests to specific paths can cause critical problems. These may invalidate user sessions or trigger
                  destructive actions like data deletion or system resets. Excluding these paths ensures application stability
                  and prevents unintended disruptions.`}
              </Alert>
              <FormikControlMui name={'configuration.excluded_url_patterns'} label='Exclude URL patterns' fullWidth>
                <Exclusion fieldName='excluded_url_patterns' placeholder='Enter URLs or regex' defaultExclusions={defaultExclusions}/>
              </FormikControlMui>

              {!isApiTarget && (
                <FormikControlMui name={'configuration.excluded_x_paths'} label='Exclude clicks based on XPath' fullWidth>
                  <Exclusion fieldName='excluded_x_paths' placeholder='Exclude clicks based on XPath'/>
                </FormikControlMui>
              )}
            </Stack>
          </AccordionDetails>
        ) : (
          <AccordionDetails>
            <Alert severity='info'>
              {'You have multiple targets. Configure each one after creation.'}
            </Alert>
          </AccordionDetails>
        )}
      </Accordion>
    </Stack>
  );
};