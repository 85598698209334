import { IssuesOccurrencesResponse } from '@api-client';
import { Box, Checkbox, CheckboxProps, Chip, Stack, SxProps, Theme } from '@mui/material';
import { FalsePositiveMark, FalsePositiveMenu } from '@shared/components';
import { globalUtils } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import React from 'react';

type HeaderRow = {
  header: true;
  onRowClick?: React.MouseEventHandler<HTMLDivElement>,
  selected?: boolean;
  isLastIssue?: boolean;
  issue?: IssuesOccurrencesResponse;
};

type BasicRow = {
  header?: false;
  onRowClick: React.MouseEventHandler<HTMLDivElement>,
  selected: boolean;
  isLastIssue: boolean;
  issue: IssuesOccurrencesResponse;
};

export interface ParameterRowProps {
  parameterSelectionApi: ItemSelectionApi<IssuesOccurrencesResponse>,
  checkboxDisabled?: boolean;
  kindId: number;
  path: string;
  method: string;
  scanId: string;
}

export const ParameterRow: React.FC<ParameterRowProps & (HeaderRow | BasicRow)> = ({
  parameterSelectionApi,
  header = false,
  onRowClick,
  selected = false,
  isLastIssue = false,
  checkboxDisabled = false,
  issue,
  kindId,
  path,
  method,
  scanId,
}) => {
  const rowSx: SxProps<Theme> = {
    // common
    p: '0.75rem',
    background: 'white',

    // basic
    ...(!header && {
      cursor: 'pointer',
      ...(selected && {
        background: muiPalette.blue?.[100],
      }),
      ...(!selected && {
        '&:hover': {
          background: muiPalette.grey?.[100],
        }
      }),

      ...(isLastIssue && {
        borderRadius: '0 0 0.375rem 0.375rem',
      }),
    }),

    // header
    ...(header && {
      borderBottom: `1px solid ${muiPalette.grey?.[300]}`,
      borderRadius: '0.375rem 0.375rem 0 0',
    }),
  };

  const checkboxProps: CheckboxProps = {
    checked: header
      ? (parameterSelectionApi?.isAllSelected || parameterSelectionApi?.isPartiallySelected)
      : issue && parameterSelectionApi?.selectedItems.has(issue.parameter_name),
    indeterminate: header && parameterSelectionApi?.isPartiallySelected,
    onChange: () => header
      ? parameterSelectionApi?.onToggleAll()
      : issue && parameterSelectionApi?.onToggleItem(issue),
    disabled: checkboxDisabled,
    size: 'small',
    disableRipple: true,
    sx: { p: 0 }
  };

  const selectedItems = Array.from(parameterSelectionApi.selectedItems.values());
  const markAllAsFP = selectedItems.filter(issue => issue.is_false_positive).length !== parameterSelectionApi.selectedItems.size;

  return (
    <Stack
      direction='row' alignItems='center'
      sx={rowSx}
      onClick={onRowClick}
    >
      {!header && issue?.is_false_positive && <FalsePositiveMark />}
      <Stack maxWidth='2.3rem' width='100%' alignItems='end' onClick={(e) => e.stopPropagation()}>
        <Checkbox {...checkboxProps} />
      </Stack>
      <Box maxWidth='2rem' width='100%'>
        {header && (
          <FalsePositiveMenu
            isFP={!markAllAsFP}
            request={{
              scan: scanId,
              kind: [kindId],
              path_method: [{ path: path, method: method }],
              parameter_name: selectedItems.map(i => i.parameter_name || '')
            }}
            disabled={!parameterSelectionApi.selectedItems.size}
            onAfterUpdate={() => parameterSelectionApi.reinitialize(new Map())}
          />
        )}
      </Box>
      <Box maxWidth='10rem' width='100%'>
        {header ? 'Parameter' : issue?.parameter_name}
      </Box>
      <Box flexGrow='1' width='50%'>
        {header ? 'Payload' : (
          <Box>
            {issue?.payload || 'N/A'}
          </Box>
        )}
      </Box>
      <Box maxWidth='7rem' width='100%'>
        {header ? 'HTTP code' : (
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            {issue?.http_status && (
              <Box>
                <Chip label={issue.http_status} color={globalUtils.getCodeColor(issue.http_status)}
                  sx={{
                  // Special yellow color for redirection codes
                    ...(globalUtils.getHttpCodeGroup(issue.http_status) === 3 && {
                      color: '#B78300',
                      background: '#FFF2CC',
                    })
                  }} />
              </Box>
            )}
            <Box flexShrink='0' onClick={(e) => e.stopPropagation()}>
              <FalsePositiveMenu
                isFP={!!issue?.is_false_positive}
                request={{
                  scan: scanId,
                  kind: [kindId],
                  path_method: [{ path: path, method: method }],
                  parameter_name: [issue?.parameter_name || ''],
                }}
                onAfterUpdate={() => parameterSelectionApi.reinitialize(new Map())}
              />
            </Box>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};