import { Box, Checkbox, CheckboxProps, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { FalsePositiveMark, FalsePositiveMenu, UIHttpMethodBadge } from '@shared/components';
import {  globalModels } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import React from 'react';

type HeaderRow = {
  header: true;
  mainHeaderHeight?: number;
  expanded?: boolean;
  path?: globalModels.VulnerablePathsViewModel,
  onTogglePath?: (e: any, pathId: string, currentRef: React.RefObject<HTMLDivElement>) => void,
  rowRef?: React.RefObject<HTMLDivElement>;
  onRowClick?: React.MouseEventHandler<HTMLDivElement>,
};

type BasicRow = {
  header?: false;
  mainHeaderHeight: number;
  expanded: boolean;
  path: globalModels.VulnerablePathsViewModel,
  onTogglePath: (e: any, pathId: string, currentRef: React.RefObject<HTMLDivElement>) => void,
  rowRef: React.RefObject<HTMLDivElement>;
  onRowClick: React.MouseEventHandler<HTMLDivElement>,
};

export interface PathRowProps {
  pathsSelectionApi: ItemSelectionApi<globalModels.VulnerablePathsViewModel>;
  checkboxDisabled?: boolean;
  scanId?: string;
  kindId?: number;
}

export const PathRow: React.FC<PathRowProps & (HeaderRow | BasicRow)> = ({
  pathsSelectionApi,
  header = false,
  mainHeaderHeight = 0,
  expanded = false,
  checkboxDisabled = false,
  rowRef = null,
  onRowClick,
  path,
  onTogglePath,
  scanId = '',
  kindId = 0,
}) => {
  const rowSx: SxProps<Theme> = {
    // common
    p: '0.75rem',
    fontSize: '0.875rem',
    background: 'white',

    // basic
    ...(!header && {
      position: 'sticky', top: mainHeaderHeight, zIndex: '9',
      borderBottom: expanded ? `1px solid ${muiPalette.grey?.[300]}` : 'none',
      lineHeight: '1.25rem',

      cursor: 'pointer',
      '&:hover': {
        background: muiPalette.grey?.[100],
      },
    }),

    // header
    ...(header && {
      fontWeight: '600',
      lineHeight: '1.5rem',
      color: muiPalette.grey?.[800],
    }),
  };

  const checkboxProps: CheckboxProps = {
    checked: header
      ? (pathsSelectionApi?.isAllSelected || pathsSelectionApi?.isPartiallySelected)
      : path && pathsSelectionApi?.selectedItems.has(path.id),
    indeterminate: header && pathsSelectionApi?.isPartiallySelected,
    onChange: () => header
      ? pathsSelectionApi?.onToggleAll()
      : path && pathsSelectionApi?.onToggleItem(path),
    disabled: checkboxDisabled,
    size: 'small',
    disableRipple: true,
  };

  const getPathValue = () => {
    try {
      const decodedPath = decodeURIComponent(path?.urlPath as string);
      return decodedPath;
    }
    catch {
      // skip URIError in case of malformed text
      return path?.urlPath;
    }
  };

  const selectedItems = Array.from(pathsSelectionApi.selectedItems.values());
  const markAllAsFP = selectedItems.filter(issue => issue.isFalsePositive).length !== pathsSelectionApi.selectedItems.size;

  return (
    <Stack
      direction='row' alignItems='center'
      ref={rowRef}
      sx={rowSx}
      onClick={onRowClick}
    >
      {!header && path?.isFalsePositive && <FalsePositiveMark />}
      <Stack maxWidth='1.3rem' width='100%' onClick={(e) => e.stopPropagation()}>
        <Checkbox {...checkboxProps}/>
      </Stack>
      <Box maxWidth='2rem' width='100%'>
        {header ? (
          <FalsePositiveMenu
            isFP={!markAllAsFP}
            request={{
              scan: scanId,
              kind: [kindId],
              path_method: selectedItems.map((i) => ({ path: i.urlPath, method: i.httpMethod })),
            }}
            disabled={!pathsSelectionApi.selectedItems.size}
            onAfterUpdate={() => pathsSelectionApi.reinitialize(new Map())}
          />
        ) : (
          <IconButton onClick={(e) => path && rowRef && onTogglePath?.(e, path.id, rowRef)} size='small'>
            {expanded ? <CaretUp size='16'/> : <CaretDown size='16'/>}
          </IconButton>
        )}
      </Box>
      <Box flexGrow='1' width='50%'>
        {header ? 'Path' : getPathValue()}
      </Box>
      <Box maxWidth='6rem' width='100%'>
        {header ? 'Method' : <UIHttpMethodBadge method={path?.httpMethod || ''} />}
      </Box>
    </Stack>
  );
};