import { InfiniteQueryObserverOptions, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as apiClient from 'api-client';
import { useApp } from '@shared/hooks';
import { AxiosError, AxiosResponse } from 'axios';
import { UsersQueryKeys } from './queriesKeys';
import { getNextPageParam, useFlatCountFromResponse, useFlatResults } from './utils';

const useGetUsersList = (
  request: apiClient.UserApiUserListRequest,
  config?: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedUserList>, AxiosError>>,
) => {
  const { usersApi } = useApp();
  const {
    data,
    isLoading = false,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage = false,
  } = useInfiniteQuery({
    queryKey: [UsersQueryKeys.usersList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => usersApi.userList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
    ...config,
  });

  const users = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    users,
    totalItemsCount,
    isUsersListLoading: isLoading || isFetchingNextPage,
    usersHasNextPage: hasNextPage,
    fetchNextUsersPage: fetchNextPage,
  };
};

const useGetUsersListMutation = () => {
  const { usersApi } = useApp();
  const {
    mutateAsync
  } = useMutation({
    mutationFn: (request: apiClient.UserApiUserListRequest) => usersApi.userList(request),
  });

  return {
    getUsersList: mutateAsync,
  };
};

const useCheckUsername = (signal?: AbortSignal) => {
  const { usersApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.UserApiUserCheckUsernameCreateRequest) =>
      usersApi.userCheckUsernameCreate(request, { signal: signal }),
    onSuccess: () => queryClient.resetQueries({ queryKey: [UsersQueryKeys.userByUsername] }),
  });

  return {
    checkUsername: mutateAsync,
    isLoading: isLoading,
  };
};

export {
  useGetUsersList,
  useGetUsersListMutation,
  useCheckUsername
};
