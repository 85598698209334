import { useFlags } from 'launchdarkly-react-client-sdk';

const useLaunchDarklyFlags = () => {
  const flags = useFlags();

  /** Note: for dev use 'true' inside 'Boolean' */

  return {
    showEngineIcon: Boolean(flags?.['show-engine-icon']),
    vulnerableShowcase: Boolean(flags?.['vulnerable-showcase']),
    scanPlanner: Boolean(flags?.['scan-planner']),
    newPathsTableWithCodes: Boolean(flags?.['new-scan-paths-table-with-response-codes']),
    inaccessibleUrlConfirmationModal: Boolean(flags?.['inaccessible-url-confirmation-modal']),
  };
};

export default useLaunchDarklyFlags;
