import * as Yup from 'yup';
import { globalTypes } from '@shared/duck';
import { User } from '@api-client';
import { matchIsValidTel } from 'mui-tel-input';

export const getInitialValues = (me?: User): globalTypes.ProfileFormValues => {
  return {
    username: me?.username || '',
    usernameExists: me?.username ? false : undefined,
    firstName: me?.first_name || '',
    lastName: me?.last_name || '',
    avatarUrl: me?.avatar_url || '',
    email: me?.email || '',
    phoneNumber: me?.phone_number,
    jobTitle: me?.job_title,
    companyName: me?.company_name,
    currentSolutionName: '',
  };
};

/** Note: USERNAME RULES:
 * 1. Only contains alphanumeric characters, underscore, dot and symbols: "@", "+", "-".
 * 2. Underscore and dot can't be at the end or start of a username (e.g _username / username_ / .username / username.).
 * 3. Underscore and dot can't be next to each other (e.g user_.name).
 * 4. Underscore or dot can't be used multiple times in a row (e.g user__name / user..name)
 * */

/** Note: NAME RULES:
 * Validates names from all countries by allowing a wide range of characters including letters, diacritics,
 * apostrophes, spaces, dots, and hyphens. The `u` flag ensures Unicode interpretation in JavaScript.
 * */

export const profileValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username should not be empty')
    .min(3, 'Username must be at least 3 characters')
    .max(100, 'Max 100 characters')
    .matches(
      /^(?!\d*$)[\w](?!.*?\.{2})[\w.-]{1,98}[\w]$/,
      'Invalid username',
    )
    .when('usernameExists', {
      is: true,
      then: schema => schema.test('exists', 'User with this username already exists', () => false),
    }),
  firstName: Yup.string()
    .required('First name should not be empty').trim()
    .max(100, 'Max 100 characters')
    .matches(/^[^@]*$/, 'Invalid First Name'),
  lastName: Yup.string()
    .required('Last name should not be empty').trim()
    .max(100, 'Max 100 characters')
    .matches(/^[^@]*$/, 'Invalid Last Name'),
  avatarUrl: Yup.string(),
  email: Yup.string().email().required('Email should not be empty'),
  usernameExists: Yup.boolean().oneOf([false], 'Username must be unique').required('Name must be tested'),
  phoneNumber: Yup.string().optional().test('validation', 'Invalid phone number', (phoneNumber?: string) => {
    return phoneNumber ? matchIsValidTel(phoneNumber) : true;
  }),
  jobTitle: Yup.string().optional().max(100, 'Max 100 characters'),
  companyName: Yup.string().optional().max(100, 'Max 100 characters'),
  currentSolutionName: Yup.string().optional().max(100, 'Max 100 characters'),
});