import {  IssuesOccurrencesResponse } from '@api-client';
import { Box, Stack } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

export interface OtherParametersTableProps {
  issues: IssuesOccurrencesResponse[];
  currentIssueId?: string;
  firstScrollToIssue: boolean;
  setFirstScrollToIssue: (value: boolean) => void;
  setCurrentIssueId: (issueId?: string) => void;
  isLastIssue: boolean;
}

export const OtherParametersTable: React.FC<OtherParametersTableProps> = ({
  issues,
  currentIssueId,
  firstScrollToIssue,
  setFirstScrollToIssue,
  setCurrentIssueId,
  isLastIssue,
}) => {
  return (
    <Box>
      {issues.map((issue, index) => {
        const selected = issue.id === currentIssueId;
        return (
          <Stack direction='row' alignItems='center' key={`${issue.id}-${index}`}
            sx={{
              borderBottom: index !== issues.length - 1 ? `1px solid ${muiPalette.grey?.[300]}` : 'none',
              p: '0.75rem',
              background: 'white',

              cursor: 'pointer',
              ...(selected && {
                background: muiPalette.blue?.[100],
              }),
              ...(!selected && {
                '&:hover': {
                  background: muiPalette.grey?.[100],
                }
              }),

              ...(isLastIssue && {
                border: '0 0 0.375rem 0.375rem',
              }),
            }}
            ref={(ref) => {
              if (selected && firstScrollToIssue && ref) {
                setFirstScrollToIssue(false);
                requestAnimationFrame(() => {
                  ref.scrollIntoView({ block: 'end', behavior: 'smooth' });
                });
              }
            }}
            onClick={() => setCurrentIssueId(issue.id)}
          >
            <Box maxWidth='2.3rem' width='100%'></Box>
            <Box maxWidth='2rem' width='100%'></Box>
            <Box maxWidth='10rem' width='100%'>
              {issue.parameter_name}
            </Box>
            <Box flexGrow='1' width='50%'>
              {issue.payload || 'N/A'}
            </Box>
          </Stack>
        );
      })}
    </Box>
  );
};