import { globalModels, globalQueries } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack';

export const useDeleteScanHandler = () => {
  const { deleteScan } = globalQueries.useDeleteScan();
  const onDeleteScan = async (id: string, name?: string) => {
    try {
      await deleteScan({ id });
      enqueueSnackbar(<><b>{name}</b> Scan has been deleted successfully</>, { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Failed to delete ${name} Scan`, { variant: 'error' });
    }
  };

  const { showModal } = useGlobalModalContext();

  const deleteScanHandler = async (scan: globalModels.ScansViewModelNew) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Scan?',
        deleteText: 'Are you sure you want to delete this Scan of',
        name: scan.targetName,
        onConfirm: () => onDeleteScan(scan.id, scan.targetName),
      },
    });
  };

  return { deleteScanHandler };
};
