import React from 'react';

// Configure Charts
import { globalUtils } from '@shared/duck';
globalUtils.initializeCharts();

// Configure dayjs plugins
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// Main React entrypoint
import { AppProvider } from '@app';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppProvider />
  </React.StrictMode>
  ,
);

// Configure Web Vitals
import reportWebVitals from './reportWebVitals';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Configure Datadog RUM
import { configureDatadogRum } from 'datadogRum';

configureDatadogRum();

// Buffer polyfill
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = window.Buffer || Buffer;
