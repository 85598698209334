import { GridItem } from '@contentful/f36-components';
import { styled } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const StyledGridItem = styled(GridItem)`
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  hyphens: auto;

  & > h6 {
    font-weight: 400;
    color: ${muiPalette.grey?.[500]}
  }

  & > a {
    white-space: nowrap;
  }
`;

export { StyledGridItem };