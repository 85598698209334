import { Heading, SubHeading, Image, Content } from './email-not-verified.styled';
import { useApp } from '@shared/hooks';
import { CONTACT_EMAIL } from '@shared/duck/constants';
import { Box, Link, Stack } from '@mui/material';

const EmailNotVerifiedPage = () => {
  const app = useApp();

  return (
    <Content>
      <Stack alignItems='flex-start' gap='3rem' flex='1 0 0'>
        <Box ml='5rem' mr='5rem'>
          <Heading>Please verify your email</Heading>
          <SubHeading>
            Verification link was sent to your address.
            <br/>
            <Link onClick={() => window.location.reload()}>
              {'Reload the page'}
            </Link>
            {' after you\'ve verified your email or '}
            <Link onClick={() => app.logout()}>
              {'Logout'}
            </Link>
            {' to switch account.'}
            <br/>
            <br/>
            {'If you can\'t find a verification link, contact us at '}
            <Link href={`mailto:${CONTACT_EMAIL}`}>
              {CONTACT_EMAIL}
            </Link>
            {' to solve this issue.'}
          </SubHeading>
        </Box>
      </Stack>
      <Image />
    </Content>
  );
};

export default EmailNotVerifiedPage;
