import React from 'react';

import { allRoutesEnum } from '@shared/duck';
import { ErrorContext } from '@shared/duck/contexts';
import { ErrorType } from '@shared/duck/contexts/error-context/duck/types';
import { useApp } from '@shared/hooks';

const Authenticator: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { setError } = React.useContext(ErrorContext);
  const app = useApp();

  // Workaround for React StrictMode to avoid double rendering
  const [isAfterFirstRender, setIsAfterFirstRender] = React.useState(false);
  React.useEffect(() => setIsAfterFirstRender(true), []);

  React.useEffect(() => {
    if (isAfterFirstRender) {
      // FIXME: handle public pages properly
      if (window.location.pathname.startsWith(allRoutesEnum.Error)) {
        // skip authentication for error page
      } else if (window.location.pathname.startsWith(allRoutesEnum.SSO)) {
        // skip authentication for SSO page
      } else {
        void app.authenticate((error) => {
          switch (error) {
            case 'userBlocked':
              setError?.({ type: ErrorType.BLOCKED_USER });
              break;
            case 'emailNotVerified':
              setError?.({ type: ErrorType.EMAIL_NOT_VERIFIED });
              break;
            default:
              ((x: never) => { throw new Error(`Unknown error type: ${x}`); })(error);
          }
        });
      }
    }
  }, [isAfterFirstRender]);

  return children;
};

export default Authenticator;
