import { Grid } from '@mui/material';
import { FormikControlMui, MuiTextInput } from '@shared/components';
import { FirstName, JobTitle, LastName, PhoneNumber, Username, SolutionNameInput } from './components';
import React from 'react';

const ProfileForm: React.FC = () => {
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <FormikControlMui
          name='username'
          label='Username'
          helpText={'Username can only use letters, numbers, hyphens, underscores and periods. It can\'t start/end with a period or a hyphen.'}
          required
        >
          <Username />
        </FormikControlMui>
      </Grid>

      <Grid item md={6} xs={12}>
        <FormikControlMui
          name='firstName'
          label='First Name'
          // helpText='Please use letters, spaces, hyphens, apostrophes or dots'
          required
        >
          <FirstName />
        </FormikControlMui>
      </Grid>

      <Grid item md={6} xs={12}>
        <FormikControlMui
          name='lastName'
          label='Last Name'
          // helpText='Please use letters, spaces, hyphens, apostrophes or dots'
          required
        >
          <LastName />
        </FormikControlMui>
      </Grid>

      <Grid item xs={12}>
        <FormikControlMui
          name='phoneNumber'
          label='Phone Number'
          optional
        >
          <PhoneNumber />
        </FormikControlMui>
      </Grid>

      <Grid item xs={12}>
        <FormikControlMui
          name='companyName'
          label='Company Name'
          optional
        >
          <MuiTextInput placeholder='Enter Company Name'/>
        </FormikControlMui>
      </Grid>

      <Grid item xs={12}>
        <FormikControlMui
          name='jobTitle'
          label='Job Title'
          optional
        >
          <JobTitle />
        </FormikControlMui>
      </Grid>

      <SolutionNameInput />
    </Grid>
  );
};

export default ProfileForm;