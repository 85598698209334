import React from 'react';
import { Description, Title } from '../../cli-commands.styled';
import { UICodeFragment } from '@shared/components';
import { SupportedLangs } from '@shared/duck/enums/global-enums';
import { globalEnums } from '@shared/duck';
import { CliCommandsContext } from '../../duck';

const UploadSpec: React.FC = () => {
  const {
    fileAttached,
    authenticationName,
    projectId,
    initTargetName,
    newTargetName, newTargetUrl,
    configuration,
    isTargetNameChanged, isTargetLocationChanged, isTargetUrlPatternsChanged, isTargetXPathsChanged,
    isNewTarget,
  } = React.useContext(CliCommandsContext);
  let step = 1;

  return (
    <Description variant='caption'>
      {isNewTarget && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'Let’s create a new target first.'}
          <UICodeFragment
            code={
              `# Create target\nnightvision target create ${newTargetName} ${newTargetUrl}` +
              (isTargetUrlPatternsChanged ? ` ${configuration?.excluded_url_patterns.join(' ')}` : '') +
              ` -P ${projectId} --type ${globalEnums.ECLITargetType.API}`
            }
            language={SupportedLangs.bash} />
        </>
      )}

      {!isNewTarget && (isTargetNameChanged || isTargetLocationChanged || isTargetUrlPatternsChanged || isTargetXPathsChanged) && !fileAttached ? (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'We have noticed that you made changes to the target; let\'s apply those first:'}
          <UICodeFragment
            code={
              `# Update target with swagger file\nnightvision target update ${initTargetName}` +
              `${isTargetNameChanged ? ` -n ${newTargetName}` : ''}` +
              `${isTargetLocationChanged ? ` -u ${newTargetUrl}` : ''}` +
              (isTargetUrlPatternsChanged ? ` ${configuration?.excluded_url_patterns.join(' ')}` : '') +
              ` -f $PATH_TO_SWAGGER_FILE -P ${projectId}`}
            language={SupportedLangs.bash}
          />
        </>
      ) : (
        <>
          <Title> {`Step ${step++}`}</Title>
          Upload Swagger file. Replace <i>$PATH_TO_SWAGGER_FILE</i> with the path to the Swagger file on your machine.
          <UICodeFragment code={`# Update target with swagger file\nnightvision target update ${isNewTarget ? newTargetName : initTargetName} -f $PATH_TO_SWAGGER_FILE -P ${projectId}`} language={SupportedLangs.bash} />
        </>
      )}

      <Title>{`Step ${step++}`}</Title>
      {'Run scan:'}
      <UICodeFragment code={`# Run scan\nnightvision scan ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} -P ${projectId} ${authenticationName ? `--auth ${authenticationName}` : ''}`} language={SupportedLangs.bash} />
    </Description >
  );
};

export default UploadSpec;