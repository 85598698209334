import React from 'react';
import { AutocompleteChangeReason, Grid } from '@mui/material';
import { DateFilter, TargetFilter } from './components';
import { ScanFiltersContext } from '../../filters-context';
import dayjs, { Dayjs } from 'dayjs';
import { UIOptionItemNew } from '../../scans-page-wrapper';
import { FilterName } from '../../duck';
import { ProjectFilterWrapper } from '@shared/components';

export const MainFilters: React.FC = () => {
  const { filterDictionary } = React.useContext(ScanFiltersContext);

  const projectIds = React.useMemo(() => {
    const projectFilterObj = filterDictionary[FilterName.PROJECT];
    const keyBy = filterDictionary[FilterName.PROJECT]?.keyBy;
    return projectFilterObj?.selected.map(p => keyBy?.(p) || '').filter(Boolean) || [];
  }, [filterDictionary[FilterName.PROJECT]?.selected]);

  const [isStartSateChecked, setStartDateChecked] = React.useState(false);
  const [isEndDateChecked, setEndDateChecked] = React.useState(false);

  const checkDate = (filterName: FilterName, valuesForCheck: UIOptionItemNew<string>) => {
    if (valuesForCheck) {
      try {
        const checkedDate = dayjs(valuesForCheck.value);
        if (!(checkedDate.toISOString() === valuesForCheck.value && checkedDate.format('DD MMM YYYY') === valuesForCheck.label)) {
          throw new Error();
        }
      }
      catch {
        filterDictionary[filterName]?.setSelected([], [valuesForCheck], 'removeOption');
      }
    }
  };

  React.useEffect(() => {
    const filterName = FilterName.STARTDATE;
    if (filterDictionary[filterName]?.selected.length && !isStartSateChecked) {
      setStartDateChecked(true);
      const startDateValues = filterDictionary[filterName]?.selected[0];
      checkDate(filterName, startDateValues);
    }
  }, [filterDictionary[FilterName.STARTDATE]?.selected]);

  React.useEffect(() => {
    const filterName = FilterName.ENDDATE;
    if (filterDictionary[filterName]?.selected.length && !isEndDateChecked) {
      setEndDateChecked(true);
      const endDateValues = filterDictionary[filterName]?.selected[0];
      checkDate(filterName, endDateValues);
    }
  }, [filterDictionary[FilterName.ENDDATE]?.selected]);

  const setNewDate = (filterName: FilterName, newValues: Dayjs | null, options?: Dayjs | null, action?: AutocompleteChangeReason) => {
    const option: UIOptionItemNew<string>[] =
      options ?
        [{ key: options.toISOString(), label: options.format('DD MMM YYYY'), value: options.toISOString() }]
        : [];
    const newValueItems: UIOptionItemNew<string>[] =
      newValues ?
        [{ key: newValues.toISOString(), label: newValues.format('DD MMM YYYY'), value: newValues.toISOString() }]
        : [];
    filterDictionary[filterName]?.setSelected(newValueItems, option, action);
  };

  const startDate = filterDictionary[FilterName.STARTDATE]?.selected.length ? dayjs(filterDictionary[FilterName.STARTDATE]?.selected[0].value) : null;
  const endDate = filterDictionary[FilterName.ENDDATE]?.selected.length ? dayjs(filterDictionary[FilterName.ENDDATE]?.selected[0].value) : null;
  return (
    <Grid container spacing={1}>
      <Grid item lg={3} md={4} xs={6}>
        <ProjectFilterWrapper
          selectedProjects={filterDictionary[FilterName.PROJECT]?.selected}
          setSelectedProjects={filterDictionary[FilterName.PROJECT]?.setSelected}
          setToSearchParams={false}
        />
      </Grid>

      <Grid item lg={3} md={4} xs={6}>
        <TargetFilter
          projectIds={projectIds}
          selectedTargets={filterDictionary[FilterName.TARGET]?.selected}
          setSelectedTargets={filterDictionary[FilterName.TARGET]?.setSelected}
        />
      </Grid>

      <Grid item lg={3} md={4} xs={6}>
        <DateFilter
          name='Start date'
          date={startDate}
          setDate={(newValues, options, action) => setNewDate(FilterName.STARTDATE, newValues, options, action)}
          maxDate={endDate || undefined}
        />
      </Grid>

      <Grid item lg={3} md={4} xs={6}>
        <DateFilter
          name='End date'
          date={endDate}
          setDate={(newValues, options, action) => setNewDate(FilterName.ENDDATE, newValues, options, action)}
          minDate={startDate || undefined}
        />
      </Grid>
    </Grid>
  );
};