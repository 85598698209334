import { makeAutoObservable, runInAction } from 'mobx';

import * as api from 'api-client';
import dayjs from 'dayjs';
import { globalConstants, globalEnums } from 'shared/duck';

export class RxUser {
  dto: api.User;
  firstLogin?: boolean;

  constructor(dto: api.User, firstLogin?: boolean) {
    this.dto = makeAutoObservable(Object.assign({}, dto));
    this.firstLogin = firstLogin;
    makeAutoObservable(this);
  }

  setDto(dto: api.User) {
    this.dto = makeAutoObservable(Object.assign({}, dto));
  }
}

export class RxSocialAuthInfo {
  dto: api.SocialAuthInfo;

  constructor(dto: api.SocialAuthInfo) {
    this.dto = makeAutoObservable(Object.assign({}, dto));
    makeAutoObservable(this);
  }
}

export interface BillingHeaders {
  plan?: string;
  status?: string;
  priceId?: string;
  endingDate?: string;
  pastDueDate?: string;
  latestInvoiceDate?: string;
  freeScansLimit?: string;
  scansPerformed?: string;
}

const FIRST_LOGIN_HEADER = 'x-first-login';

export class RxUsersModule {
  readonly api: api.UserApi;
  socialAuthInfo?: RxSocialAuthInfo;
  me?: RxUser;
  plan?: globalEnums.SubscriptionPlan;
  planStatus?: globalEnums.SubscriptionStatus;
  planPrice?: string;
  endingDate?: string;
  pastDueDate?: string;
  latestInvoiceDate?: string;
  // free trial scans limitation
  freeScansLimit?: number;
  scansPerformed?: number;

  constructor(api: api.UserApi) {
    this.api = api;
    this.socialAuthInfo = undefined;
    this.me = undefined;
    this.plan = undefined;
    this.planStatus = undefined;
    this.endingDate = undefined;
    this.pastDueDate = undefined;
    this.latestInvoiceDate = undefined;
    makeAutoObservable(this);
  }

  setBillingStatus(info: BillingHeaders) {
    this.plan = info.plan as globalEnums.SubscriptionPlan;
    this.planStatus = info.status as globalEnums.SubscriptionStatus;
    this.planPrice = info.priceId;
    this.endingDate = info.endingDate && dayjs(info.endingDate).format(globalConstants.FULL_MONTH_DATE);
    this.pastDueDate = info.pastDueDate && dayjs(info.pastDueDate).format(globalConstants.FULL_MONTH_DATE);
    this.latestInvoiceDate = info.latestInvoiceDate && dayjs(info.latestInvoiceDate).format(globalConstants.FULL_MONTH_DATE);

    // free trial scans limitation
    this.freeScansLimit = Number(info.freeScansLimit);
    this.scansPerformed = Number(info.scansPerformed);
  }

  async updateMe(userInfo: api.UserUpdateRequest): Promise<void> {
    const response = await this.api.userMeUpdateUpdate({ userUpdateRequest: userInfo });
    const firstLogin = response.headers[FIRST_LOGIN_HEADER];
    runInAction(() => {
      if (this.me) {
        this.me.setDto(response.data);
        this.me.firstLogin = firstLogin;
      }
    });
  }

  async fetchMeAndGetCsrfToken(): Promise<string | undefined> {
    const response = await this.api.userMeRetrieve({
      validateStatus: null, // treat 401 as non-error to grab response data
    });
    return runInAction((): string | undefined => {
      const data = response.data;
      const firstLogin = response.headers[FIRST_LOGIN_HEADER];
      let csrfToken = undefined;
      this.socialAuthInfo = new RxSocialAuthInfo(data.social_auth);
      if (response.status < 400) {
        this.me = new RxUser(data.user, firstLogin);
        csrfToken = data.csrf_token;
      } else {
        this.me = undefined;
      }
      return csrfToken;
    });
  }
}
