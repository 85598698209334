import { Button, Typography } from '@mui/material';
import { CaretDoubleDown } from '@phosphor-icons/react';
import { muiPalette } from '@shared/general-mui-theme';
import React from 'react';

export interface LoadMoreButtonProps {
  fetchPathsNextPage?: () => void;
}

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  fetchPathsNextPage,
}) => {
  return (
    <Button
      onClick={fetchPathsNextPage}
      sx={{
        p: '0.75rem',
        mt: '1rem',
        display: 'flex',
        textAlign: 'left',
        gap: '0.5rem',
        color: muiPalette.blue?.[600],
      }}
    >
      <CaretDoubleDown size={16} weight='bold'/>
      <Typography variant='subtitle2' color={muiPalette.blue?.[600]} flexGrow='1'>
        Load more results
      </Typography>
    </Button>
  );
};