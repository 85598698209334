import { TableRow } from '@contentful/f36-table';
import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

export const StyledGroupRow = styled(TableRow)`
  td {
    color: ${baseTokens.gray500};
    padding: ${baseTokens.spacingXs} ${baseTokens.spacingM};

    &:hover {
      cursor: default !important;
      background-color: ${baseTokens.colorWhite};
    }
  }
`;