import { Table } from '@contentful/f36-components';
import { UIMenu, tableTypes } from '@shared/components';
import { memoize } from 'lodash';
import React from 'react';
import { TableCell as StyledTableCell } from 'views/components/v1/Table/Table.styled';
import { UITableCell } from '../../../../../../../components';
import { globalEnums, globalTypes } from '@shared/duck';
import { StyledBodyRow } from './basic-body-row.styled';
import { Box, Checkbox } from '@mui/material';

interface BodyRowProps<DataItem> extends Partial<tableTypes.BasicTableProps<DataItem>> {
  rowKey: globalTypes.UID,
  dataItem: DataItem,
  columns: tableTypes.ColumnProps<DataItem>[];
  index: number,
}

const BasicBodyRow = <DataItem extends globalTypes.UIDItem>({
  rowKey,
  dataItem,
  columns,
  index,
  itemActions,
  onRowClick,
  bordered = false,
  itemSelection,
  selectedRowId,
}: BodyRowProps<DataItem>) => {
  const itemSelectionApi = itemSelection?.itemSelectionApi;
  const selectable = Boolean(itemSelectionApi);
  const isSelected = !!itemSelectionApi?.selectedItems.has(dataItem.id) || selectedRowId === dataItem.id;

  const getOnRowClick = React.useCallback(
    memoize((dataItem: DataItem) => () => {
      dataItem && onRowClick && onRowClick(dataItem);
    }), [onRowClick],
  );

  const getOnToggleCheckRow = React.useCallback(
    memoize((item: DataItem) => () => itemSelectionApi?.onToggleItem(item)),
    [itemSelectionApi],
  );

  return (
    <StyledBodyRow
      bordered={bordered}
      key={rowKey}
      isSelected={isSelected}
      onClick={getOnRowClick(dataItem)}
    >
      {selectable ? (
        <StyledTableCell small={globalEnums.EmotionBoolean.True}>
          <Box
            onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
          >
            <Checkbox
              onChange={getOnToggleCheckRow(dataItem)}
              checked={itemSelectionApi?.selectedItems.has(dataItem.id)}
              onClick={e => e.stopPropagation()}
              disableRipple
              size='small'
              sx={{ p: 0 }}
            />
          </Box>
        </StyledTableCell>
      ) : null}
      {columns.map((col, colIndex) => (
        <UITableCell
          key={`${index}${colIndex}`}
          dataItem={dataItem}
          column={col}
          index={index}
        />
      ))}

      {itemActions && (
        <Table.Cell
          onClick={(e: any) => e.stopPropagation()}
          style={{ verticalAlign: 'middle' }}
        >
          <UIMenu
            itemList={itemActions(dataItem).itemList}
            size='small'
            iconVariant={globalEnums.EMuiIconButtonVariants.DEFAULT}
          />
        </Table.Cell>
      )}
    </StyledBodyRow>
  );
};

export default BasicBodyRow;