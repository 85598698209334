import { Checkbox, CheckboxProps as BaseCheckboxProps } from '@contentful/f36-components';
import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

const StyledCheckbox = styled(Checkbox) <BaseCheckboxProps>`
  & label {
    font-weight: ${baseTokens.fontWeightNormal};
    display: flex;
    align-items: flex-start;
  }
`;

export { StyledCheckbox };
