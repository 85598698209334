import { Chip, Stack, Tooltip } from '@mui/material';
import React from 'react';

export interface FalsePositiveMarkProps {
  size?: 'small' | 'meduim';
  position?: 'relative' | 'absolute';
}

export const FalsePositiveMark: React.FC<FalsePositiveMarkProps> = ({
  size = 'small',
  position = 'absolute',
}) => {
  const isAbsolutPosition = position === 'absolute';
  const isSmallSize = size === 'small';

  return (
    <Tooltip title='Marked as False Positive'>
      <Stack
        sx={
          isAbsolutPosition ? {
            position: 'absolute',
            top: '0.1rem',
            left: '0.1rem',
          } : {}
        }
      >
        <Chip label='false positive' color='success'
          sx={isSmallSize ? {
            height: '1rem',
            '.MuiChip-label': {
            // p: '0 0.3rem',
              fontSize: '0.6rem'
            }
          } : {}
          }
        />
      </Stack>
    </Tooltip>
  );
};