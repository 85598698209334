import { Box, Button, Chip, InputBaseComponentProps, Stack, Tooltip } from '@mui/material';
import { MuiTextInput } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';

interface ExclusionProps extends InputBaseComponentProps {
  fieldName: string;
  placeholder: string;
  defaultExclusions?: string[];
}

export const Exclusion: React.FC<ExclusionProps> = ({
  fieldName,
  placeholder,
  defaultExclusions = [],
  ...props
}) => {
  const [isDeleted, setIsDeleted] = React.useState(false);
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = React.useState<string>('');
  const initExclusions = (values as any).configuration[fieldName];
  const exclusions: string[] = initExclusions.length || isDeleted ? initExclusions : defaultExclusions;

  React.useEffect(() => {
    setIsDeleted(false);
    setFieldValue(`configuration.${fieldName}`, exclusions);
  }, []);

  const onAddExclusion = () => {
    if (value.trim()) {
      setFieldValue(`configuration.${fieldName}`, [...exclusions, value]);
      setValue('');
    }
  };

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter' && value.trim()) {
      onAddExclusion();
    }
  };

  const onDeleteExclusion = (index: number) => {
    const items = [...exclusions];
    items.splice(index, 1);
    setFieldValue(`configuration.${fieldName}`, items);
    !items.length && setIsDeleted(true);
  };

  return (
    <>
      <Stack direction='row' gap='0.75rem'>
        <Box width='100%'>
          <MuiTextInput
            error={props.error}
            value={value}
            onChange={e => setValue(e.target.value)}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
          />
        </Box>
        <Button
          onClick={onAddExclusion}
          size='large'
          disabled={!value.trim()}
        >
          Add
        </Button>
      </Stack>
      {!!exclusions.length && (
        <Stack direction='row' flexWrap='wrap' mt='0.5rem'>
          {exclusions.map((el, index) => (
            <Tooltip key={`exclusion-${index}`} arrow title={el} enterDelay={500} enterNextDelay={500} placement='top'>
              <Chip label={el} key={`exclusion-${index}`} onDelete={() => onDeleteExclusion(index)}/>
            </Tooltip>
          ))}
        </Stack>
      )}
    </>
  );
};
