import { styled } from '@mui/material';
import { ModalContent } from '@shared/components/Modal';
import { muiPalette } from '@shared/general-mui-theme';

const StyledModalContent = styled(ModalContent)`
  p {
    white-space: pre-line;
  }

  a {
    color: ${muiPalette.blue?.[600]};
    cursor: pointer;

    &:hover {
      color: ${muiPalette.blue?.[700]};
    }
  }

  pre {
    background: #272822;
    padding: 0.75rem;
    border-radius: 0.25rem;

    code {
      color: ${muiPalette.green?.[200]};
    }
  }
`;

export { StyledModalContent };
