import { Message } from '@api-client';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React from 'react';
import { EBadgeVariants } from '../enums/global-enums';

export type UID = number | string;

export interface UIDItem {
  id: UID;
}

export enum SortingDirections {
  asc = 'asc',
  desc = 'desc',
}

export interface UIMenuItemsList {
  title: string,
  titleIcon?: JSX.Element,
  titleIconPosition?: 'right' | 'left',
  titleStackGap?: number,
  itemAction: (e?: any) => void,
  disabled?: boolean,
  tooltipText?: string,
  tooltipPlacement?: MuiTooltipPlacement,
  isRedText?: boolean,
  textColor?: string,
  closeAfterAction?: boolean,
  isHidden?: boolean,
}

export interface UIOptionItem<T = string | number> {
  key?: string | number;
  label: string;
  value: T;
}

export interface ScanLogItem extends Message, UIDItem {
  timestampFormatted: string;
}

export type CommonProps = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties | undefined
};

export type EmotionBooleanType = 1 | 0;

export interface UIBadgeProps {
  key?: string,
  variant: EBadgeVariants,
  text: string,
  sx?: SxProps<Theme>
}

export interface PageLayoutProps {
  className?: string;
}

export interface DefaultNameValue {
  key?: string,
  name: string,
  value: any
}

export type MuiTooltipPlacement =
  'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface MuiSelectOptions {
  key: string | number,
  label: string,
}
