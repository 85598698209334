import { MenuItem, styled, Typography } from '@mui/material';
import { withSafeProps } from 'views/utils/common';

interface StyledMenuItemTextProps {
  children: string,
  textColor?: string,
}

export const StyledMenuItemText = styled(withSafeProps<StyledMenuItemTextProps>(Typography, ['textColor']))`
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.00963rem;
    color: ${({ textColor }) => textColor};
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 0.75rem 1rem;
  align-items: center;

    &.Mui-disabled {
        opacity: 0.5;
    }
`;