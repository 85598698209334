import React from 'react';
import { useNavigate } from 'react-router-dom';

import { App, AppModelContext } from './app';

export const AppModelProvider: React.FC<React.PropsWithChildren> = (props) => {
  const navigate = useNavigate();
  const [app] = React.useState(() => new App(navigate));
  return (
    <AppModelContext.Provider value={app}>
      {props.children}
    </AppModelContext.Provider>
  );
};
