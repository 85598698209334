import { CopyIconC } from '@assets';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import Prism from 'prismjs';
import { globalEnums, globalTypes, globalUtils } from '@shared/duck';
import { initHighlightTextPlugin } from './duck';
import { StyledCodeWrapper } from './code-fragment.styled';

export const MAX_LENGTH_TO_HIGHLIGHT = 100_000;

export const CodeFragment: React.FC<Omit<globalTypes.CodeFragmentProps, 'useTabsForMultiline'>> = ({
  scrollProps,
  code,
  language,
  plugins = [],
  className,
  withCopy = true,
  dangerText = [],
  highlightLines,
  forceHighlight = false,
}) => {
  const containerRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (code.length < MAX_LENGTH_TO_HIGHLIGHT || forceHighlight) {
      if (language === globalEnums.SupportedLangs.evidence_markup) {
        initHighlightTextPlugin(dangerText);
      }
      Prism.highlightAll();
    }
  }, [code, language, dangerText.join(), highlightLines]);

  React.useEffect(() => {
    const dangerElement = containerRef.current?.querySelector('.token.danger');
    if (dangerElement) {
      /** Note: dirty huck! Disabled scroll for parent element while scroll in code*/
      if (scrollProps?.parentRef?.current) {
        scrollProps.parentRef.current.style.overflow = 'unset';
      }
      dangerElement.scrollIntoView({ block: 'start', inline: 'center' });
    }

    if (scrollProps?.parentRef?.current) {
      scrollProps.parentRef.current.style.overflow = 'auto';
      scrollProps.scrollToTop && scrollProps.parentRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [containerRef.current]);

  React.useEffect(() => {
    if (scrollProps) {
      if (containerRef.current?.querySelector('.token.danger')) {
        /** Note: dirty huck! Disabled scroll for parent element while scroll in code*/
        if (scrollProps?.parentRef?.current) {
          scrollProps.parentRef.current.style.overflow = 'unset';
        }
        containerRef.current?.querySelector('.token.danger')?.scrollIntoView({ block: 'start', inline: 'center' });
      }

      if (scrollProps?.parentRef?.current) {
        scrollProps.parentRef.current.style.overflow = 'auto';
        scrollProps.scrollToTop && scrollProps.parentRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [scrollProps]);

  const languageClass = language ? `language-${language}` : '';

  const removeCommentsFromCode = React.useCallback((): string => {
    const codeArray = code.split('\n');
    return codeArray.filter(line => !line.trim().startsWith('#') && line).join('\n');
  }, [code]);

  const codeWithoutComments = removeCommentsFromCode();

  const onCopyClick = (content: string) => {
    globalUtils.copyTextToClipboard(content, 'Copied to clipboard!');
  };


  return (
    <Box flexGrow='1'>
      <StyledCodeWrapper className={`Code ${className}`} ref={containerRef}>
        {withCopy &&
          <div className='copy'>
            <Tooltip
              arrow
              title={'Copy to clipboard'}
              placement='top'
              sx={{
                maxWidth: '10rem',
              }}
            >
              <IconButton
                aria-label='Copy'
                color='outlined'
                sx={{
                  width: '2rem',
                  height: '2rem',
                }}
                onClick={() => onCopyClick(codeWithoutComments)}
              >
                <CopyIconC
                  variant={globalEnums.CopyIconVariantsEnum.BLACK}
                />
              </IconButton>
            </Tooltip>
          </div>}

        <pre className={[languageClass, ...plugins].join(' ')} data-prompt='$' data-line={highlightLines}>
          <code className={languageClass} style={{ pointerEvents: 'none' }}>{code}</code>
        </pre>
      </StyledCodeWrapper>
    </Box>
  );
};