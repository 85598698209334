import { globalModels, globalQueries } from '@shared/duck';
import React from 'react';
import useItemSelection from '@shared/hooks/use-item-selection';
import { Issue } from '@api-client';
import { vulnPathsTableUtils } from '../vul-paths-table/duck';
import { PathsTable } from './paths-table';
import { ErrorContext, ErrorContextTypes } from '@shared/duck/contexts';
import { errorResponseHandler } from 'views/utils/errorHandlers';

export interface PathsTableWrapperProps {
  scanId: string;
  targetId: string;
  kindId: number;
  debouncedSearch?: string;
  currentIssue?: Issue;
  currentIssueId?: string;

  setCurrentIssueId: (issueId?: string) => void;
  firstScrollToIssue: boolean;
  setFirstScrollToIssue: (value: boolean) => void;
}

export const PathsTableWrapper: React.FC<PathsTableWrapperProps> = ({
  scanId,
  kindId,
  debouncedSearch,
  currentIssue,
  currentIssueId,
  ...props
}) => {
  const { setError } = React.useContext(ErrorContext);
  const [firstRequest, setFirstRequest] = React.useState(true);

  const {
    vulnerablePaths = [],
    fetchVulnerablePathsListNextPage,
    isFetchedAfterMount,
    isVulnerablePathsLoading,
    isFetching,
    vulnerablePathsListHasNextPage,
  } = globalQueries.useGetIssuesVulnerablePathsList({
    scan: scanId,
    kind: [kindId],
    filter: debouncedSearch,
  }, {
    onError: err => errorResponseHandler(err, 'issue', setError),
    onSettled(data) {
      firstRequest && !data?.pages[0].data.count && setError?.({
        type: ErrorContextTypes.ErrorType.NOT_FOUND,
        objectName: 'issue'
      });
      firstRequest && setFirstRequest(false);
    },
  });

  const vulnerablePathsViewModel = vulnerablePaths.map(vulnPathsTableUtils.mapDtoToViewModel);

  const pathsSelectionApi = useItemSelection<globalModels.VulnerablePathsViewModel>({
    data: vulnerablePathsViewModel,
    keyBy: item => item.id,
    labelBy: item => `${item.urlPath}: ${item.httpMethod}`,
  });

  React.useEffect(() => {
    const selectedItems = new Map(pathsSelectionApi.selectedItems);
    pathsSelectionApi.reinitializeData(vulnerablePathsViewModel);

    pathsSelectionApi.selectedItems.forEach((item) => {
      if (selectedItems.has(item.id) && !vulnerablePathsViewModel.find((issue) => issue.id === item.id)) {
        selectedItems.delete(item.id);
      }
    });
    pathsSelectionApi.reinitialize(selectedItems);
  }, [JSON.stringify(vulnerablePathsViewModel)]);

  const [expandedPaths, setExpandedPaths] = React.useState<Set<string>>(new Set());

  const togglePath = (id: string) => {
    const open = !expandedPaths.has(id);
    const newExpandedPaths = new Set(expandedPaths);
    if (open) {
      newExpandedPaths.add(id);
    } else {
      newExpandedPaths.delete(id);
    }
    setExpandedPaths(newExpandedPaths);
  };

  React.useEffect(() => {
    if (!currentIssueId && isFetchedAfterMount && vulnerablePathsViewModel.length && !expandedPaths.has(vulnerablePathsViewModel[0].id)) {
      togglePath(vulnerablePathsViewModel[0].id);
    }
  }, [isFetchedAfterMount, vulnerablePathsViewModel, currentIssueId]);

  const [initialPathSettled, setInitialPathSettled] = React.useState(false);
  React.useEffect(() => {
    if (currentIssue && !isFetching && vulnerablePathsViewModel.length && !initialPathSettled) {
      const path = vulnerablePathsViewModel.find(path => path.id === vulnPathsTableUtils.getVulnerablePathId(currentIssue));
      if (path) {
        !expandedPaths.has(path.id) && togglePath(path.id);
        setInitialPathSettled(true);
      }
      else {
        if (vulnerablePathsListHasNextPage) {
          fetchVulnerablePathsListNextPage();
        }
      }
    }
  }, [JSON.stringify(vulnerablePathsViewModel), currentIssue, vulnerablePathsListHasNextPage, isFetching]);

  return (
    <PathsTable
      vulnerablePaths={vulnerablePathsViewModel}
      isVulnerablePathsLoading={isVulnerablePathsLoading}
      isVulnerablePathsFetching={isFetching}
      vulnerablePathsListHasNextPage={vulnerablePathsListHasNextPage}
      fetchPathsNextPage={fetchVulnerablePathsListNextPage}
      scanId={scanId}
      kindId={kindId}
      pathsSelectionApi={pathsSelectionApi}
      expandedPaths={expandedPaths}
      togglePath={togglePath}
      currentIssue={currentIssue}
      currentIssueId={currentIssueId}
      {...props}
    />
  );
};