import PageTopBarAvatar from './header-avatar';
import React from 'react';
import { useMedia } from '@shared/hooks';
import { ActivePage, NotActivePageLink, StyledTopBar } from './header.styled';
import { allRoutesEnum, globalTypes } from '@shared/duck';
import { Breadcrumbs, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { BookOpen, CaretRight, List } from '@phosphor-icons/react';
import { headerUtils } from './duck';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Header: React.FC<globalTypes.CommonProps & { toggleDrawer: () => void }> = ({
  className,
  toggleDrawer,
}) => {
  const { isSmallScreen, isSxScreen } = useMedia();
  const location = useLocation();

  const pathNames = location.pathname.split('/').filter(Boolean);
  const title = headerUtils.getPageTitleByEntityName(pathNames[0]);

  return (
    <StyledTopBar
      className={className}
      container
      padding={isSmallScreen ? '0.5rem 1rem' : '0.5rem 2rem'}
    >
      <Grid item xs={9}>
        <Stack gap={0} flexDirection='row' alignItems='center'>
          {isSmallScreen &&
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                mr: 1
              }}
            >
              <List size={20} color='#67728A' />
            </IconButton>
          }

          <Stack gap={0} ml={isSmallScreen ? 0 : 0.5}>
            <Grid item xs={12}>
              <Typography variant='h6'>
                {title}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Breadcrumbs
                maxItems={isSxScreen ? 1 : (isSmallScreen ? 2 : undefined)}
                itemsBeforeCollapse={isSxScreen ? 0 : 1}
                separator={<CaretRight size={14} weight='bold' />}
                aria-label='breadcrumb'
              >
                {pathNames.map((_, index) => {
                  const last = index === pathNames.length - 1;
                  const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                  const breadcrumbName = headerUtils.findMatchedBreadcrumbName(to);

                  if (last) {
                    document.title = breadcrumbName ? `${breadcrumbName} | Nightvision` : 'Nightvision';
                  }

                  return breadcrumbName && (
                    last ? (
                      <ActivePage key={to}>
                        {breadcrumbName}
                      </ActivePage>
                    ) : (
                      <NotActivePageLink to={to} key={to} underline='hover'>
                        {breadcrumbName}
                      </NotActivePageLink>
                    )
                  );
                })}
              </Breadcrumbs>
            </Grid>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={3}>
        <Stack
          gap={1}
          flexDirection='row'
          justifyContent='flex-end'
        >
          <Stack gap={0} justifyContent='center'>
            {/* <StyledNotificationContainer>
             <IconButton
             icon={<BellIcon />}
             size='small'
             onClick={onNotificationsClick}
             aria-label='Notifications'
             title='Notifications'
             />
             {notification && <Asset src={Ellipse} className='ellipse'></Asset>}
             </StyledNotificationContainer> */}
            {!isSmallScreen ? (
              <Button
                size='small'
                target='_blank'
                startIcon={<BookOpen size={24} />}
                href={allRoutesEnum.Documentation}
              >
                {'Docs'}
              </Button>
            ) : (
              <IconButton
                href={allRoutesEnum.Documentation}
                target='_blank'
                aria-label='Documentation'
                title='Documentation'
              >
                <BookOpen size={24} />
              </IconButton>
            )}
            {/* <IconButton
             icon={<HelpIcon />}
             size='small'
             onClick={onHelpClick}
             aria-label='Help'
             title='Help'
             /> */}
          </Stack>
          <PageTopBarAvatar />
        </Stack>
      </Grid>
    </StyledTopBar >
  );
};

export default Header;
