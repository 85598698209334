import React from 'react';
import { BillingPlanInfoCard, BillingPlanItems, CardsList, BillingPlanCard, BillingPlanCardBestMark } from './billing-plan-cards.styled';
import { Check } from 'assets';
import { PaymentPlanButtonProps } from '@shared/duck/types';
import { useApp } from '@shared/hooks';
import baseTokens from '@contentful/f36-tokens';
import { allRoutesEnum, globalEnums, globalQueries } from '@shared/duck';
import { UIAsset } from '@shared/components';
import { CancelSubscriptionModal, UpgradePlanModal } from './components';
import useModal from '@shared/components/Modal/useModal';
import { Stack, Typography, Button } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';
import { muiPalette } from '@shared/general-mui-theme';
import { useQueryClient } from '@tanstack/react-query';
import { PaymentQueryKeys } from '@shared/duck/queries/queriesKeys';
import { useNavigate } from 'react-router-dom';

interface BillingPlanCardsProps {
  cards: PaymentPlanButtonProps[];
  setWaitForPlanStatusChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const BillingPlanCards: React.FC<BillingPlanCardsProps> = ({
  cards,
  setWaitForPlanStatusChange,
}: BillingPlanCardsProps) => {
  const app = useApp();
  const navigate = useNavigate();
  const upgradeModalApi = useModal<PaymentPlanButtonProps>();
  const cancelModalApi = useModal();
  const [professionalPlanId, setProfessionalPlanId] = React.useState('');
  const [isApiEnvyCard, setIsApiEnvyCard] = React.useState(false);
  const currentPlanStatus = app.users.planStatus;
  const currentPlan = app.users.plan;

  const isSubscriptionEnding = app.users.planStatus === globalEnums.SubscriptionStatus.ENDING;
  const subscriptionEndingDate = app.users.endingDate;
  const { cancelSubscription } = globalQueries.useCancelSubscription();

  const getOnClickHandler = (planCard: PaymentPlanButtonProps, isCurrent: boolean): (() => void) | undefined => {
    if (isCurrent) {
      if (isSubscriptionEnding) {
        return async () => {
          await app.openStripeCustomerPortal();
        };
      }
      else {
        return async () => {
          await cancelModalApi.openModal();
        };
      }
    }
    switch (planCard.plan) {
      case globalEnums.SubscriptionPlan.TRIAL:
        if (currentPlan === globalEnums.SubscriptionPlan.TRIAL) {
          return async () => {
            await cancelModalApi.openModal();
          };
        }
        return () => {
          app.openStripeFreeTrialCheckoutSession();
        };
      case globalEnums.SubscriptionPlan.PROFESSIONAL:
      case globalEnums.SubscriptionPlan.API_ENVY:
        if (currentPlan === globalEnums.SubscriptionPlan.TRIAL) {
          return () => {
            setIsApiEnvyCard(planCard.plan === globalEnums.SubscriptionPlan.API_ENVY);
            setProfessionalPlanId(planCard.priceId || '');
            upgradeModalApi.openModal();
          };
        }
        if (
          currentPlanStatus === globalEnums.SubscriptionStatus.PAST_DUE
          || currentPlanStatus === globalEnums.SubscriptionStatus.OVERDUE
        ) {
          return () => {
            app.openStripeCustomerPortal();
          };
        } else if (isCurrent) {
          return async () => {
            await cancelModalApi.openModal();
          };
        } else {
          return () => {
            planCard.priceId && app.openStripeCheckoutSession(planCard.priceId);
          };
        }
      default:
        return undefined;
    }
  };

  const onConfirmUpgrade = async () => {
    professionalPlanId && await app.openStripeCheckoutSession(professionalPlanId);
  };

  const [initUserPlanStatus, setInitUserPlanStatus] = React.useState(app.users.planStatus);
  const queryClient = useQueryClient();

  const onConfirmCancelSubscription = async () => {
    let triesLeft = 30;
    setWaitForPlanStatusChange(true);
    await cancelSubscription();
    while (--triesLeft > 0 && initUserPlanStatus === app.users.planStatus) {
      await new Promise(r => setTimeout(r, 5_000));
      queryClient.invalidateQueries({ queryKey: [PaymentQueryKeys.paymentPlansList] });
    }
    if (triesLeft >= 0 && initUserPlanStatus !== app.users.planStatus) {
      setWaitForPlanStatusChange(false);
      setInitUserPlanStatus(app.users.planStatus);
    }
    else {
      navigate(allRoutesEnum.Home);
    }
  };

  return (
    <CardsList>
      {cards.map(card => {
        const isTrial = currentPlan === globalEnums.SubscriptionPlan.TRIAL;
        const isCurrent = card.isCurrent || false;
        const isBest = card.isBest || false;

        const buttonColor =
          card.plan === globalEnums.SubscriptionPlan.TRIAL && currentPlanStatus === globalEnums.SubscriptionStatus.NONE
            ? globalEnums.EMuiButtonColors.SECONDARY
            : isCurrent
              ? currentPlanStatus === globalEnums.SubscriptionStatus.ENDING
                ? globalEnums.EMuiButtonColors.SUCCESS
                : globalEnums.EMuiButtonColors.ERROR
              : globalEnums.EMuiButtonColors.PRIMARY;
        const buttonVariant = buttonColor === globalEnums.EMuiButtonColors.PRIMARY
          ? globalEnums.EMuiButtonVariants.CONTAINED
          : globalEnums.EMuiButtonVariants.OUTLINED;

        const datePrefix = isCurrent
          ? (isTrial || isSubscriptionEnding)
            ? 'ends on'
            : 'next payment on'
          : '';

        return (
          <BillingPlanCard key={card.name} isBest={isBest} isCurrent={isCurrent} >
            {isBest && (
              <BillingPlanCardBestMark isCurrent={isCurrent} width='100%'>
                <Typography variant='subtitle1' fontWeight='600' p='0.5rem' color='#FFFFFF' textAlign='center'>Best Value</Typography>
              </BillingPlanCardBestMark>
            )}
            <BillingPlanInfoCard className='card' customStyles={{ noBodyPadding: true, active: isCurrent }}>
              <Stack gap='0.5rem' p='2rem'>
                <Stack direction='row' justifyContent='space-between' gap='0'>
                  <Typography variant='subtitle1' fontWeight='600' fontSize='1.25rem'>{card.name}</Typography>
                  {isCurrent && <CheckCircle size={25} color='green' weight='fill' />}
                </Stack>
                <Stack flexDirection='row' alignItems='center' gap='0'>
                  <Typography fontWeight='600' fontSize='1.25rem' lineHeight='2rem'>{card.monthly}</Typography>
                  {card.monthlySuffix && (
                    <Typography
                      fontSize='0.875rem' color={muiPalette.grey?.[500]}
                      ml='0.5rem'
                    >
                      {card.monthlySuffix}
                    </Typography>
                  )}
                </Stack>
                <Typography
                  variant='default' color={muiPalette.grey?.[500]}
                  mb='1rem'
                >
                  {card.yearly}
                </Typography>
                <Stack gap='0.25rem'>
                  <Button
                    color={buttonColor}
                    variant={buttonVariant}
                    disabled={card.disabled}
                    onClick={getOnClickHandler(card, isCurrent)}
                  >
                    {card.customButtonText || (isCurrent ? (isSubscriptionEnding ? 'Renew' : 'Cancel') : 'Select plan')}
                  </Button>
                  <Typography
                    variant='body2' lineHeight='1.25rem' color={muiPalette.grey?.[500]}
                    mb={datePrefix ? '-1.25rem' : '0'}
                  >
                    {datePrefix && `${datePrefix} ${subscriptionEndingDate}`}
                  </Typography>
                </Stack>
              </Stack>
              <BillingPlanItems p='2rem' gap='1rem'>
                <Typography variant='subtitle1' fontSize='1rem' fontWeight='600'>Includes</Typography>
                {card.includes.map(i => (
                  <Stack key={i} flexDirection='row' gap='0.75rem'>
                    <CheckMark />
                    <Typography variant='default'>{i}</Typography>
                  </Stack>
                ))}
              </BillingPlanItems>
            </BillingPlanInfoCard>
          </BillingPlanCard>
        );
      })}
      <UpgradePlanModal
        onConfirm={onConfirmUpgrade}
        isOpenModal={upgradeModalApi.isModalShown}
        closeModal={upgradeModalApi.closeModal}
        upgradeToApiEnvy={isApiEnvyCard}
      />
      <CancelSubscriptionModal
        subscriptionEndingDate={subscriptionEndingDate}
        onConfirm={onConfirmCancelSubscription}
        isOpenModal={cancelModalApi.isModalShown}
        closeModal={cancelModalApi.closeModal}
      />
    </CardsList >
  );
};

const CheckMark: React.FC = () => {
  return (
    <UIAsset
      style={{
        borderRadius: '50%',
        flexShrink: '0',
        padding: '0.1875rem',
        height: '1rem',
        width: '1rem',
        background: baseTokens.blue200,
      }}
      src={Check}
    />
  );
};

export default BillingPlanCards;
