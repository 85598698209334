import { IssuesOccurrencesResponse } from '@api-client';
import { Box, LinearProgress, Stack } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { ExpandButton, OtherParametersTable, ParameterRow } from './components';
import { muiPalette } from '@shared/general-mui-theme';
import { ItemSelectionApi } from '@shared/hooks/use-item-selection';
import { NoContentPlaceholder } from '@shared/components';
import { AttentionGray } from '@assets';

export interface ParameterTableProps {
  groupedIssues?: _.Dictionary<IssuesOccurrencesResponse[]>;
  isIssuesLoading: boolean;
  parentHeaderHeight: number;
  parameterSelectionApi: ItemSelectionApi<IssuesOccurrencesResponse>;
  expandedParameters: Set<string>,
  toggleParameter: (name: string) => void;
  firstScrollToIssue: boolean;
  setFirstScrollToIssue: (value: boolean) => void;
  setCurrentIssueId: (issueId?: string) => void;
  currentIssueId?: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  kindId: number;
  path: string;
  method: string;
  scanId: string;
}

export const ParameterTable: React.FC<ParameterTableProps> = ({
  groupedIssues,
  isIssuesLoading,
  parentHeaderHeight,
  parameterSelectionApi,
  expandedParameters,
  toggleParameter,
  firstScrollToIssue,
  setFirstScrollToIssue,
  setCurrentIssueId,
  currentIssueId,
  scrollRef,
  kindId,
  path,
  method,
  scanId,
}) => {
  const parameterHeaderRef = React.useRef<HTMLDivElement>(null);
  const totalParentHeight = parentHeaderHeight + (parameterHeaderRef.current?.clientHeight || 0);
  const parameters = groupedIssues && Object.keys(groupedIssues);
  const lastItemId = groupedIssues && parameters?.length ? groupedIssues[parameters.slice(-1)[0]].slice(-1)[0].id : '';

  const onToggleParameter = (e: any, paramName: string, currentRef: HTMLDivElement | null) => {
    e.stopPropagation();
    const stickyRect = currentRef?.getBoundingClientRect().top || 0;
    toggleParameter(paramName);

    // Waits for the browser to complete all pending layout and style calculations and
    // schedule the code to run just before the next repaint
    requestAnimationFrame(() => {
      const scrollDiff = (currentRef?.getBoundingClientRect().top || 0) - stickyRect;
      const scrollYAfter = scrollRef.current?.scrollTop || 0;
      scrollRef.current?.scrollTo(0, scrollYAfter + scrollDiff);
    });
  };

  return (
    <Box
      sx={{ wordBreak: 'break-word',
        borderBottom: `0.3rem solid ${muiPalette.grey?.[200]}`,
        borderRadius: '0.375rem',
      }}
    >
      {/* Main header */}
      <Stack
        gap='0'
        ref={parameterHeaderRef}
        sx={{ position: 'sticky', top: parentHeaderHeight, background: 'white', zIndex: '2',
          fontWeight: '600',
          fontSize: '0.85rem',
          lineHeight: '1.25rem', color: muiPalette.grey?.[800],
        }}
      >
        <Stack
          gap='0'
          sx={{
            border: `0.3rem solid ${muiPalette.grey?.[200]}`,
            borderBottom: 'none',
            borderTopLeftRadius: '0.375rem',
            borderTopRightRadius: '0.375rem',
            background: muiPalette.grey?.[200],
          }}
        >
          <ParameterRow
            header
            parameterSelectionApi={parameterSelectionApi}
            checkboxDisabled={groupedIssues ? !parameters?.length : true}
            kindId={kindId}
            path={path}
            method={method}
            scanId={scanId}
          />
          {isIssuesLoading && <LinearProgress />}
        </Stack>
      </Stack>

      {/* Main Content */}
      {!isIssuesLoading && (
        <Stack gap='0'
          sx={{
            borderRight: `0.3rem solid ${muiPalette.grey?.[200]}`,
            borderLeft: `0.3rem solid ${muiPalette.grey?.[200]}`,
            background: muiPalette.grey?.[200],
          }}
        >
          {groupedIssues && parameters?.length ? (
            <>
              {parameters.map((key, index) => {
                const issues = groupedIssues[key];
                const firstIssue = issues[0];
                const expanded = expandedParameters.has(firstIssue.parameter_name);
                const selected = firstIssue.id === currentIssueId;
                const isLastIssue = lastItemId === firstIssue.id;

                let headerRef: HTMLDivElement | null = null;
                const refCallback = (instance: HTMLDivElement | null) => {
                  if (instance) {
                    headerRef = instance;
                    if (selected && firstScrollToIssue) {
                      setFirstScrollToIssue(false);
                      requestAnimationFrame(() => {
                        instance.scrollIntoView({ block: 'center', behavior: 'smooth' });
                      });
                    }
                  }
                };

                return (
                  <Stack
                    key={`${key}-${index}`}
                    gap='0'
                    sx={{
                      borderBottom: index !== parameters.length - 1 ? `1px solid ${muiPalette.grey?.[300]}` : 'none',
                    }}>
                    <Stack
                      gap='0'
                      sx={{
                        position: 'sticky', top: totalParentHeight - 1,
                        background: muiPalette.grey?.[200],
                      }}
                      ref={refCallback}
                    >
                      <ParameterRow
                        parameterSelectionApi={parameterSelectionApi}
                        issue={firstIssue}
                        selected={selected}
                        onRowClick={() => setCurrentIssueId(firstIssue.id)}
                        isLastIssue={isLastIssue}
                        kindId={kindId}
                        path={path}
                        method={method}
                        scanId={scanId}
                      />

                      {/* Expand button */}
                      {issues.length > 1 &&  (
                        <ExpandButton
                          expanded={expanded}
                          onToggle={(e) => onToggleParameter(e, firstIssue.parameter_name, headerRef)}
                          issuesLength={issues.length - 1}
                        />
                      )}
                    </Stack>

                    {/* Nested table with other parameters */}
                    {expanded && (
                      <OtherParametersTable
                        issues={issues.slice(1)}
                        currentIssueId={currentIssueId}
                        firstScrollToIssue={firstScrollToIssue}
                        setFirstScrollToIssue={setFirstScrollToIssue}
                        setCurrentIssueId={setCurrentIssueId}
                        isLastIssue={isLastIssue}
                      />
                    )}
                  </Stack>
                );
              })}
            </>
          ) : (
            <NoContentPlaceholder message='No Issues found.' iconSrc={AttentionGray} />
          )}
        </Stack>
      )}
    </Box>
  );
};