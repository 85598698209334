import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

export const DotSizeVariants = ['small', 'medium'];
export type DotSize = typeof DotSizeVariants[number];

interface DotProps {
  color?: string;
  size?: DotSize;
}

const getBorderWidth = ({ size = 'medium' }: DotProps): string => (
  size === 'small' ? '3px' : '4px'
);
const getColor = ({ color = baseTokens.red500 }: DotProps): string => color;

const Dot = styled('div') <DotProps>`
  border-radius: 50%;
  border: ${getBorderWidth} solid ${getColor};
  background: ${getColor};
  width: 1px;
  height: 1px;
`;

export { Dot };
