import { Grid } from '@contentful/f36-components';
import { styled } from '@mui/material';
import { BreakpointX } from 'views/utils/design';

const ContentGrid = styled(Grid)`
  .header-section {
    grid-area: auto / span 4;
  }

  .controls-section {
    grid-area: auto / span 2;
  }

  .time-chart-section {
    grid-area: auto / span 6;
    max-height: 21.875rem;
  }

  .issue-count-section {
    grid-area: auto / span 3;
  }

  .recent-scans-section {
    grid-area: auto / span 6;
    min-height: 21.875rem;
  }

  @media (max-width: ${BreakpointX.Tablet.max}px) {
    .header-section {
      grid-area: auto / span 3;
    }

    .controls-section {
      grid-area: auto / span 3;
    }
  }

  @media (max-width: ${BreakpointX.Mobile.max}px) {
    .header-section {
      grid-area: auto / span 6;
    }

    .controls-section {
      grid-area: auto / span 6;
    }
  }

  @media (max-width: ${BreakpointX.Desktop.max}px) {
    .issue-count-section {
      grid-area: auto / span 6;
    }

    .recent-scans-section {
      grid-area: auto / span 6;
    }
  }
`;

export { ContentGrid };
