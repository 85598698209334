import React from 'react';
import { globalQueries, globalModels, globalUtils, allRoutesEnum } from '@shared/duck';
import { useNavigate } from 'react-router-dom';
import { useApp } from '@shared/hooks';
import { ErrorContext, MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { enqueueSnackbar } from 'notistack';

interface CollaboratorsTableApiResult {
  collaborators: globalModels.UserViewModel[],
  totalItemsCount: number,
  isCollaboratorsListLoading: boolean,
  collaboratorsHasNextPage: boolean,
  fetchNextCollaboratorsPage: () => void,
  onUnshareCollaboratorClick: (item: globalModels.UserViewModel) => void;
  isProjectOwned: boolean,
  projectOwnerId: string,
}

export const useCollaboratorsTableApi = ({
  page,
  projectId,
  search,
  pageSize,
}: {
  page: number;
  projectId: string;
  search?: string;
  pageSize?: number;
}): CollaboratorsTableApiResult => {
  const navigate = useNavigate();
  const { setError } = React.useContext(ErrorContext);

  const { project, isProjectLoading } = globalQueries.useGetProject({ id: projectId }, {
    onError: err => errorResponseHandler(err, 'project', setError),
  });

  const {
    users: { me },
  } = useApp();

  const {
    users: dtoCollaborators,
    totalItemsCount,
    isUsersListLoading,
    fetchNextUsersPage,
    usersHasNextPage = false,
  } = globalQueries.useGetUsersList({
    page: page + 1,
    project: [project?.id || ''],
    filter: search,
    pageSize: pageSize,
  }, { enabled: !!project?.id });

  const collaborators = React.useMemo(() =>
    dtoCollaborators.map(item => globalUtils.mapUserDtoToViewModel(item, me?.dto)
    ), [dtoCollaborators]
  );

  const isProjectOwned = project?.own_user.id === me?.dto.id;
  const projectOwnerId = project?.own_user.id || '';

  const { showModal } = useGlobalModalContext();

  const { unshareProject } = globalQueries.useUnshareProject();
  const onUnshareCollaboratorClick = async (user: globalModels.UserViewModel) => {
    const userName = `${user.firstName} ${user.lastName}`;
    const projectName = `${project?.name} project`;

    const onConfirmUnshare = async () => {
      try {
        await unshareProject({ id: project?.id || '', unshareProjectRequest: { users: [user.id] } });
        enqueueSnackbar(`${userName} has been removed successfully from ${project?.name} project`, { variant: 'success' });
        if (user.id === me?.dto.id) {
          navigate(allRoutesEnum.Projects);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(`Failed to remove ${userName} from ${project?.name} project`, { variant: 'error' });
      }
    };

    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Remove Collaborator?',
        onConfirm: onConfirmUnshare,
        deleteText: 'Are you sure you want to remove',
        name: userName,
        textAfterName: 'from',
        additionalName: projectName,
        warningText: '\n\nThey will no longer have access to associated targets, scans etc.',
        confirmButtonText: 'Remove',
      },
    });
  };

  return {
    collaborators,
    totalItemsCount,
    isCollaboratorsListLoading: isUsersListLoading || isProjectLoading,
    fetchNextCollaboratorsPage: fetchNextUsersPage,
    collaboratorsHasNextPage: usersHasNextPage,
    onUnshareCollaboratorClick,
    isProjectOwned,
    projectOwnerId,
  };
};

export default useCollaboratorsTableApi;
