import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { UIDialogTitle } from '@shared/components/dialog';
import { useGlobalModalContext } from '@shared/duck/contexts';
import React from 'react';

export interface DeleteModalProps {
  title: string;
  deleteText?: string;
  name?: string;
  textAfterName?: string;
  additionalName?: string;
  warningText?: string;
  confirmButtonText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const UIDeleteModal: React.FC<DeleteModalProps> = ({
  title,
  deleteText = 'Are you sure you want to delete',
  textAfterName = 'from your account',
  additionalName,
  warningText = '\n\nThis action is irreversible.',
  confirmButtonText = 'Delete',
  onConfirm,
  onCancel,
  name = '',
}) => {
  const [isShown, setIsShown] = React.useState<boolean>(true);
  const handleClose = (result: boolean) => {
    setIsShown(false);
    if (result) {
      onConfirm();
    }
    else {
      onCancel?.();
    }
  };

  const { hideModal } = useGlobalModalContext();

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      open={isShown}
      onClose={() => handleClose(false)}
      onTransitionExited={hideModal}
    >
      <UIDialogTitle
        title={title}
        onCloseBtnAction={() => handleClose(false)}
      />
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Typography
          variant='subtitle2'
          fontWeight={400}
          whiteSpace='pre-line'
          style={{ wordBreak: 'break-word' }}
        >
          {deleteText}
          {name && <b>{` ${name}`}</b>}
          {`${textAfterName ? ` ${textAfterName}` : ''}`}
          {additionalName && <b>{` ${additionalName}`}</b>}{'?'}
          {warningText}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pt: '0', gap: '0.5rem' }}>
        <Button
          autoFocus
          onClick={() => handleClose(false)}
          size='small'
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => handleClose(true)}
          size='small'
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UIDeleteModal;