import { styled } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';

export interface CustomStylesProps {
  noBodyPadding?: boolean;
  active?: boolean;
}

const CardWrapper = styled('div') <CustomStylesProps>`
  border-radius: ${baseTokens.borderRadiusMedium};
  border: ${({ active }) => (
    active ? `1px solid ${baseTokens.gray900}` : `1px solid ${baseTokens.gray200}`
  )};
  background: ${baseTokens.colorWhite};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .card-header {
    padding: ${baseTokens.spacingM};
    border-bottom: 1px solid ${baseTokens.gray200};
    box-sizing: border-box;
    height: 4.375rem;
  }

  & .card-body {
    flex-grow: 1;
    padding: ${({ noBodyPadding }) => (
      noBodyPadding ? 0 : baseTokens.spacingM
    )};
  }
`;

export { CardWrapper };
