import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { Check, DownloadSimple, Info, PencilSimpleLine, Trash } from '@phosphor-icons/react';
import { tableTypes } from '@shared/components';
import { globalEnums, globalModels, globalQueries, globalUtils } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { muiPalette } from '@shared/general-mui-theme';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

interface HarTableApiResult {
  harColumns: tableTypes.ColumnProps<globalModels.HarFileModel>[];
  harFiles: globalModels.HarFileModel[];
  totalItemsCount: number;
  isHarFilesListLoading: boolean;
}

const mapHarFileStatusToText = new Map<globalEnums.HAR_FILE_STATUS, string>([
  [globalEnums.HAR_FILE_STATUS.VALID, 'Valid'],
  [globalEnums.HAR_FILE_STATUS.WAITING_FOR_UPLOAD, 'Waiting for HAR file upload'],
  [globalEnums.HAR_FILE_STATUS.NO_FILE, 'HAR file is not specified'],
]);

const mapHarFileStatusToIcon = new Map<globalEnums.HAR_FILE_STATUS, React.ReactElement>([
  [globalEnums.HAR_FILE_STATUS.VALID, <Check size={16} key='valid' color={muiPalette.green?.[600]} style={{ flexShrink: 0 }}/>],
  [globalEnums.HAR_FILE_STATUS.WAITING_FOR_UPLOAD,  <CircularProgress size={16} key='waiting' sx={{ flexShrink: 0 }}/>],
  [globalEnums.HAR_FILE_STATUS.NO_FILE, <Info size={18} key='noFile' color={muiPalette.orange?.[400]} style={{ flexShrink: 0 }}/>],
]);

export const useHarTableApi = ({
  page,
  targetId,
  pageSize,
}: {
  page: number;
  targetId: string;
  pageSize?: number;
}): HarTableApiResult => {
  const {
    harFiles: dtoHarFiles,
    totalItemsCount,
    isHarListLoading,
  } = globalQueries.useGetHarFiles(
    {
      page: page + 1,
      id: targetId,
      pageSize: pageSize,
    },
  );

  const harFiles = React.useMemo(
    () =>
      dtoHarFiles?.map(
        (item) => globalUtils.mapHarFilesDtoToViewModel(item),
      ), [dtoHarFiles],
  );

  const { getHarDownloadUrl } = globalQueries.useGetHarDownloadUrl();
  const onGetDownloadUrl = async (harId: number) => {
    const { data } = await getHarDownloadUrl({ id: targetId, harFileId: harId });
    if (data.url) {
      window.location.href = data.url;
    }
    else {
      enqueueSnackbar('Error downloading HAR file', { variant: 'error' });
    }
  };

  const harColumns: tableTypes.ColumnProps<globalModels.HarFileModel>[] = [
    {
      title: 'Name',
      columnKey: 'name',
      minWidth: '10rem',
      cellInfo: {
        description: item => item.description,
      },
    },
    {
      title: 'Status',
      columnKey: 'status',
      cellInfo: {
        fullWidth: true,
      },
      customCellRenderer(value) {
        const text = mapHarFileStatusToText.get(value as globalEnums.HAR_FILE_STATUS);
        const Icon = mapHarFileStatusToIcon.get(value as globalEnums.HAR_FILE_STATUS);
        return (
          <Stack direction='row' alignItems='center'>
            <Typography variant='default'>
              {text}
            </Typography>

            {Icon}
          </Stack>
        );
      },
      minWidth: '7rem',
    },
    {
      title: '',
      columnKey: 'customField',
      minWidth: '2rem',
      customCellRenderer(_, item) {
        const [loading, setLoading] = React.useState(false);
        const downloadDisabled = item.status !== globalEnums.HAR_FILE_STATUS.VALID;

        const onDowload = async (id: number) => {
          setLoading(true);
          await onGetDownloadUrl(id);
          setLoading(false);
        };

        return (
          <Stack direction='row' alignItems='center' key={item.id}>
            <IconButton onClick={() => onEditHarFileInfo(item.id, item.name, item.description)} size='small'>
              <PencilSimpleLine />
            </IconButton>

            {loading
              ? <CircularProgress size={18} />
              : (
                <IconButton onClick={() => onDowload(item.id)} disabled={downloadDisabled} size='small'>
                  <DownloadSimple />
                </IconButton>
              )}

            <IconButton onClick={() => onDeleteHarFile(targetId, item.id, item.name)} size='small'>
              <Trash color={muiPalette.red?.[600]} />
            </IconButton>
          </Stack>
        );
      },
      cellInfo: {
        alignItems: 'right',
      }
    },
  ];

  const { deleteHarFile } = globalQueries.useDeleteHarFile();
  const { showModal } = useGlobalModalContext();

  const onDeleteHarFile = async (targetId: string, harFileId: number, name: string) => {
    const onConfirm = async () => {
      try {
        await deleteHarFile({ id: targetId, harFileId: harFileId });
        enqueueSnackbar(`${name} file has been deleted successfully`, { variant: 'success' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(`Failed to delete ${name} file`, { variant: 'error' });
      }
    };
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete HAR file?',
        onConfirm,
        deleteText: `Are you sure you want to delete ${name} HAR file`,
        textAfterName: '',
      },
    });
  };

  const onEditHarFileInfo = (harId: number, initialName: string, initialDesctiption?: string) => {
    showModal({
      modalType: MODAL_TYPE.EditHarInfoModal,
      modalProps: {
        targetId: targetId,
        harId: harId,
        initialName: initialName,
        initialDescription: initialDesctiption,
      },
    });
  };

  return {
    harColumns,
    harFiles,
    totalItemsCount: totalItemsCount,
    isHarFilesListLoading: isHarListLoading,
  };
};