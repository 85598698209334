import { User } from '@api-client';
import { Chip, Stack } from '@mui/material';
import { tableTypes, UIUserAvatar } from '@shared/components';
import { allRoutesEnum, globalEnums, globalModels, globalUtils } from '@shared/duck';
import React from 'react';

const getIconWrapper = (
  Children: React.ReactNode,
): React.ReactNode => {

  // eslint-disable-next-line react/display-name
  return (
    <Stack>
      {Children}
    </Stack>
  );
};

export const targetColumns: tableTypes.ColumnProps<globalModels.TargetViewModel>[] = [
  {
    title: 'Target',
    columnKey: 'name',
    cellInfo: {
      cellTextWeight: 600,
      getIcon(item) {
        return getIconWrapper(globalUtils.getTargetTypeIcon(
          item.type.toString()?.toUpperCase() as globalEnums.ETargetType,
        ));
      },
      description(item) {
        return item.location || '';
      },
      href(item) {
        return allRoutesEnum.ProjectTargetDetails.replace(':targetId', item.id).replace(':projectId', item.projectId);
      }
    },
    minWidth: '30rem',
  },
  {
    title: 'Accessibility',
    columnKey: 'internetAccessible',
    customCellRenderer(value) {
      const color = value === false ? 'primary' : 'secondary';
      const label = value === false ? 'Private' : 'Public';
      return (
        <Chip color={color} label={label} />
      );
    },
    minWidth: '10rem',
  },
  {
    title: 'Added By',
    columnKey: 'createdBy',
    customCellRenderer(value) {
      const owner = value as User;
      const fullName = `${owner.first_name} ${owner.last_name}`.trim();
      return (
        <UIUserAvatar
          src={owner.avatar_url || ''}
          fullName={fullName}
          withFullNameText
        />)
      ;
    },
    minWidth: '12rem',
  },
];