import React from 'react';
import { FormikControlMui, MuiTextInput, TargetExclusions, UITable } from '@shared/components';
import { globalModels } from '@shared/duck';
import { getTableProps, WebTargetsContentValues } from './duck';
import { Box, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { useFormikContext } from 'formik';

interface WebTargetsContentProps {
  project?: string;
  target?: globalModels.TargetViewModel;
}

export const WebTargetsContent: React.FC<WebTargetsContentProps> = ({
  project,
  target,
}) => {
  const { values } = useFormikContext<WebTargetsContentValues>();
  return (
    <Stack sx={{ height: '100%', maxHeight: '30rem', pt: !target ? '2rem' : '0' }} gap='0'>
      {values.firstPage ? (
        <>
          <Stack gap={0} mb={2}>
            <Typography
              color={muiPalette.blue?.[600]}
              variant='subtitle2'
              fontWeight={600}
            >
              STEP 1 OF 2
            </Typography>
            <Typography
              variant='subtitle1'
              lineHeight='2rem' fontSize='1.25rem'
              fontWeight={600}
            >
              Add URLs to scan
            </Typography>
          </Stack>
          <FormikControlMui name='stringUrl' label='Enter URL(s)'>
            <MuiTextInput multiline placeholder='Enter URLs, each separated by a line break' maxRows={3} minRows={3} />
          </FormikControlMui>
        </>
      ) : (
        <>
          {!target && (
            <Stack gap={0} mb={2}>
              <Typography
                color={muiPalette.blue?.[600]}
                variant='subtitle2'
                fontWeight={600}
              >
                STEP 2 OF 2
              </Typography>
              <Typography
                variant='subtitle1'
                lineHeight='2rem' fontSize='1.25rem'
                fontWeight={600}
              >
                Name each Target uniquely
              </Typography>
            </Stack>
          )}
          <UITable {...getTableProps(values, !!target, project)} />

          <Box sx={{ pt: '1.5rem' }}>
            <TargetExclusions targetsCount={values.targets.length} isCreateCommand={!target}/>
          </Box>
        </>
      )}
    </Stack>

  );
};