import ReactMarkdown from 'react-markdown';
import { Modal, ModalHeader } from '@shared/components/Modal';
import { Note } from '@contentful/f36-components';
import { StyledModalContent } from './IssueWriteupModal.styled';
import writeups from 'common/writeups';
import { globalEnums } from '@shared/duck';
import { Box, Typography } from '@mui/material';

export interface IssueWriteupModalProps {
  isShown: boolean;
  onClose: () => void;
  issueName: string;
}

function IssueWriteupModal({ issueName, isShown, onClose }: IssueWriteupModalProps) {
  const issueKey = Object.keys(writeups).find(title => title.toLowerCase() === issueName.toLowerCase());
  const content = (
    issueKey && (
      writeups as { [key: string]: string }
    )[issueKey]
  ) || '';
  const placeholder = (
    <Box m='1.5rem'>
      <Note variant={globalEnums.ENoteVariants.PRIMARY}>
        There is no writeup for this vulnerability yet.
        <br/>
        <Typography variant='default' fontWeight='500' mt='0.5rem'>
          Please let us know and we will make sure we add it!
        </Typography>
      </Note>
    </Box>
  );

  const onCancel = () => onClose();

  return (
    <Modal isShown={isShown} onClose={onCancel} size={content ? 'large' : 'medium'}>
      {() => (
        <>
          <ModalHeader title={issueKey || issueName} onClose={onCancel} />
          <StyledModalContent>{content ? <ReactMarkdown>{content}</ReactMarkdown> : placeholder}</StyledModalContent>
        </>
      )}
    </Modal>
  );
}

export default IssueWriteupModal;
