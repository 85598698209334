import React from 'react';
import { useFormikContext } from 'formik';
import { globalQueries } from '@shared/duck';
import { MuiTextInput } from '@shared/components';
import { AuthenticationFormValues } from '@shared/components/authentication-modal/authentication-modal';
import { useDebounce } from '@shared/hooks';
import { CanceledError } from 'axios';
import { CircularProgress, InputAdornment, InputBaseComponentProps } from '@mui/material';

interface UrlInputProps extends InputBaseComponentProps {
  inEdit: boolean;
}

export const UrlInput: React.FC<UrlInputProps> = ({
  inEdit,
  ...innerTextInputProps
}: UrlInputProps) => {
  const { values, setFieldValue, initialValues, errors } = useFormikContext<AuthenticationFormValues>();
  const { debouncedValue = values.playwrightUrl, onChange: onDebounceChange } = useDebounce<string>('', 1000);
  const [abortController, setAbortController] = React.useState(new AbortController());
  const { checkPublicUrl, isLoading } = globalQueries.useCheckPublicUrl(abortController.signal);
  const testUrl = async (url: string) => {
    try {
      const isValid = !errors.playwrightUrl?.toString().includes('Invalid format');
      if ((inEdit && initialValues.playwrightUrl === debouncedValue) || !isValid) return;

      const { data } = await checkPublicUrl(url);

      setFieldValue('playwrightUrl', data.requested_url);
      setFieldValue('playwrightUrlTested', true);
    }
    catch (e) {
      if (e instanceof CanceledError) {
        // canceled prevoius request
      }
    }
  };

  React.useEffect(() => {
    if (debouncedValue)
      testUrl(debouncedValue);
  }, [debouncedValue]);

  return (
    <MuiTextInput
      inputProps={innerTextInputProps}
      error={innerTextInputProps.error}
      size='small'
      placeholder='Enter name'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {isLoading
              ? <CircularProgress size={20} color='inherit' />
              : <></>}
          </InputAdornment>
        )
      }}
      onChange={(e) => {
        setFieldValue('playwrightUrlTested', false);
        abortController.abort();
        setAbortController(new AbortController());
        onDebounceChange(e.target.value);
        innerTextInputProps.onChange?.(e);
      }}
    />
  );
};
