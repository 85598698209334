import React from 'react';
import * as assets from '@assets';
import { CliCommandsLayout, ScanDialogLayout, FormData, formDataTypes } from '../components';
import { Grid, Stack } from '@mui/material';
import { ToolCard } from '../../../components';
import { CliCommands } from './components';
import { allRoutesEnum, globalQueries } from '@shared/duck';
import { Formik, FormikHelpers } from 'formik';
import { webUtils } from './duck';
import { useNavigate } from 'react-router-dom';
import { useMedia } from '@shared/hooks';
import { MuiContentLoader } from '@shared/components';

interface ScanWebProps {
  onCancel: () => void;
}

const ScanWeb: React.FC<ScanWebProps> = ({
  onCancel,
}) => {
  const navigate = useNavigate();

  const [cliFlow, setCliFlow] = React.useState(false);
  const [afterBackClick, setAfterBackClick] = React.useState(false);
  const [isOpenCliCommand, setOpenCliCommand] = React.useState(false);
  const [isUrlCheckLoading, setUrlCheckLoading] = React.useState(false);

  const { onCreateScan } = webUtils.onSubmit();

  const onSubmit = async (
    values: formDataTypes.FormDataFormValues,
    formikHelpers: FormikHelpers<formDataTypes.FormDataFormValues>,
  ) => {
    if (isOpenCliCommand) {
      navigate(allRoutesEnum.Scans);
    }
    if (cliFlow) {
      setOpenCliCommand(true);
      return;
    }
    await onCreateScan(values, formikHelpers, isOpenCliCommand);
  };

  const onBackClick = () => {
    cliFlow && setOpenCliCommand(false);
    setAfterBackClick(true);
  };

  const openCliFlow = () => setCliFlow(true);
  const closeCliFlow = () => setCliFlow(false);

  const { defaultProject = null, isDefaultProjectLoading } = globalQueries.useGetDefaultProject();

  const { isSmallScreen, isUp } = useMedia();
  const isLargeScreen = isUp('800');

  return (
    <MuiContentLoader
      variant='linear'
      isLoading={isDefaultProjectLoading}
    >
      <Formik<formDataTypes.FormDataFormValues>
        initialValues={{
          project: defaultProject || null,
          createNewTargetCheckbox: false,
          bypassUrlValidationCheckbox: false,
          newTargetName: '',
          baseUrl: '',
          nameExists: undefined,
          fileRequired: false,
          configuration: {
            excluded_url_patterns: [],
            excluded_x_paths: [],
          },
        }}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
        validationSchema={webUtils.scanWebValidationSchema(cliFlow)}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setFieldTouched, touched }) => {
          const isTargetNameChanged = !values.createNewTargetCheckbox && values.newTargetName && (
            values.target?.name !== values.newTargetName
          );
          const isTargetLocationChanged = !values.createNewTargetCheckbox && (
            values.target?.location !== values.baseUrl
          );
          const isExclusionsChanged = JSON.stringify(values.target?.configuration) !== JSON.stringify(values.configuration);
          const isOneCLiCommand = !isTargetNameChanged && !isTargetLocationChanged && !isExclusionsChanged;
          if (!touched.target && afterBackClick) {
            setAfterBackClick(false);
            setFieldTouched('target', true, false);
          }
          const isAccessible = values.isUrlAccessible;

          return (
            <ScanDialogLayout
              titleIcon={assets.WebSvg}
              title={'Scan Web Target'}
              onClose={onCancel}
              isOpenCli={cliFlow}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              disabled={!isValid || isUrlCheckLoading}
              isOpenCommandsPage={isOpenCliCommand}
              onBackClick={onBackClick}
              isUrlAccessible={isAccessible}
              openCliFlow={openCliFlow}
            >
              {!isOpenCliCommand ? (
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={2}
                >
                  <Grid item xs={isLargeScreen ? 4 : 12}>
                    <Stack flexDirection='column' spacing='1rem'>
                      <ToolCard
                        title='Web Portal'
                        description='Scan internet-accessible targets from NightVision Cloud.'
                        asset={assets.MonitorSvg}
                        action={closeCliFlow}
                        isActive={!cliFlow}
                      />
                      <ToolCard
                        title='CLI Helper'
                        description='Generate a NightVision CLI Command to scan targets on private networks.'
                        asset={assets.TerminalSvg}
                        action={openCliFlow}
                        isActive={cliFlow}
                      />
                    </Stack>
                  </Grid>
                  <Grid item
                    xs={isLargeScreen ? 8 : 12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingBottom: isSmallScreen ? '1rem' : '2rem'
                    }}>
                    <FormData
                      cliFlow={cliFlow}
                      openApi={false}
                      openCliFlow={openCliFlow}
                      isAccessible={isAccessible}
                      isUrlCheckLoading={isUrlCheckLoading}
                      setUrlCheckLoading={setUrlCheckLoading}
                    />
                  </Grid>
                </Grid>
              ) : (
                <CliCommandsLayout multipleCommands={!isOneCLiCommand}>
                  <CliCommands />
                </CliCommandsLayout>
              )}
            </ScanDialogLayout>
          );
        }}
      </Formik>
    </MuiContentLoader>
  );
};

export default ScanWeb;