import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppModelProvider } from './provider';

export const API_QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0, // default: 3
    },
  },
});

const AppContext = ({ children }: { children: React.ReactNode }) => (
  <AppModelProvider>
    <QueryClientProvider client={API_QUERY_CLIENT}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </AppModelProvider>
);

export default AppContext;
