import React from 'react';
import { MuiOpenApiTargetSelect } from '@shared/components';
import { useFormikContext } from 'formik';
import { formDataTypes } from '../../duck';
import { OpenApiTarget } from '@api-client';
import { globalEnums, globalQueries } from '@shared/duck';
import { IFormControlEvent } from 'views/utils/form';

interface OpenApiTargetSelectProps {
  readOnly?: boolean;
}

const OpenApiTargetSelect: React.FC<OpenApiTargetSelectProps> = ({
  readOnly = false,
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext<formDataTypes.FormDataFormValues>();
  const { getLastScan } = globalQueries.useGetLastScanMutation();

  const onFormChange = async (e: IFormControlEvent<OpenApiTarget | undefined>) => {
    setFieldValue('target', e.target.value);
    setFieldValue('isReadyToScan', e.target.value?.is_ready_to_scan);
    setFieldValue('specStatus', e.target.value?.spec_status);

    if (e.target.value && e.target.value?.id !== values.target?.id) {
      setFieldValue('baseUrl', e.target.value?.location || '');
    }
    setFieldValue('newTargetName', '');
    if (e.target.value) {
      const configuration = {
        excluded_url_patterns: e.target.value.configuration?.excluded_url_patterns || [],
        excluded_x_paths: e.target.value.configuration?.excluded_x_paths || [],
      };
      setFieldValue('configuration', configuration);

      const lastScanInfo = await getLastScan({ target: [e.target.value.id] }).then(res => res.data.results);
      if (lastScanInfo?.[0]) {
        const auth = lastScanInfo?.[0]?.credentials;
        setFieldValue('authentication', auth && auth?.is_deleted ? null : auth);
      }
    }
  };

  const onAfterTestName = ({ exists }: { exists?: boolean }) => {
    setFieldValue('nameExists', exists);
  };

  const setNewTargetName = (newName: string, e?: any) => {
    if (e?.key === globalEnums.EKeyboardKeys.ENTER || e?.type === 'blur') {
      if (!values.target && !!newName) {
        setFieldValue('enableTestCheckbox', true);
      }
    }
    if (newName !== values.newTargetName)
      setFieldValue('enableTestCheckbox', false);

    if (newName !== values.target?.name) {
      setFieldValue('newTargetName', newName);
    }
    else {
      setFieldValue('newTargetName', '');
    }
  };

  return (
    <MuiOpenApiTargetSelect
      {...props}
      isFreeSolo={!readOnly}
      label=''
      defaultTarget={values.target as OpenApiTarget}
      projectId={values.project?.id}
      onFormChange={onFormChange}
      defaultNewTargetName={readOnly ? values.target?.name : values.newTargetName}
      setNewTargetName={readOnly ? undefined : setNewTargetName}
      onAfterTestName={readOnly ? undefined : onAfterTestName}
      forceCheck={values.createNewTargetCheckbox}
    />
  );
};

export default OpenApiTargetSelect;