import React from 'react';
import { useLocation } from 'react-router-dom';
import { NoAccessPage, NotFoundPage, EmailNotVerifiedPage, BlockedUserPage } from '@pages';
import { ErrorContext, ErrorContextTypes } from '@shared/duck/contexts';

const GlobalErrorHandler: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [error, setError] = React.useState<ErrorContextTypes.ErrorDetails>();

  // Reset error on path change
  const location = useLocation();
  React.useLayoutEffect(() => {
    setError?.(undefined);
  }, [location.pathname]);

  if (error) {
    switch (error.type) {
      case ErrorContextTypes.ErrorType.NOT_FOUND:
        return <NotFoundPage />;
      case ErrorContextTypes.ErrorType.NO_ACCESS:
        return <NoAccessPage name={error.objectName} />;
      case ErrorContextTypes.ErrorType.EMAIL_NOT_VERIFIED:
        return <EmailNotVerifiedPage />;
      case ErrorContextTypes.ErrorType.BLOCKED_USER:
        return <BlockedUserPage />;
      default:
        ((x: never) => { throw new Error(`Unknown error type: ${x}`); })(error.type);
    }
  }

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      { children }
    </ErrorContext.Provider>
  );
};

export default GlobalErrorHandler;
