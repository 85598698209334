import { globalEnums, globalTypes } from '@shared/duck';
import { isEmpty } from 'lodash';
import babelParser from 'prettier/parser-babel';
import htmlParser from 'prettier/parser-html';
import prettier from 'prettier/standalone';
import xmlFormat from 'xml-formatter';

const formatJson = (payload: string): string => {
  let formattedPayload = payload;
  try {
    formattedPayload = JSON.stringify(JSON.parse(payload), null, 2);
  } catch (error) {
    console.warn('Unable to format JSON snippet', error);
  }
  return formattedPayload;
};

const formatBase64 = (payload: string): string => {
  let formattedPayload = payload;
  try {
    formattedPayload = atob(payload);
    return formatJson(formattedPayload);
  } catch (error) {
    console.warn('Unable to format base64 snippet', error);
  }
  return formattedPayload;
};

const formatCodeWithBabel = (payload: string, parserName: 'html' | 'babel'): string => {
  let formattedPayload = payload;
  const parser = parserName === 'html' ? htmlParser : babelParser;
  try {
    formattedPayload = prettier.format(payload, {
      parser: parserName,
      plugins: [parser],
    });
  } catch (error) {
    console.warn('Unable to format code snippet', error);
  }
  return formattedPayload;
};

const clearString = (str?: string) => {
  return str?.replace(/[\r\n\s]+$|^[\r\n\s]+/g, '');
};

function objectToYaml(obj: any, indentation = 0): string {
  let yamlString = '';
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      const indent = ' '.repeat(indentation);
      if (Array.isArray(element)) {
        yamlString += `${indent}${key}:\n`;
        for (const item of element) {
          yamlString += `${indent} `;
          if (typeof item === 'object') {
            yamlString += objectToYaml(item, indentation + 2);
          } else {
            yamlString += `${item}\n`;
          }
        }
      } else if (typeof element === 'object') {
        yamlString += `${indent}${key}:`;
        yamlString += objectToYaml(element, indentation + 2);
      } else {
        yamlString += `${indent}${key}: ${clearString(element)}\n`;
      }
    }
  }
  return yamlString;
}

const formatResponseContent = (text: string, type: string, encoding?: string) => {
  const contentType = globalEnums.SupportedLangs.html;
  let content = text;

  if (encoding?.includes('base64')) {
    content = formatBase64(text);
  }

  if (type?.includes('html')) {
    content = decodeHTMLEntities(content).replace(/^[\n\r\s\t]+/, '');
    return {
      content: formatCodeWithBabel(content, 'html'),
      contentType,
    };
  }

  if (type?.includes('json')) {
    return {
      content: formatJson(content),
      contentType: globalEnums.SupportedLangs.yaml,
    };
  }

  return { content, contentType };
};

function createResponseString(resp: { [key: string]: any }, body?: string): string {
  const responseList = [`${resp.httpVersion || ''} ${resp.status || ''} ${resp.statusText || ''}`];
  if (resp.headers) {
    for (const header of resp.headers) {
      responseList.push(`${header.name}: ${header.value}`);
    }
  }
  responseList.push('\n');
  if (body) {
    responseList.push(body);
  }
  return responseList.join('\n');
}

function createRequestString(req: { [key: string]: any }): string {
  const requestList = [];
  let urlPath = req.url ? new URL(req.url).pathname : '';

  const queryParam = req.url ? new URL(req.url).search : '';
  if (queryParam) {
    urlPath = `${urlPath}${queryParam}`;
  }
  requestList.push(`${req.method || ''} ${urlPath} ${req.httpVersion || ''}`);

  if (req.headers) {
    for (const header of req.headers) {
      header.value && requestList.push(`${header.name}: ${header.value}`);
    }
  }
  requestList.push('');
  const postDataParams = formatPostDataParams(
    req?.postData?.text || '',
    req?.postData?.params || [],
    req?.postData?.mimeType || '',
  ).content;
  requestList.push(postDataParams || '');

  return requestList.join('\n');
}

/**
 * See https://stackoverflow.com/a/7394787
 */
function decodeHTMLEntities(text: string) {
  const textArea = document.createElement('textarea');
  // Artificial HTML element outside DOM,
  // => skip semgrep finding:
  // nosemgrep: javascript.browser.security.insecure-document-method.insecure-document-method
  textArea.innerHTML = text;
  return textArea.value;
}

function encodeHTMLEntities(text: string) {
  const textArea = document.createElement('textarea');
  // Artificial HTML element outside DOM,
  // => skip semgrep finding:
  // nosemgrep: javascript.browser.security.insecure-document-method.insecure-document-method
  textArea.innerText = text;
  return textArea.innerHTML;
}

const pluralize = (word: string, number: number) => (
  number === 1 ? word : `${word}s`
);

const removeHttpFromUrl = (url: string) => {
  return url.replace(/http(s?):\/\//, '');
};

const formatPostDataParams = (text: string, params: any, type: string) => {
  const formatParams = params?.map((s: globalTypes.DefaultNameValue) => s.value ? `${s.name}: ${s.value}` : '')
    .filter((el: string) => !isEmpty(el)).join(' \n');

  let content = params && formatParams ? `Params:\n${formatParams}` : text || '';
  const contentType = globalEnums.SupportedLangs.evidence_markup;

  if (type.includes('x-www-form-urlencoded')) {
    content = text || `${params?.map((s: globalTypes.DefaultNameValue) => `${s.name}=${s.value}`).join('&')}`;

    return {
      content: content,
      contentType: contentType,
    };
  } else if (type.includes('xml')) {
    const requestContent = text || params?.map((s: globalTypes.DefaultNameValue) => `<${s.name}>${s.value}</${s.name}>`).join('');
    content = xmlFormat(`
    <request>
      ${requestContent}
    </request>`);

    return {
      content: content,
      contentType: contentType,
    };
  } else if (type.includes('json')) {
    const content = text || `{${params?.map((s: globalTypes.DefaultNameValue) => `"${s.name}":"${s.value}"`).join(',')}}`;
    return {
      content: formatJson(content),
      contentType: globalEnums.SupportedLangs.yaml,
    };
  } else {
    return { content, contentType };
  }
};

export {
  formatCodeWithBabel,
  objectToYaml,
  createRequestString,
  createResponseString,
  encodeHTMLEntities,
  decodeHTMLEntities,
  formatResponseContent,
  pluralize,
  removeHttpFromUrl,
  formatPostDataParams,
};
