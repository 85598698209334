import { ScanStatus } from '@api-client';
import { globalModels, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';

interface ScansTableApiResult {
  scans: globalModels.ScansViewModelNew[],
  totalItemsCount: number,
  isScansListLoading: boolean,
  scansHasNextPage: boolean,
  fetchNextScansPage: () => void,
  onScansRowClick?: (item: globalModels.ScansViewModelNew) => void,
}

export const useScansTableApi = ({
  project,
  filter,
  createdSince,
  createdBefore,
  authentication,
  target,
  accessibility,
  statuses,
  archived,
  createdBy,
  page,
  pageSize,
}:
{
  project?: string[];
  filter?: string;
  createdSince?: string;
  createdBefore?: string;
  target?: string[];
  authentication?: string[];
  accessibility?: number[];
  statuses?: ScanStatus[];
  archived?: boolean;
  createdBy?: string[];
  page: number;
  pageSize?: number;
}): ScansTableApiResult => {
  const REFETCH_INTERVAL = 10 * 1000;

  const {
    scans: dtoScans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage = false,
    fetchNextScansPage,
  } = globalQueries.useGetScansList(
    {
      filter: filter,
      createdSince: createdSince,
      createdBefore: createdBefore,
      project: project,
      credentials: authentication,
      target: target,
      internetAccessible: accessibility as Array<0 | 1>,
      status: statuses,
      archived: archived ? archived : undefined,
      createdBy: createdBy,
      page: page + 1,
      pageSize: pageSize,
    },
    { refetchInterval: REFETCH_INTERVAL },
  );

  const scans = React.useMemo(
    () =>
      dtoScans?.map(
        (item) => globalUtils.mapScansDtoToViewModelNew(item)) || [],
    [dtoScans],
  );

  return {
    scans,
    totalItemsCount,
    isScansListLoading,
    scansHasNextPage,
    fetchNextScansPage,
  };
};