import { CanceledError } from 'axios';
import { useApp } from '@shared/hooks';
import { globalUtils } from '..';

interface WaitForSubscriptionResponse {
  error?: string
  aborted?: boolean;
}

interface WaitForSubscriptionHandlerParams {
  maxAttemptCount?: number;
  probeIntervalMs?: number;
}

const ERROR_MESSAGE = 'Subscription update is still in progress. Please reload this page.';

export const useWaitForSubscriptionHandler = ({ maxAttemptCount = 10, probeIntervalMs = 3000 }: WaitForSubscriptionHandlerParams = {}) => {
  const app = useApp();
  const controller = new AbortController();
  globalUtils.useComponentWillUnmount(() => controller.abort());

  const waitForSubscription = async (
    updateStatus?: (hasSubscription: boolean) => void
  ): Promise<WaitForSubscriptionResponse> => {
    let count = maxAttemptCount;
    let lastHasSubscription = app.checkHasFullSubscription() || app.checkHasPayingSubscription();
    while (!lastHasSubscription && !controller.signal.aborted) {
      if (count == 0) {
        return { error: ERROR_MESSAGE };
      }
      count--;
      await new Promise((r) => setTimeout(r, probeIntervalMs));
      try {
        await app.users.fetchMeAndGetCsrfToken();
        lastHasSubscription = app.checkHasFullSubscription() || app.checkHasPayingSubscription();
        updateStatus?.(lastHasSubscription);
      }
      catch (e) {
        if (e instanceof CanceledError) {
          // canceled previous request
        }
      }
    }
    if (!lastHasSubscription && !controller.signal.aborted) {
      return ({ error: ERROR_MESSAGE });
    }
    else if (controller.signal.aborted) {
      return ({ aborted: true });
    }
    return {};
  };

  return {
    waitForSubscription: waitForSubscription,
  };
};
