import { TextFieldProps } from '@mui/material';
import { globalTypes } from '@shared/duck';
import { useFormikContext } from 'formik';
import React from 'react';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { useDebounce } from '@shared/hooks';

const PhoneNumber: React.FC<TextFieldProps> = ({
  ...props
}) => {
  const { values, setFieldValue, touched, setFieldTouched } = useFormikContext<globalTypes.ProfileFormValues>();
  const { debouncedValue = '', onChange: onDebounceChange, value = '' } = useDebounce<string>(values.phoneNumber);
  const inputRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (values.phoneNumber !== debouncedValue) {
      setFieldValue('phoneNumber', debouncedValue);
      if (!touched.phoneNumber) {
        setFieldTouched('phoneNumber', true, false);
      }
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (values.phoneNumber !== debouncedValue) {
      onDebounceChange(values.phoneNumber);
    }
  }, [values.phoneNumber]);

  const handleChange = (newPhone: string, info: MuiTelInputInfo) => {
    if (info.reason === 'country') {
      // clear number after selecting the new country code
      onDebounceChange(`+${info.countryCallingCode}`);

      if (inputRef.current) {
        // focus phone input after changing value state
        setTimeout(() => {
          inputRef.current?.focus();
        }, 1000);
      }
    }
    else {
      onDebounceChange(newPhone);
    }
  };

  return (
    <MuiTelInput
      error={props.error}
      onBlur={props.onBlur}
      name={props.name}
      value={value}
      onChange={handleChange}
      inputRef={inputRef}
    />
  );
};

export default PhoneNumber;