enum ScansQueryKeys {
  scansList = 'scansList',
  scanById = 'scanById',
  scansPathsList = 'scansPathsList',
  scansPathsListOld = 'scansPathsListOld',
  scanPlaywrightLoginStatus = 'scanPlaywrightLoginStatus',
  scanLogs = 'scanLogs',
  scanChecks = 'scanChecks',
  owaspLogsRetrieve = 'owaspLogsRetrieve'
}

enum CredentialQueryKeys {
  credentialsList = 'credentialsList',
  credentialsStatus = 'credentialsStatus',
  scansListByCredId = 'scansListByCredId',
}

enum IssuesQueryKeys {
  issuesList = 'issuesList',
  issueKindsList = 'issueKindsList',
  issuesVulnerablePathsList = 'issuesVulnerablePathsList',
  issueOccurrencesList = 'issueOccurrencesList',
  issueById = 'issueById',
}

enum IssueKindsQueryKeys {
  issueKindById = 'issueKindById',
  issueKindsList = 'issueKindsList',
}

enum IssueWriteupQueryKeys {
  issueWriteupsList = 'issueWriteupsList',
  issueWriteupsCountBySeverityList = 'issueWriteupsCountBySeverityList',
  issueWriteupsCountBySeverityAndDateList = 'issueWriteupsCountBySeverityAndDateList',
  issueWriteupsCountBySeverityHistory = 'issueWriteupsCountBySeverityHistory',
}

enum StatisticsQueryKeys {
  homeStatistics = 'homeStatistics',
  latestScansStatistics = 'latestScansStatistics',
}

enum EnumsQueries {
  enumsList = 'enumsList',
}

enum TargetsQueries {
  targetsList = 'targetsList',
  targetById = 'targetById',
  urlTargetById = 'urlTargetById',
  urlTargetsList = 'urlTargetsList',
  urlTargetPathsList = 'urlTargetPathsList',
  openApiTargetsList = 'openApiTargetsList',
  openApiTarget = 'openApiTarget',
  specUrlById = 'specUrlById',
  targetsAuthList = 'targetsAuthList',
  targetsAppList = 'targetsAppList',
  falsePositivesList = 'falsePositivesList',
  harFilesList = 'harFilesList',
}

enum ProjectsQueryKeys {
  projectsList = 'projectsList',
  projectsDefault = 'projectsDefault',
  projectById = 'projectById',
  targetByName = 'targetByName',
  projectByName = 'projectByName',
  authByName = 'authByName',
}

enum NucleiReposQueryKeys {
  nucleiRepositoriesList = 'nucleiRepositoriesList',
  nucleiRepository = 'nucleiRepository',
}

enum UsersQueryKeys {
  usersList = 'usersList',
  userByUsername = 'userByUsername',
}

enum PaymentQueryKeys {
  paymentPlansList = 'paymentPlansList',
}

enum AuthQueryKeys {
  tokensList = 'tokensList',
}

export {
  ScansQueryKeys,
  CredentialQueryKeys,
  IssuesQueryKeys,
  EnumsQueries,
  TargetsQueries,
  ProjectsQueryKeys,
  NucleiReposQueryKeys,
  UsersQueryKeys,
  IssueKindsQueryKeys,
  IssueWriteupQueryKeys,
  StatisticsQueryKeys,
  PaymentQueryKeys,
  AuthQueryKeys
};
