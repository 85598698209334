import { useQuery } from '@tanstack/react-query';
import * as apiClient from 'api-client';
import { useApp } from '@shared/hooks';
import { StatisticsQueryKeys } from './queriesKeys';
import lodash from 'lodash';

// APPLICATIONS-TODO
export type StatisticsProp = keyof Omit<apiClient.LatestScans, 'target_id' | 'application_id' | 'e_year' | 'e_month' | 'e_day'>;

export interface IssuesCountBySeverity {
  severity: apiClient.SeverityEnum;
  value: number;
}

export interface IssuesCountBySeverityAndDate {
  severity: apiClient.SeverityEnum;
  values: IssuesCountByDate[];
  hidden: boolean;
}

interface IssuesCountByDate {
  date: Date;
  value: number;
}

interface RequestParams extends Omit<apiClient.StatisticApiStatisticLatestScansDailyListRequest, 'since' | 'before'> {
  start: Date;
  end: Date;
}

interface GetIssuesCountBySeverityAndDateOptions {
  dates: Date[];
  statsProp: StatisticsProp;
  perMonth?: boolean;
  runningTotal?: boolean;
  refetchInterval?: number;
}

const useGetIssuesCountBySeverityInPeriodList = (
  request: RequestParams,
  options: GetIssuesCountBySeverityAndDateOptions,
) => {
  const { statisticsApi } = useApp();

  // Fetch stats for requested time period
  const apiRequest: apiClient.StatisticApiStatisticLatestScansDailyListRequest = {
    ...request,
    since: request.start.toISOString(),
    before: request.end.toISOString(),
  };
  const { data: statsResponse, isLoading: isDataLoading } = useQuery({
    queryKey: [StatisticsQueryKeys.homeStatistics, ...Object.values(request), options.dates, options.perMonth],
    queryFn: () => {
      if (options.perMonth) {
        return statisticsApi.statisticLatestScansMonthlyList({ ...apiRequest });
      }
      else {
        return statisticsApi.statisticLatestScansDailyList({ ...apiRequest });
      }
    },
    refetchInterval: options.refetchInterval,
  });

  const stats = statsResponse?.data || [];

  // Fetch previous historical values for Running Total
  const historyRequest: apiClient.StatisticApiStatisticLatestScansListRequest = {
    ...request,
    before: request.start.toISOString(),
    since: undefined, // track history from the very beginning
  };
  const { data: historyResponse, isLoading: isHistoryLoading } = useQuery({
    queryKey: [StatisticsQueryKeys.latestScansStatistics, ...Object.values(request), options.dates, options.perMonth],
    queryFn: () => statisticsApi.statisticLatestScansList({ ...historyRequest }),
    refetchInterval: options.refetchInterval,
    enabled: true, // always get history to smoothen chart switches
  });

  const history = historyResponse?.data || [];

  const groupedStats = groupByDate(stats, options.perMonth);
  const groupedStatsWithAllDates = fillGapsWithDates(groupedStats, options.dates);
  if (options.runningTotal) {
    propagateHistoricalValues(groupedStatsWithAllDates, history);
  }
  const resultStats = sumGroupedStats(groupedStatsWithAllDates, options.statsProp);

  const result: IssuesCountBySeverityAndDate[] = [
    getSeverityByDateCount({
      severity: apiClient.SeverityEnum.Critical,
      stats: resultStats,
      statsProp: options.statsProp,
    }),
    getSeverityByDateCount({
      severity: apiClient.SeverityEnum.High,
      stats: resultStats,
      statsProp: options.statsProp,
    }),
    getSeverityByDateCount({
      severity: apiClient.SeverityEnum.Medium,
      stats: resultStats,
      statsProp: options.statsProp,
      hidden: true,
    }),
    getSeverityByDateCount({
      severity: apiClient.SeverityEnum.Low,
      stats: resultStats,
      statsProp: options.statsProp,
      hidden: true,
    }),
    // getSeverityByDateCount({
    //   severity: SeverityEnum.Info,
    //   stats: summedStats,
    //   statsProp: options.statsProp,
    //   hidden: true,
    // }),
  ];

  return {
    uniqueIssuesCountBySeverityAndDate: result,
    isUniqueIssuesCountBySeverityAndDateLoading: isDataLoading || (
      options.runningTotal && isHistoryLoading
    ),
  };
};

export { useGetIssuesCountBySeverityInPeriodList };

// utils

function getValueOfDate(x: apiClient.LatestScans): string {
  // "month - 1", as in Date constructor they count months from 0, and we count from 1
  const result = new Date(x.e_year, x.e_month - 1, x.e_day).valueOf().toString();
  return result;
}

function groupByDate(stats: apiClient.LatestScans[], perMonth: boolean = false): ReadonlyMap<string, apiClient.LatestScans[]> {
  if (perMonth) {
    // Keep only Year and Month for grouping
    stats.forEach(s => s.e_day = 1);
  }
  const groupedResult = lodash.groupBy(stats, getValueOfDate);
  const result = new Map(Object.entries(groupedResult));
  return result;
}

interface GroupedStats {
  date: Date;
  items: apiClient.LatestScans[];
}

function fillGapsWithDates(groupedStats: ReadonlyMap<string, apiClient.LatestScans[]>, dates: Date[]): GroupedStats[] {
  return dates.map(
    date => {
      const stats = groupedStats.get(date.valueOf().toString());
      return ({
        date: date,
        items: stats || [],
      }) as GroupedStats;
    }
  );
}

function getTargetAppId(point: apiClient.LatestScans): string {
  const result = point.target_id;
  return result;
}

type StatsHistoryMap = Map<string, apiClient.LatestScans>;

/**
 * In place, propagate previous values for each unique Target
 * to the future dates, if they are not provided.
 *
 * (Instead of calculating a SUM of all previous values.)
 */
function propagateHistoricalValues(stats: GroupedStats[], history: apiClient.LatestScans[]): void {
  const statsHistory: StatsHistoryMap = new Map(history.map(h => [getTargetAppId(h), h]));
  for (const point of stats) {
    // add new items to history and override old values
    for (const item of point.items) {
      const pointId = getTargetAppId(item);
      statsHistory.set(pointId, item);
    }
    // use latest history as a new current point
    point.items = Array.from(statsHistory.values());
  }
}

type LatestScanStatsOnly = Pick<apiClient.LatestScans, StatisticsProp>;

interface LatestScanTotalStats extends LatestScanStatsOnly {
  date: Date;
}

function sumGroupedStats(stats: GroupedStats[], statsProp: StatisticsProp): LatestScanTotalStats[] {
  const result = stats.map(
    point => {
      const pointStatsSum = point.items.reduce(
        (total, current) => addStatistics(total, current, statsProp),
        {} as LatestScanStatsOnly
      );
      return ({
        ...pointStatsSum,
        date: point.date,
      });
    }
  );
  return result;
}

function addStatistics(resultPoint: LatestScanStatsOnly, current: LatestScanStatsOnly, prop: StatisticsProp) {
  const currentProp = current[prop];
  if (currentProp) {
    const resultPointProp = resultPoint[prop] || {};
    const availableKeys = Object.keys(currentProp);
    for (const key of availableKeys) {
      if (!(key in resultPointProp)) {
        resultPointProp[key] = 0;
      }
      resultPointProp[key] += currentProp[key];
    }
    resultPoint[prop] = resultPointProp;
  }
  return resultPoint;
}

const SeverityValueToLabel: Record<apiClient.SeverityEnum, string> = {
  [apiClient.SeverityEnum.Critical]: 'Critical',
  [apiClient.SeverityEnum.High]: 'High',
  [apiClient.SeverityEnum.Medium]: 'Medium',
  [apiClient.SeverityEnum.Low]: 'Low',
  [apiClient.SeverityEnum.Info]: 'Informational',
  [apiClient.SeverityEnum.Unknown]: 'Unknown',
  [apiClient.SeverityEnum.Unspecified]: 'Unspecified',
};

interface SeverityByDateParams {
  severity: apiClient.SeverityEnum;
  stats: LatestScanTotalStats[];
  statsProp: StatisticsProp;
  hidden?: boolean;
}

function getSeverityByDateCount({ stats, severity, statsProp, hidden = false }: SeverityByDateParams): IssuesCountBySeverityAndDate {
  const severityLabel = SeverityValueToLabel[severity];
  const values = stats
    .map(x => {
      const result: IssuesCountByDate = {
        date: x.date,
        value: 0,
      };
      const stat = x[statsProp];
      if (stat) {
        result.value += stat[severityLabel] || 0;
      }
      return result;
    });

  return {
    severity,
    values,
    hidden,
  };
}
