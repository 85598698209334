import { styled, css } from '@mui/material';
import baseTokens from '@contentful/f36-tokens';
import { globalEnums, globalTypes } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { withSafeProps } from 'views/utils/common';
import { UITextInput } from '@shared/components';

const StyledOption = styled('div')<{ isSelected?: boolean }>`
  & label {
    color: ${baseTokens.gray600};
  }

  color: ${baseTokens.gray600};
  padding: ${baseTokens.spacingXs} ${baseTokens.spacingM};
  ${({ isSelected = globalEnums.EmotionBoolean.False }) =>
    isSelected
      ? css`
          & label {
            font-weight: ${baseTokens.fontWeightDemiBold};
          }
          background: ${baseTokens.gray100};
        `
      : css``};

  &:hover {
    cursor: pointer;
    background: ${baseTokens.gray100};
  }
`;

interface StyledTextInputProps {
  isPlaceholder?: boolean;
  bordered?: boolean;
}

const StyledTextInput = styled(withSafeProps<StyledTextInputProps & globalTypes.UITextInputProps>(
  UITextInput, ['isPlaceholder', 'bordered']
))`
  border: ${({ bordered }) => bordered ? '' : 'transparent'};
  border-right: none;
  color: ${({ isPlaceholder }) => isPlaceholder ? `${muiPalette.grey?.[500]}` : ''};
`;

export { StyledOption, StyledTextInput };
