import { Issue } from '@api-client';
import React from 'react';
import { ActionsInfoMenu } from '..';
import {
  IssueCommonDetails,
  IssueValidationModal,
  RequestTab,
  ResponseTab,
} from './components';
import { ContentContainer } from '../../issue-details.styled';
import { globalUtils } from '@shared/duck';
import {
  FalsePositiveMark,
  MuiContentLoader,
} from '@shared/components';
import { Tooltip, Stack, Typography, IconButton, Button, ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { issueDetailsConstants } from '../../duck';
import { muiPalette } from '@shared/general-mui-theme';
import { Link } from '@phosphor-icons/react';
import { IssueResolutionEnum } from 'models/Enums';


interface InformationProps {
  currentIssue?: Issue;
}

const IssueInformation: React.FC<InformationProps> = ({
  currentIssue,
}) => {
  const [isRaw, setIsRaw] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = React.useState<issueDetailsConstants.MAIN_TAB>(issueDetailsConstants.MAIN_TAB.RESPONSE);

  const getIssueUrl = () => {
    const currLocation = new URL(location.href);
    currLocation.search = `?issueId=${currentIssue?.id}`;

    const responseTab = searchParams.get(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB);
    const requestTab = searchParams.get(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB);

    if (requestTab) {
      currLocation.searchParams.set(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB, requestTab || issueDetailsConstants.RESPONSE_TAB.RAW);
    }
    else {
      currLocation.searchParams.set(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB, responseTab || issueDetailsConstants.REQUEST_TAB.RAW);
    }
    return currLocation;
  };

  const onCopyIssueIdClick = () => {
    const issueUrl = getIssueUrl();
    globalUtils.copyTextToClipboard(issueUrl.toString(), 'Copied to clipboard!');
  };

  const onTabChange = (_: any, nextState: issueDetailsConstants.MAIN_TAB) => {
    setSearchParams((urlParams) => {
      if (nextState === issueDetailsConstants.MAIN_TAB.REQUEST) {
        urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB, issueDetailsConstants.REQUEST_TAB.RAW);
        urlParams.delete(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB);
      }
      else if (nextState === issueDetailsConstants.MAIN_TAB.RESPONSE) {
        urlParams.set(issueDetailsConstants.SEARCH_TAB_NAME.RESPONSE_TAB, issueDetailsConstants.RESPONSE_TAB.RAW);
        urlParams.delete(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB);
      }
      return urlParams;
    });
    setFirstIssueOpen(false);
  };

  React.useEffect(() => {
    const requestTab = searchParams.get(issueDetailsConstants.SEARCH_TAB_NAME.REQUEST_TAB);
    setCurrentTab(requestTab ? issueDetailsConstants.MAIN_TAB.REQUEST : issueDetailsConstants.MAIN_TAB.RESPONSE);
  }, [searchParams]);

  const [openValidateModal, setOpenValidateModal] = React.useState(false);

  const parentRef = React.createRef<HTMLDivElement>();
  const [isFirstIssueOpen, setFirstIssueOpen] = React.useState(true);

  React.useEffect(() => {
    setFirstIssueOpen(true);
  }, [currentIssue]);

  const isFp = currentIssue?.resolution === IssueResolutionEnum.falsePositive;

  return (
    <MuiContentLoader
      isLoading={currentIssue === undefined}
    >
      <Stack gap='0' height='100%'>
        <Stack
          direction='row' justifyContent='space-between'
          sx={{
            borderBottom: `1px solid ${muiPalette.grey?.[300]}`,
            p: '0.87rem',
          }}
        >
          <Stack direction='row' alignItems='center' minWidth='5.5rem' gap='0.25rem'>
            <Typography variant='default' fontWeight='500' color={muiPalette.grey?.[900]} noWrap>
              Copy Issue link
            </Typography>

            <Tooltip
              arrow
              title={getIssueUrl().toString()}
              placement='top'
            >
              <IconButton
                size='small'
                aria-label='Copy shared URL'
                onClick={onCopyIssueIdClick}
                sx={{ ml: '0.25rem' }}
              >
                <Link size={20} color={muiPalette.grey?.[400]} />
              </IconButton>
            </Tooltip>

            {isFp && <FalsePositiveMark position='relative' size='meduim'/>}
          </Stack>
          <Stack direction='row'>
            <Button
              onClick={() => setOpenValidateModal(true)}
              variant='contained'
            >
              Validate
            </Button>

            <ActionsInfoMenu
              issue={currentIssue}
              isRaw={isRaw}
              setIsRaw={setIsRaw}
            />
          </Stack>
        </Stack>
        <ContentContainer>
          <Box m='1rem' ref={parentRef}>
            <IssueCommonDetails issue={currentIssue} />

            <Box mb='1.5rem'>
              <ToggleButtonGroup
                color='primary'
                value={currentTab}
                exclusive
                onChange={onTabChange}
              >
                <ToggleButton value={issueDetailsConstants.MAIN_TAB.REQUEST}>Request</ToggleButton>
                <ToggleButton value={issueDetailsConstants.MAIN_TAB.RESPONSE}>Response</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {currentTab === issueDetailsConstants.MAIN_TAB.REQUEST ? (
              <RequestTab issue={currentIssue} isRaw={isRaw} />
            ) : (
              <ResponseTab parentRef={parentRef} issue={currentIssue} isRaw={isRaw} isFirstIssueOpen={isFirstIssueOpen} />
            )}
          </Box>
        </ContentContainer>
      </Stack>
      <IssueValidationModal
        openModal={openValidateModal}
        closeModal={() => setOpenValidateModal(false)}
        issue={currentIssue}
      />
    </MuiContentLoader>
  );
};

export default IssueInformation;
