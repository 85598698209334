import { User } from '@api-client';
import {
  DialogContent,
  Button, Dialog, DialogActions, TextField, Tooltip,
} from '@mui/material';
import { UIDialogTitle } from '@shared/components';
import { useDebounce } from '@shared/hooks';
import { AccessList, AutocompleteStyled, UserCard } from './components';
import { ContentContainer } from './share-project-modal.styled';
import { allRoutesEnum, globalQueries, globalUtils } from '@shared/duck';
import React from 'react';
import { useGlobalModalContext } from '@shared/duck/contexts';

const MIN_INPUT_LENGTH = 3;

export interface ShareProjectModalProps {
  collaborators: User[];
  projectId: string;
  scanId?: string;
}

export const ShareProjectModal: React.FC<ShareProjectModalProps> = ({
  collaborators,
  projectId,
  scanId,
}) => {
  const [isAutocompleteOpen, setIsAutocompleteOpen] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<User[]>([]);
  const [options, setOptions] = React.useState<User[]>([]);
  const [selectedUsernames, setSelectedUsernames] = React.useState<string[]>([]);
  const { shareProjectSubmit } = globalUtils.useShareProjectHandlerNew();
  const { debouncedValue: debouncedSearch, onChange: onSearchChange, value: inputValue = '' } = useDebounce<string>();

  const {
    users = [],
    isUsersListLoading,
    usersHasNextPage,
    fetchNextUsersPage,
  } = globalQueries.useGetUsersList({
    filter: debouncedSearch,
    isActive: true,
  }, {
    enabled: !!debouncedSearch && debouncedSearch?.length >= MIN_INPUT_LENGTH,
  });

  const sharedWithUsersSorted = collaborators.sort((
    a,
    b,
  ) => a.first_name.localeCompare(b.first_name));

  const usersWithoutShared = React.useMemo(() => users.filter(user =>
    !sharedWithUsersSorted.some(sharedUser => sharedUser.id === user.id)
  ), [users, sharedWithUsersSorted]);

  React.useEffect(() => {
    if (usersHasNextPage) {
      fetchNextUsersPage();
    }
  }, [usersHasNextPage, fetchNextUsersPage]);

  React.useEffect(() => {
    const allUsers: User[] = [];
    if (selectedUsers.length) {
      allUsers.push(...selectedUsers);

      const newUsers = usersWithoutShared.filter((user) => {
        return !selectedUsers.some(selectedUser => selectedUser.id === user.id);
      });

      newUsers.forEach((newUser) => {
        allUsers.push(newUser);
      });

      setOptions(allUsers);
    } else {
      setOptions(usersWithoutShared);
    }
  }, [usersWithoutShared]);

  React.useEffect(() => {
    const usernames: string[] = [];
    selectedUsers.map((value: User) => {
      usernames.push(value.username);
    });
    setSelectedUsernames(usernames);
  }, [selectedUsers]);

  const handleAutocompleteOpen = () => {
    if (inputValue.length >= MIN_INPUT_LENGTH) {
      setIsAutocompleteOpen(true);
    }
  };

  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => {
    setSelectedUsers([]);
    onSearchChange('');
    setIsOpen(false);
  };

  const handleInputChange = (value: string) => {
    onSearchChange(value);
    if (value.length >= MIN_INPUT_LENGTH) {
      setIsAutocompleteOpen(true);
    } else {
      setIsAutocompleteOpen(false);
    }
  };

  const handleOnChange = (tags: User[]) => {
    setSelectedUsers(tags);
  };

  const handleOptionLabel = (option: User): string => {
    if (selectedUsers.find((user) => option.id === user.id)) {
      // Final label for selected option
      return `${option.first_name} ${option.last_name} (${option.username})`;
    } else {
      // Mui Autocomplete creates it's own filtered options based on provided option label
      // If we want to filter users by another filters -> we need to include this field here
      return `${option.first_name} ${option.last_name} ${option.email} ${option.username}`;
    }
  };

  const onCopyScanUrlClick = () => {
    if (scanId) {
      const url = location.origin + `${allRoutesEnum.ScanDetails.replace(':scanId', scanId)}`;
      globalUtils.copyTextToClipboard(url, 'Copied to clipboard!');
    }
  };

  const onShareProject = async () => {
    await shareProjectSubmit(selectedUsernames, projectId, handleClose);
    setSelectedUsernames([]);
  };

  const { hideModal } = useGlobalModalContext();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      onTransitionExited={hideModal}
      fullWidth
      sx={{
        '.MuiPaper-root': {
          maxWidth: '25rem',
        }
      }}
    >
      <UIDialogTitle
        title='Share project'
        onCloseBtnAction={handleClose}
      />

      <DialogContent
        dividers
      >
        <ContentContainer
          sx={{ paddingBottom: '1.75rem' }}
        >
          <AutocompleteStyled
            id='multiple-limit-tags'
            open={isAutocompleteOpen}
            onOpen={handleAutocompleteOpen}
            label={'Share project with:'}
            inputValue={inputValue}
            onInputChange={(_, value) => handleInputChange(value)}
            onClose={() => setIsAutocompleteOpen(false)}
            onChange={(event: any, tags: any) => handleOnChange(tags as User[])}
            loading={isUsersListLoading}
            loadingText='Loading...'
            hidePopupIndicator
            multiple
            autoComplete
            autoHighlight
            filterSelectedOptions
            limitTags={4}
            options={options}
            noOptionsText='User not found'
            getOptionLabel={(option: any) => handleOptionLabel(option as User)}
            renderOption={(props, option) => (
              <UserCard user={option as User} {...props} />
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder='Email, username, or name' />
            )}
          />
        </ContentContainer>

        <AccessList
          sharedUsers={sharedWithUsersSorted}
        />
      </DialogContent>

      <DialogActions
        sx={{ justifyContent: 'space-between' }}
      >
        {scanId && (
          <Button
            variant='text'
            onClick={onCopyScanUrlClick}
          >
            {'Copy link to scan'}
          </Button>
        )}

        <DialogActions
          sx={{
            padding: 'initial',
            marginLeft: `${scanId ? '' : 'auto'}`,
          }}
        >
          <Button
            variant={'outlined'}
            onClick={handleClose}
          >
            {'Cancel'}
          </Button>
          <Tooltip
            title='Please select a user'
            key='share-btn-tooltip'
            placement='top'
            arrow
            disableHoverListener={!!selectedUsers.length}
          >
            <span>
              <Button
                variant='contained'
                onClick={onShareProject}
                disabled={!selectedUsers.length}
              >
                {'Share'}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>

      </DialogActions>
    </Dialog>
  );
};

export default ShareProjectModal;