import React from 'react';
import { Collapse, Grid, IconButton, Stack, Typography } from '@mui/material';
import { MuiAccessibilityFilter, MuiAuthenticationFilter, MuiCreatedByFilter } from './components';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { ScanFiltersContext } from '../../filters-context';
import { FilterName } from '../../duck';

export const OtherFilters: React.FC = () => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const toggleFilters = () => setExpanded(old => !old);

  const {
    filterDictionary,
  } = React.useContext(ScanFiltersContext);

  const projectIds = React.useMemo(() => {
    const projectFilterObj = filterDictionary[FilterName.PROJECT];
    const keyBy = filterDictionary[FilterName.PROJECT]?.keyBy;
    return projectFilterObj?.selected.map(p => keyBy?.(p) || '').filter(Boolean) || [];
  }, [filterDictionary[FilterName.PROJECT]?.selected]);

  return (
    <Stack gap={0}>
      <Stack direction='row' alignItems='center' onClick={toggleFilters}
        sx={{ cursor: 'pointer', width: 'fit-content' }}
      >
        <Typography variant='subtitle2'>
          {expanded ? 'Hide filters' : 'More filters'}
        </Typography>
        <IconButton size='small'>
          {expanded ? <CaretUp /> : <CaretDown />}
        </IconButton>
      </Stack>

      <Collapse in={expanded}
        sx={{
          '& .MuiCollapse-wrapperInner': {
            mt: '0.5rem',
          }
        }}>
        <Grid container spacing={1}>
          <Grid item lg={3} md={4} xs={6}>
            <MuiAuthenticationFilter
              projectIds={projectIds}
              selectedAuthentications={filterDictionary[FilterName.AUTHENTICATION]?.selected}
              setSelectedAuthentication={filterDictionary[FilterName.AUTHENTICATION]?.setSelected}
            />
          </Grid>

          <Grid item lg={3} md={4} xs={6}>
            <MuiAccessibilityFilter
              selectedAccessibility={filterDictionary[FilterName.ACCESSIBILITY]?.selected}
              setSelectedAccessibility={filterDictionary[FilterName.ACCESSIBILITY]?.setSelected}
            />
          </Grid>

          <Grid item lg={3} md={4} xs={6}>
            <MuiCreatedByFilter
              // projectIds={projectIds}
              selectedUsers={filterDictionary[FilterName.INITIATEDBY]?.selected}
              setSelectedUsers={filterDictionary[FilterName.INITIATEDBY]?.setSelected}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Stack >
  );
};