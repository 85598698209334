import { globalModels, globalQueries } from '@shared/duck';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { enqueueSnackbar } from 'notistack';
export const useDeleteTargetHandler = () => {
  const { deleteTarget } = globalQueries.useDeleteTarget();
  const onDeleteTarget = async (id: string, name?: string, onAfterDelete?: () => void) => {
    try {
      await deleteTarget({ id });
      enqueueSnackbar(<><b>{name}</b> Target has been deleted successfully</>, { variant: 'success' });
      onAfterDelete?.();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Failed to delete ${name} Target`, { variant: 'error' });
    }
  };

  const { showModal } = useGlobalModalContext();

  const deleteTargetHandler = async (target: globalModels.TargetViewModel, onAfterDelete?: () => void) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Target?',
        onConfirm: () => onDeleteTarget(target.id, target.name, onAfterDelete),
        name: target.name,
      },
    });
  };

  return { deleteTargetHandler };
};