import { Stack, styled, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

const Header = styled(Stack)`
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  gap: 1.25rem;
  border-bottom: 1px solid ${muiPalette.grey?.['200']};
  background: white;
`;

const DialogTitle = styled(Typography)`
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem;
  color: ${muiPalette.grey?.['900']};
`;

const Footer = styled(Stack)`
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  gap: 1.25rem;
  border-top: 1px solid ${muiPalette.grey?.['300']};
  background: white;

  & :first-of-type {
    border: none;
    :hover {
      border: none;
    }
  }
`;

export { Header, DialogTitle, Footer };