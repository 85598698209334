import { Grid } from '@mui/material';
import { FormikControlMui, MuiTextInput } from '@shared/components';
import React from 'react';
import { useApp } from '@shared/hooks';

const SolutionNameInput: React.FC = () => {
  const app = useApp();
  const showSolutionInput = app.users.me?.firstLogin;

  return (
    showSolutionInput && (
      <Grid item xs={12}>
        <FormikControlMui
          name='currentSolutionName'
          label='Currently used Application Security Testing Solution'
          optional
        >
          <MuiTextInput placeholder='Enter Solution Name'/>
        </FormikControlMui>
      </Grid>
    )
  );
};

export default SolutionNameInput;