import { AlertClasses, AlertProps, Interpolation, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { muiPalette } from './palette';

export const AlertStyles: Partial<OverridesStyleRules<keyof AlertClasses, 'MuiAlert', Omit<Theme, 'components'>>> | undefined = {
  root: {
    borderRadius: '0.375rem',
    border: '1px solid',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
};

export const AlertVariants:
  {
    props: Partial<AlertProps>;
    style: Interpolation<{
      theme: Omit<Theme, 'components'>;
    }>;
  }[] | undefined = [
    {
      props: { severity: 'info' },
      style: {
        borderColor: muiPalette.blue?.[300],
        backgroundColor: muiPalette.blue?.[100],
      },
    },
    {
      props: { severity: 'warning' },
      style: {
        borderColor: muiPalette.orange?.[300],
        backgroundColor: muiPalette.orange?.[100],
        '.MuiAlert-icon': {
          color: muiPalette.orange?.[400],
        }
      },
    },
  ];