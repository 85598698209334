import { styled } from '@mui/material';

const AppWindowContainer = styled('div')`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  outline: none;
  background-color: #f5f5f5;
`;

export { AppWindowContainer };
